
// mobile menu

.menu-overlay {
    background: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;	
}

.active-overlay {
	opacity: 1;
    visibility: visible;	
}

.main-menu-sidebar {
	background:$color-blue;
	width:310px;
	position:fixed;
	height:100%;
	top:0px;
	right:-310px;
	z-index:99999;
	transition:.45s ease-in-out;
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 30px;
    overflow-y:scroll;
    /* close menu */
    header {
        position: relative;
    }
    .close-menu-btn {
        position: absolute;
        top:30px;
        right:0px;
        color:$white;
        font-size: 20px;
        width: 40px;
        height: 40px;
        border-radius:100%;
        i {
            @include centre(both);
        }
    }
    // arrow
    small {
        display:block;
        width:50px;
        height:54px;
        position: absolute;
        top:0px;
        right:0px;     
        &:after {
            color:$white;
            font-size: 22px!important;
            font-weight: 300;
            @include centre(both);
        }
        &.active {
            &:after {
                color:$white;
                @include animate-fade;
                @include centre(both);
            }						
        }          
    }
	/* main menu */
	#sidebar-menu-wrapper {
		#menu {
			.nav-links {
                padding-top: 90px;
                text-align: center;
                .menu-item-has-children {
                    position: relative;
                }
				> li > a {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 18px;
                    color:$white;
                    position: relative;
                    padding-left: 0px;
                    padding-right: 0px;
                    display: block;
                    font-family: $font-semi;
					&:hover {
						color:$white;
					}
				}
                > li {
                    &:last-child {
                        margin-top: 10px;
                        a {
                            display: inline-block;
                            background:$color-blue-light;
                            padding-left: 46px;
                            padding-right: 46px;
                            border-radius: 999px;
                            font-family: $font-semi;
                            color:$color-text;
                            &:hover {
                                background:$color-blue;
                                color:$white;
                            }                            
                        }
                    }
                }
				li {
					ul {
						padding-top: 10px;
						padding-bottom: 10px;
						li {
							a {
								display: block;
								font-size: 1em;
								color:#5d5d5d;
								padding-top: 8px;
								padding-bottom: 8px;
								padding-left: 20px;
								position: relative;
								color:$white;
								line-height: 1.3;
                                letter-spacing: 1px;
                                
								&:hover {
									color:$white;
									padding-left: 20px;
									&:before {
										width: 10px;
									}
								}
								&:before {
									content:'';
									width: 5px;
									height: 5px;
									background:$white;
									opacity: 0.7;
									@include centre(y);
									left:0px;
									border-radius:100%;
									@include animate-fade;
								}
							}
						}
					}
				}
				.has-menu > a {
					position:relative;
				}
			}
		}

	}
}


.body-scroll {
	overflow-y:hidden!important;
}

.menu-active {
	right: 0;
}
