// Our Mission
#our-mission {
    background:$color-light;
    padding-top: 150px;
    padding-bottom: 250px;
    @include bp(lg) {
        padding-top: 100px;
        padding-bottom: 180px;
    }
    @include bp(md) {
        padding-top: 50px;
        padding-bottom: 150px;
    }
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    // intro
    .mission-intro {
        padding-right: 50px;
        @include bp(lg) {
            padding-right: 15px;
        }
        @include bp(sm-down) {
            padding-right: 0px;
        }
        h2 {
            font-size: 50px;
            line-height: 1.2;
            margin-bottom: 40px;
            @include bp(lg) {
                font-size: 40px;
                margin-bottom: 30px;
            }
            @include bp(md) {
                font-size: 40px;
                margin-bottom: 30px;
            }
            @include bp(sm-down) {
                font-size: 36px;
                margin-bottom: 30px;
            }
            br {
                @include bp(md) {
                    display: none
                }
                @include bp(sm-down) {
                    display: none
                }
            }
        }
        p {
            @include intro-text;
            margin-bottom: 30px;
            @include bp(lg) {
                margin-bottom: 25px;
            }
        }
    }
    // content
    .mission-content {
        @include bp(md) {
            margin-top: 40px;
        }
        @include bp(sm-down) {
            margin-top: 40px;
        }
        .mission-card {
            position: relative;
            padding-left: 65px;
            margin-bottom: 70px;
            @include bp(lg) {
                margin-bottom: 45px;
                padding-left: 60px;
            }
            @include bp(md) {
                margin-bottom: 40px;
                padding-left: 60px;
            }
            @include bp(sm-down) {
                margin-bottom: 40px;
            }
            img {
                position: absolute;
                left:0px;
                width: 42px;
                top:0px;
                @include bp(lg) {
                    width: 38px;
                }
            }
            h3 {
                font-size: 18px;
                margin-bottom: 20px;
                line-height: 1.3;
                @include bp(lg) {
                    margin-bottom: 10px;
                }
            }
            p {
                line-height: 1.4;
            }
        }
    }
}

//  about CTA
#about-cta {
    margin-bottom: 150px;
    margin-top: -180px;
    @include bp(lg) {
        margin-bottom: 100px;
        margin-top: -130px;
    }
    @include bp(md) {
        margin-bottom: 100px;
        margin-top: -130px;
    }
    @include bp(sm-down) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .about-text {
        padding-top: 120px;
        padding-bottom: 120px;
        padding-left: 150px;
        padding-right: 150px;
        background-color: $color-pale;
        border-radius: 50px;
        box-shadow: 0 40px 76px rgba(0, 0, 0,  0.08);  
        position: relative;
        @include bp(lg) {
            padding:70px;
        }
        @include bp(md) {
            padding:60px;
            border-radius: 30px;
        }
        @include bp(sm-down) {
            padding:30px;
            padding-top: 40px;
            padding-bottom: 40px;
            border-radius: 32px;
        }
        @include bp(sm-down) {
            padding:30px;
        }
        p {
            margin-bottom: 0px;
            text-align: center;
            color:$white;
            font-size: 32px;
            font-family: $font-heading;
            line-height: 1.3;
            @include bp(lg) {
                font-size: 28px;
            }
            @include bp(md) {
                font-size: 26px;
            }
            @include bp(sm-down) {
                font-size: 22px;
                line-height: 1.4;
            }
        }
        a.btn-cta {
            @include btn-cta;
        }
        footer {
            text-align: center;
            padding-top: 30px;
        }
    }
    &.about-cta--inner {
        margin-top: 0px!important;
        margin-bottom: 80px;
        .about-text {
            padding-top: 80px;
            padding-bottom: 80px;
            @include bp(sm-down) {
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }
    }    
}

#about-logos {
    padding-bottom: 100px;
    margin-top: -100px;
    @include bp(lg) {
        margin-top: -50px;
        padding-bottom: 20px;
    }
    @include bp(md) {
        padding-bottom: 0px;
    }
    @include bp(sm-down) {
        margin-top: 0px;
        padding-bottom: 50px;
    }
    #logos-slider {
        display: flex;
        @include bp(sm-down) {
            flex-wrap: wrap;
            justify-content: center;
        }
        .item {
            width: 25%;
            @include bp(sm-down) {
                width: 33%;
            }
            .logo {
                height: 150px;
                position: relative;
                @include bp(lg) {
                    height: 130px;
                }
                @include bp(md) {
                    height: 130px;
                }
                @include bp(sm-down) {
                    height: 80px;
                }
                img {
                    @include centre(both);
                    max-width: 70%;
                    max-height: 80px;
                    @include bp(md) {
                        max-height: 50px;
                    }
                }
            }
        }
    }
}

// Cta
#about-ceo {
    @include bp(md) {
        padding-top: 30px;
    }
    @include bp(sm-down) {
        padding-top: 0px;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include bp(sm-down) {
            display: block;
        }
        .ceo-image {
            width: 50%;
            margin-left: -50px;
            @include bp(sm-down) {
                margin-left: 0px;
                width: 100%;
            }
            img {
                max-width: initial;
                width:auto;
                @include bp(lg) {
                    width: 100%;
                }
                @include bp(md) {
                    width: 100%;
                }
                @include bp(sm-down) {
                    width: 100%;
                }
            }
        }
        .ceo-bio {
            width: 50%;
            padding-left: 50px;
            @include bp(lg) {
                padding-left: 0px;
            }
            @include bp(md) {
                padding-left: 0px;
                width: 55%;
            }
            @include bp(sm-down) {
                padding-left: 0px;
                width: 100%;
                text-align: center;
                padding-left: 15px;
                padding-right: 15px;
            }
            h3 {
                font-size: 32px;
                margin-bottom: 20px;
                @include bp(lg) {
                    font-size: 28px;
                }
                @include bp(md) {
                    font-size: 26px;
                }
                @include bp(sm-down) {
                    font-size: 26px;
                }
            }
            h4 {
                margin-bottom: 30px;
                font-size: 18px;
                color:#3c3c3c;
                font-family: $font-med;
                @include bp(lg) {
                    font-size: 16px;
                }
                @include bp(md) {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                @include bp(sm-down) {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
            p {
                @include intro-text;
            }
            footer {
                padding-top: 10px;
                ul {
                    display: inline-block;
                    li {
                        margin-right: 15px;
                        display: inline-block;
                        @include bp(sm-down) {
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                        a {
                            font-size: 24px;
                            border-radius: 100%;
                            display: inline-block;
                            text-align: center;
                            color:$color-pale;
                            &:hover {
                                color:$color-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}

// team
#team {
    padding-top: 100px;
    padding-bottom: 100px;
    @include bp(lg) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include bp(md) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    header {
        text-align: center;
        padding-bottom: 80px;
        @include bp(lg) {
            padding-bottom: 50px;
        }
        @include bp(md) {
            padding-bottom: 40px;
        }
        @include bp(sm-down) {
            padding-bottom: 30px;
        }
        h2 {
            @include main-title;
            @include bp(md) {
                margin-bottom: 0px;
            }
        }
    }
    // team list
    #team-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include bp(sm-down) {
            display: block;
        }
        .team-card {
            width: 25%;
            text-align: center;
            margin-bottom: 40px;
            @include bp(tablet) {
                width: 50%;
            }
            @include bp(sm-down) {
                width: 100%;
            }
            &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                width: 20%;
                @include bp(lg) {
                    width: 25%;
                }                
                @include bp(md) {
                    width: 25%;
                }
                @include bp(sm-down) {
                    width: 100%;
                }
                .team-photo {
                    img {
                        width: 200px!important;
                        height: 200px!important;
                        @include bp(md) {
                            width: 160px!important;
                            height: 160px!important;
                        }
                    }
                }
                .team-desc {
                    h3 {
                        font-size: 20px;
                    }
                }
            }
            &:hover {
                .team-photo {
                    img {
                        transform: scale(1.05);
                        box-shadow: 0px 0px 34.56px 10px rgba(0, 0, 0, 0.15); 

                    }
                }
            }
            @include bp(sm-down) {
                margin-bottom: 40px;
            }
            .team-photo {
                img {
                    @include animate-fade;
                    border-radius: 100%;
                    width: 240px;
                    height: 240px;
                    object-fit: cover;
                    @include bp(lg) {
                        width: 200px;
                        height: 200px;
                    }
                    @include bp(md) {
                        width: 160px;
                        height: 160px;
                    }
                }
            }
            .team-desc {
                padding-top: 30px;
                @include bp(lg) {
                    padding-top: 20px;
                }
                @include bp(md) {
                    padding-top: 15px;
                }
                @include bp(sm-down) {
                    padding-top: 15px;
                }
                h3 {
                    font-size: 24px;
                    line-height: 1.2;
                    max-width: 85%;
                    margin:0px auto;
                    margin-bottom: 10px;
                    @include bp(lg) {
                        font-size: 22px;
                    }
                    @include bp(md) {
                        font-size: 18px;
                        max-width: 95%;
                    }
                    @include bp(sm-down) {
                        font-size: 22px;
                        margin-bottom: 5px;
                        max-width: 100%;
                    }
                }
                span.position {
                    display: block;
                    margin-bottom: 20px;
                    color:#a4a4a4;
                    font-size: 18px;
                    @include bp(md) {
                        margin-bottom: 15px;
                        font-size: 15px;
                    }
                    @include bp(sm-down) {
                        font-size: 16px;
                    }
                }
                footer {
                    ul {
                        li {
                            display: inline-block;
                            margin-left: 5px;
                            margin-right: 5px;
                            a {
                                background:#f2f2f2;
                                color:#778178;
                                width: 32px;
                                height: 32px;
                                text-align: center;
                                line-height: 32px;
                                border-radius: 100%;
                                display: inline-block;
                                position: relative;
                                font-size: 16px;
                                span {
                                    @include centre(both);
                                }
                                &:hover {
                                    background:$color-pale;
                                    color:$white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Testimonials
#about-testimonials {
    background:$color-light;
    padding-top: 120px;
    padding-bottom: 120px;
    @include bp(lg) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @include bp(md) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .testimonials-image {
        img {
            width: 100%;
        }
    }
    .testimonials-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        #reviews-slider {
            @include bp(sm-down) {
                margin-top: 30px;
                text-align: center;
            }
            width: 100%;
            .item {
                padding-top: 50px;
                @include bp(sm-down) {
                    padding-top: 15px;
                }
                .review-item {
                    @include bp(sm-down) {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    p {
                        @include intro-text;
                        font-size: 28px;
                        line-height: 42px;
                        @include bp(md) {
                            font-size: 22px;
                            line-height: 32px;
                        }
                        @include bp(sm-down) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                    footer {
                        margin-top: 35px;
                        position: relative;
                        padding-left: 170px;
                        height: 140px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        @include bp(md) {
                            margin-top: 50px;
                            padding-left: 150px;
                        }
                        @include bp(sm-down) {
                            height: auto;
                            padding-left: 0px;
                            margin-top: 30px;
                            text-align: center;
                            display: block;
                        }
                        .profile-image {
                            position: absolute;
                            left:0px;
                            box-shadow: 0px 0px 14.56px 0px rgba(0, 0, 0, 0.05);
                            top:0px;
                            width: 140px;
                            height: 140px;
                            background:$white;
                            border-radius: 100%;
                            @include centre(y);
                            @include bp(md) {
                                width: 120px;
                                height: 120px;
                            }
                            @include bp(sm-down) {
                                @include clear-pos;
                                margin:0px auto;
                                margin-bottom: 25px;
                                width: 120px;
                                height: 120px;
                            }
                            img {
                                @include centre(both);
                                max-width: 70%;
                            }
                        }
                        a.btn-more {
                            @include btn-cta;
                            min-width: 100px;
                            padding-left: 20px;
                            padding-right: 20px;
                            height: 44px;
                            line-height: 44px;
                            font-size: 16px;
                            margin-top: 10px;
                            width: auto;
                            @include bp(md) {
                                font-size: 14px;
                                width:auto;
                            }
                            @include bp(sm-down) {
                                margin-top: 15px;
                                width: auto;
                            }
                        }
                        span {
                            display: block;
                            &.author {
                                font-size: 20px;
                                color:$color-blue;
                                font-family: $font-med;
                                margin-bottom: 3px;
                                line-height: 1;
                                @include bp(md) {
                                    font-size: 18px;
                                }
                            }
                            &.position {
                                font-size: 18px;
                                color:#a4a4a4;
                                @include bp(md) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Rest team
#rest-team {
    padding-bottom: 100px;
    @include bp(md) {
        padding-bottom: 70px;
    }
    @include bp(sm-down) {
        padding-bottom: 50px;
    }
    img.map {
        width: 100%;      
        @include bp(sm-down) {
            display: block;
            margin-bottom: 30px;
        }  
        @include bp(md) {
            display: none;
        }          
        @include bp(sm-down) {
            display: none;
        }        
    }

    .tab {
        margin:0px auto;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:2px solid $color-pale;
        width:auto;
        padding:5px;
        width: 800px;
        border-radius: 999px;
        @include bp(md) {
            width: 100%!important;
        }
        @include bp(sm-down) {
            width: 100%;
            display: block;
            text-align: center;
            border-radius: 4px;
        }
        button {
            width: 200px;
            border-radius: 999px;
            border:none;
            font-size: 18px;
            font-family: $font-semi;
            color:$color-pale;
            padding-top: 13px;
            padding-bottom: 13px;
            flex:1;
            @include bp(md) {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 16px;
            }
            @include bp(sm-down) {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 16px;     
                width: 100%;      
                border-radius: 4px;     
            }
            &.active {
                background:$color-pale!important;
                color:$white!important;
            }
            &:hover {
                color:$color-pale;
                background: none;
            }
        }
    }

    #dev-team {
        .team-box {
            &:first-child {
                left:49%;
                top:32%;                
            }
            &:nth-child(2) {
                left:55%;
                top:30%;
            }
            &:nth-child(3) {
                left:46%;
                top:40%;
            }
            &:nth-child(4) {
                left:45%;
                top:24%;
            }
            &:nth-child(5) {
                left:41%;
                top:35%;
            }    
            &:nth-child(6) {
                left:53%;
                top:39%;
            }   
            &:nth-child(7) {
                left:52%;
                top:20%;
            }      
            &:nth-child(8) {
                top:71%;
                left:30%;
            }  
            &:nth-child(9) {
                top:44%;
                left:68%;
            }     
            &:nth-child(10) {
                top:80%;
                left:28%;
            }     
            &:nth-child(11) {
                top:53%;
                left:70%;
            }   
            &:nth-child(12) {
                top:41%;
                left:60%;
            }                                                                    
        }
    }

    #marketing-team {
        .team-box {
            &:first-child {
                left:30%;
                top:71%;                
            }
            &:nth-child(2) {
                left:67%;
                top:46%;
            }
            &:nth-child(3) {
                left:29%;
                top:80%;
            }
            &:nth-child(4) {
                left:65%;
                top:54%;
            }
            &:nth-child(5) {
                left:52%;
                top:34%;
            }    
            &:nth-child(6) {
                left:12%;
                top:40%;
            }   
            &:nth-child(7) {
                left:82%;
                top:70%;
            }                               
        }        
    }

    #recruit-team {
        .team-box {
            &:first-child {
                left:25%;
                top:35%;                
            }
            &:nth-child(2) {
                left:29%;
                top:52%;
            }
            &:nth-child(3) {
                left:23%;
                top:55%;
            }
            &:nth-child(4) {
                left:55%;
                top:54%;
            }
            &:nth-child(5) {
                left:48%;
                top:35%;
            }    
            &:nth-child(6) {
                left:12%;
                top:45%;
            }   
            &:nth-child(7) {
                left:30%;
                top:79%;
            }   
            &:nth-child(9) {
                left:22%;
                top:64%;
            }      
            &:nth-child(10) {
                left:59%;
                top:30%;
            }      
            &:nth-child(11) {
                left:52%;
                top:23%;
            }      
            &:nth-child(12) {
                left:27%;
                top:70%;
            }        
            &:nth-child(13) {
                left:32%;
                top:64%;
            }   
            &:nth-child(14) {
                left:39%;
                top:44%;
            }    
            &:nth-child(14) {
                left:19%;
                top:14%;
            }  
            &:nth-child(15) {
                left:27%;
                top:61%;
            } 
            &:nth-child(16) {
                left:54%;
                top:33%;
            }   
            &:nth-child(17) {
                left:78%;
                top:52%;
            }   
            &:nth-child(8) {
                left:48%;
                top:12%;
            }                                                                                                                                        
        }        
    }    

    #accounting-team {
        .team-box {
            &:first-child {
                left:55%;
                top:29%;                
            }
            &:nth-child(2) {
                left:43%;
                top:32%;
            }
            &:nth-child(3) {
                left:25%;
                top:62%;
            }
            &:nth-child(4) {
                left:65%;
                top:54%;
            }
            &:nth-child(5) {
                left:24%;
                top:30%;
            }    
            &:nth-child(6) {
                left:12%;
                top:40%;
            }   
            &:nth-child(7) {
                left:82%;
                top:70%;
            }                               
        }        
    }      

    #map-holder {
        position: relative;
        .team-box {
            position: absolute;
            top:0px;
            left:0px;
            @include bp(md) {
                position: relative;
                left:auto!important;
                top:auto!important;
                border:1px solid #ddd;
                padding:10px;
                border-radius: 6px;
                margin-bottom: 10px;
                box-shadow: 0px 0px 14.56px 0px rgba(0, 0, 0, 0.05);                 
            }
            @include bp(sm-down) {
                position: relative;
                left:auto!important;
                top:auto!important;
                border:1px solid #ddd;
                padding:10px;
                border-radius: 6px;
                margin-bottom: 10px;
                box-shadow: 0px 0px 14.56px 0px rgba(0, 0, 0, 0.05); 
            }
            a {
                display: flex;
                align-items: center;     
                position: relative;
                width: 56px;
                height: 56px;     
                @include bp(md) {
                    width: 100%;
                    height: auto;
                }                   
                @include bp(sm-down) {
                    width: 100%;
                    height: auto;
                }   
                span.name {
                    display: block;
                    font-size: 18px;
                    color:$color-blue;
                    font-family: $font-med;

                    @media screen and (min-width:1024px) {
                        background:$color-blue;
                        color:$white;
                        font-size: 13px;
                        @include centre(x);
                        width: auto;
                        padding-left: 12px;
                        padding-right: 12px;
                        border-radius: 9px;
                        text-align: center;
                        line-height: 1.2;
                        padding-top: 6px;
                        padding-bottom: 6px;
                        top:-50px;
                        opacity: 0;
                        @include animate-fade;
                        z-index:1050;
                        display: none;
    
                        display: block;
                        visibility: hidden;
                        opacity: 0;
    
                        transition: all .3s ease-in;                        
                    }

                    @include bp(sm-down) {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        flex:1;
                        visibility: visible;                        
                    }
                    &:after {
                        content:'';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 12px 12px 0 12px;
                        border-color: $color-blue transparent transparent transparent;     
                        position: absolute;
                        left:0px;
                        @include centre(x);
                        bottom:-10px;     
                        @include bp(md) {
                            display: none;
                        }                          
                        @include bp(sm-down) {
                            display: none;
                        }              
                    }
                }           
                &:hover {
                    img {
                        transform: scale(1.1);
                        box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.05); 
                    }
                    span.name {
                        opacity: 1;
                        display: block;

                        visibility: visible;
                        opacity: 1;
                        visibility: visible;
                    
                        
                    }
                }
            }
            img {
                border-radius: 100%;
                width: 56px;
                height: 56px;
                object-fit: cover;
                @include animate-fade;
                border:3px solid $color-yellow;
                @include bp(md) {
                    margin-right: 25px;
                }                
                @include bp(sm-down) {
                    margin-right: 25px;
                }
            }            
        }
    }
}



/* Simple Modal*/
$speed: 0.6s;
$delay: ($speed * .5);
$easing: cubic-bezier(.55,0,.1,1);
html.fixed {
    overflow: hidden;
}
@media only screen and (min-width: 40em) {
    .modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: rgba(#000, 0.6);
        opacity: 0;
        visibility: hidden;
        backface-visibility: hidden;
        transition: opacity $speed $easing, visibility $speed $easing;
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
} // media query
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100px;
    margin: 0 auto;
    background-color: #fff;
    width: 90%;
    max-width: 1170px;
    min-height: 20rem;
    padding: 0px;
    border-radius: 0px;
    opacity: 0;
    overflow-y: auto;
    visibility: hidden;
    box-shadow: 0 2px 10px rgba(#000, 0.1);
    backface-visibility: hidden;
    transform: scale(1.2);
    transition: all $speed $easing;
    justify-content: space-between;
    .close-modal {
        position: absolute;
        cursor: pointer;
        top: 30px;
        right: 50px;
        opacity: 0;
        backface-visibility: hidden;
        transition: opacity $speed $easing, transform $speed $easing;
        transition-delay: $delay;
        z-index: 9999999;
        @include bp(md) {
            top:15px;
            right:30px;
        }
        img {
            width: 36px;
            height: 36px;
            @include bp(md) {
                width: 30px;
                height: 30px;
            }
            @include bp(sm-down) {
                width: 24px;
                height: 24px;
            }
        }
    } // close modal
    
    .img-bottom {
        position: absolute;
        bottom:0px;
        left:0px;
    }
    
    .modal-content {
        opacity: 0;
        backface-visibility: hidden;
        transition: opacity $speed $easing;
        transition-delay: $delay;
        padding:60px;
        min-height: 600px;
        @include bp(lg) {
            padding:30px;
        }
        @include bp(md) {
            padding:30px;
            padding-top: 50px;
        }
        @include bp(sm-down) {
            padding:30px;
        }
        .author-desc {
            padding-top: 19px;
            h4 {
                font-size: 22px;
                margin-bottom: 5px;
                @include bp(lg) {
                    font-size: 20px;
                }
                @include bp(md) {
                    font-size: 20px;
                }
            }
            span.position {
                display: block;
                font-size: 20px;
                color:#333333;
                margin-bottom: 20px;
                @include bp(md) {
                    font-size: 18px;
                }
            }
            .btn-in {
                position: relative;
                z-index:999;
            }
        }
    } // content

    .content-wrapper {
        display: flex;
        justify-content: space-between;
        @include bp(sm-down) {
            display: block;
        }
        .col-image {
            width: 40%;
            @include bp(md) {
                width: 35%;
            }
            @include bp(sm-down) {
                width: 100%;
            }
            img {
                width: 100%;
                border-radius: 100%;
                @include bp(sm-down) {
                    width: 80%;
                    margin:0px auto;
                    display: block;
                }
            }
            footer {
                text-align: center;
                padding-top: 20px;
            }
            ul {
                li {
                    display: inline-block;
                    margin-left: 5px;
                    margin-right: 5px;
                    a {
                        background:#f2f2f2;
                        color:#778178;
                        width: 52px;
                        height: 52px;
                        text-align: center;
                        line-height: 32px;
                        border-radius: 100%;
                        display: inline-block;
                        position: relative;
                        font-size: 20px;
                        span {
                            @include centre(both);
                        }
                        &:hover {
                            background:$color-pale;
                            color:$white;
                        }
                    }
                }
            }
        }
        .col-text {
            width: 50%;
            padding-top: 30px;
            @include bp(md) {
                padding-top: 10px;
            }
            @include bp(sm-down) {
                width: 100%;
            }
            h4 {
                font-size: 32px;
            }
            span.position {
                display: block;
                margin-bottom: 30px;
            }
            p {
                @include intro-text;
                margin-bottom: 20px;
                @include bp(lg) {
                    margin-bottom: 15px;
                }
                @include bp(sm-down) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .modal-text {
        padding-left: 50px;
        padding-right: 80px;
        @include bp(md) {
            padding-left: 30px;
            padding-right: 15px;
        }
        @include bp(sm-down) {
            padding-left: 0px;
            padding-right: 0px;
        }
        h2 {
            
            font-size: 25px;
            margin-bottom: 30px;
    
        }
        p {
            font-size: 16px;
            line-height: 24px;
            color:#333333;
            margin-bottom: 25px;

        }
    }

    .col-text {
        flex:1;
    }
    
    &.active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        .modal-content {
            opacity: 1;
        }
        .close-modal {
            transform: translateY(10px);
            opacity: 1;
            z-index: 9999999;
        }
    }
}
@media only screen and (max-width: 39.9375em) {
    .modal-overlay {
        display: flex;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        backface-visibility: hidden;
        transition: opacity $speed $easing, visibility $speed $easing;
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
    .modal {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
        transform: scale(1.1);
        padding: 0 !important;
        .modal-content {
            position: absolute;
            top: 0px;
            left: 0;
        }
    }
    .close-modal {
        right: 20px !important;
        z-index: 999999;
    }
} // media query

/* Overlay */
.modal-overlay {
    display: none;
    &.active {
        overflow-y: scroll;
    }
    &.show {
        display: flex;
    }
}

.img-responsive {
    max-width: 100%;
}

// about intro
body.page-template-about-template {
    #about {
        padding-top: 0px;
        margin-top: 0px;
        @include bp(md) {
            padding-bottom: 70px;
        }
    }
}