// Lp Header
#lp-header {
    padding-top: 45px;
    padding-bottom: 45px;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9998;
    background-color: #fff;
    @include animate-fade;
    @include bp(sm-down) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    &.sticky-lp {
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
        background:$white;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    #lp-logo {
        width: 230px;
        @include bp(sm-down) {
            width: 180px;
        }
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .lp-header--cta {
        a.btn-cta {
            @include btn-cta;        
        }
    }
}

// Lp Intro
#lp-intro {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 75px;
    @include bp(sm-down) {
        margin-top: 20px;
        margin-bottom: 40px;
    }
    h1 {
        @include hero-title;
        margin-bottom: 35px;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }
    p {
        font-family: $font-med;
        font-size: 24px;
        line-height: 1.333;
        color: #000;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        @include bp(lg) {
            font-size: 22px;
        }
        @include bp(md) {
            font-size: 20px;
        }
        @include bp(sm-down) {
            font-size: 18px;
        }
    }
}

//Lp contact

#contact-cta.contact-lp {
    @include bp(desktop) {
        margin-bottom: 100px!important;
    }
    @include bp(lg) {
        margin-bottom: 80px!important;
    }
    @include bp(md) {
        margin-bottom: 80px!important;
    }
    @include bp(sm-down) {
        margin-bottom: 60px!important;
    }
    #contact-wrapper {
        max-width: 650px;
        margin:0px auto;
    }
}

// Lp Features
#lp-features {
    margin-top: 100px;
    padding-bottom: 100px;
    @include bp(lg) {
        margin-top: 80px;
        padding-bottom: 80px;
    }
    @include bp(md) {
        margin-top: 60px;
        padding-bottom: 60px;
    }
    @include bp(sm-down) {
        margin-top: 50px;
        padding-bottom: 20px;
    }
    .lp-features {
        &__intro {
            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include bp(sm-down) {
                    display: block;
                }
            }
            .feature-text {
                width: 50%;
                align-items: center;
                @include bp(md) {
                    width: 60%;
                }
                @include bp(sm-down) {
                    width: 100%;
                    text-align: center;
                    padding-bottom: 30px;
                }
                h2 {
                    font-size: 46px;
                    line-height: 1.304;
                    font-family: $font-heading;
                    margin-bottom: 25px;
                    width: 95%;
                    @include bp(lg) {
                        font-size: 38px;
                    }         
                    @include bp(md) {
                        font-size: 32px;
                    }
                    @include bp(sm-down) {
                        font-size: 26px;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }      
                p {
                    @include intro-text;
                    width: 90%;
                    @include bp(sm-down) {
                        width: 100%;
                        max-width: 90%;
                        margin:0px auto;
                    }
                }  
                a.btn-cta {
                    @include yellow-btn;
                    min-width: 200px;
                    text-align: center;
                    margin-top: 35px;
                    text-transform: none;
                    @media (min-width: 768px) {
                        padding-left: 75px;
                        padding-right: 75px;
                    }
                }
            }
            .feature-image {
                width: 50%;
                @include bp(md) {
                    width: 40%;
                }
                @include bp(sm-down) {
                    width: 100%;
                }
                img {
                    width: 610px;
                    max-width: inherit;
                    @include bp(lg) {
                        width: 100%;
                    }
                    @include bp(md) {
                        width: 100%;
                    }
                    @include bp(sm-down) {
                        width: 100%;
                    }
                }
            }
        }
        &__items {
            margin-top: 75px;
            @include bp(sm-down) {
                margin-top: 50px;
            }
            .col-md-4 {
                &:nth-child(3n+2) {
                    .lp-features__item {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
                &:nth-child(3n+3) {
                    .lp-features__item {
                        margin-left: auto;
                    }
                }
            }
        }
        &__item {
            max-width: 252px;
            @include bp(sm-down) {
                max-width: 100%;
                margin-bottom: 40px;
            }
            .item-ico {
                margin-bottom: 25px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                height: 82px;
            }
            .item-text {
                h3 {
                    font-size: 20px;
                    line-height: 1.5;
                    margin-bottom: 14px;
                }
            }
        }
    }
}

//Lp steps

#lp-steps {
    background-color: $color-light;
    padding-top: 100px;
    padding-bottom: 275px;
    @include bp(lg) {
        padding-top: 80px;
        padding-bottom: 250px;
    }
    @include bp(md) {
        padding-top: 60px;
        padding-bottom: 250px;
    }
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    h2 {
        font-size: 46px;
        line-height: 1.304;
        font-family: $font-heading;
        margin-bottom: 70px;
        max-width: 835px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        @include bp(lg) {
            font-size: 38px;
        }         
        @include bp(md) {
            font-size: 32px;
            margin-bottom: 50px;
        }
        @include bp(sm-down) {
            font-size: 26px;
            max-width: 100%;
            margin-bottom: 40px;
        }
    }
    .lp-step {
        @include bp(sm-down) {
            margin-bottom: 40px;
        }
        .step-num {
            font-size: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font-main;
            color: rgb(255, 255, 255);
            line-height: 0.813;
            border-radius: 50%;
            background-color: $color-pale;
            width: 74px;
            height: 74px;
            margin-bottom: 10px;
            @include bp(lg) {
                font-size: 30px;
                width: 70px;
                height: 70px;
            }         
            @include bp(md) {
                font-size: 28px;
                width: 65px;
                height: 65px;
            }
            @include bp(sm-down) {
                font-size: 24px;
                width: 60px;
                height: 60px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .lp-step-ico {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-bottom: 55px;
            height: 160px;
            max-width: 303px;
            margin-left: auto;
            margin-right: auto;
            @include bp(sm-down) {
                margin-bottom: 35px;
            }
            img {
                max-width: 241px;
                max-height: 180px;
                @include  bp(md) {
                    max-width: 221px;
                    max-height: 160px;
                }
            }
        }
        p {
            text-align: center;
            max-width: 303px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .view-btn-wrap {
        margin-top: 80px;
        text-align: center;
        @include bp(sm-down) {
            margin-top: 10px;
        }
        a {
            @include yellow-btn;
            text-transform: none;
            @media (min-width: 768px) {
                padding-left: 75px;
                padding-right: 75px;
            }
        }
    }
}

#lp-testimonials {
    margin-top: -175px;
    margin-bottom: 160px;
    @include bp(desktop) {
        margin-bottom: 140px;
    }
    @include bp(lg) {
        margin-bottom: 110px;
    }
    @include bp(md) {
        margin-bottom: 100px;
    }
    @include bp(sm-down) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .slider-for {
        background-color: $color-pale;
        border-radius: 32px;
        padding: 80px 65px 45px 65px;
        @include bp(lg) {
            padding: 75px 55px 45px 55px;
        }
        @include bp(md) {
            padding: 55px 55px 45px 55px;
        }
        @include bp(sm-down) {
            padding: 40px 15px;
        }
        .testimonial-text {
            text-align: center;
            max-width: 724px;
            margin-left: auto;
            margin-right: auto;
            p {
                font-size: 32px;
                font-family: $font-heading;
                color: rgb(255, 255, 255);
                line-height: 1.375;
                @include bp(lg) {
                    font-size: 30px;
                }         
                @include bp(md) {
                    font-size: 28px;
                }
                @include bp(sm-down) {
                    font-size: 22px;
                }
            }
        }
        .testimonial-author {
            margin-top: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include bp(sm-down) {
                flex-direction: column;
            }
            .img-holder {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 128px;
                width: 128px;
                height: 128px;
                background-color: #fff;
                border-radius: 50%;
                margin-right: 30px;
                @include bp(sm-down) {
                    min-width: 90px;
                    width: 90px;
                    height: 90px;
                    margin-right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 10px;
                }
                img {
                    padding: 16px;
                }
            }
            .author-info {
                @include bp(sm-down) {
                    text-align: center;
                }
                .author {
                    display: block;
                    font-family: $font-heading;
                    font-size: 24px;
                    color: rgb(255, 255, 255);
                    line-height: 1.833;
                    @include bp(lg) {
                        font-size: 22px;
                    }
                    @include bp(md) {
                        font-size: 20px;
                    }
                    @include bp(sm-down) {
                        font-size: 18px;
                    }
                }
                .position {
                    font-size: 16px;
                    line-height: 1.5;
                    font-family: $font-main;
                    color: rgba(255, 255, 255, 0.659);
                    display: block;
                }
            }
        }
    }
    .slider-nav {
        margin-top: 65px;
        @include bp(sm-down) {
            margin-top: 30px;
        }
        .slick-track {
            display: flex;
            .slick-slide {
                display: flex;
                height: auto;
                align-items: center;
                justify-content: center;
                &:hover {
                    cursor: pointer;
                }
                img {
                    opacity: 0.35;
                    @include animate-fade;
                    max-width: 60%;
                }
                &.slick-current {
                    img {
                        opacity: 1;
                    }
                }
            }
        }
        .slick-dots {
            text-align: center;
        }
    }
}

#lp-why {
    padding-bottom: 100px;
    @include bp(md) {
        padding-bottom: 80px;
    }
    @include bp(sm-down) {
        padding-bottom: 20px;
    }
    h2 {
        font-size: 46px;
        line-height: 1.304;
        font-family: $font-heading;
        margin-bottom: 70px;
        max-width: 835px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        @include bp(lg) {
            font-size: 38px;
        }         
        @include bp(md) {
            font-size: 32px;
            margin-bottom: 50px;
        }
        @include bp(sm-down) {
            font-size: 26px;
            width: 100%;
            margin-bottom: 40px;
        }
    }
    .lp-why-list {
        .col-md-4 {
            &:nth-child(3n+2) {
                .lp-why-item {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            &:nth-child(3n+3) {
                .lp-why-item {
                    margin-left: auto;
                }
            }
        }
        .lp-why-item {
            max-width: 252px;
            text-align: center;
            @include bp(sm-down) {
                max-width: 100%;
                margin-bottom: 40px;
            }
            .item-ico {
                margin-bottom: 30px;
                display: flex;
                align-items: flex-end;
                justify-content:center;
                height: 82px;
            }
        }
    }
}

#lp-cta {
    background-color: $color-blue;
    padding-top: 70px;
    padding-bottom: 70px;

    @include bp(lg) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @include bp(md) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .lp-cta-intro {
        text-align: center;
        padding-bottom: 95px;
        @include bp(lg) {
            padding-bottom: 80px;
        }
    
        @include bp(md) {
            padding-bottom: 60px;
        }
        @include bp(sm-down) {
            padding-bottom: 50px;
        }
        h3 {
            max-width: 950px;
            margin-left: auto;
            margin-right: auto;
            font-size: 32px;
            font-family: $font-heading;
            color: rgb(255, 255, 255);
            line-height: 1.375;
            margin-bottom: 25px;
            @include bp(lg) {
                font-size: 30px;
            }         
            @include bp(md) {
                font-size: 28px;
            }
            @include bp(sm-down) {
                font-size: 24px;
                max-width: 100%;
            }
            
        }
        p {
            max-width: 660px;
            margin-left: auto;
            margin-right: auto;
            font-family: $font-med;
            font-size: 24px;
            line-height: 1.333;
            color: #fff;
            @include bp(lg) {
                font-size: 22px;
            }
            @include bp(md) {
                font-size: 20px;
            }
            @include bp(sm-down) {
                font-size: 18px;
            }
        }
    }
    #form-wrapper {
        max-width: 780px;
        padding:40px;
        margin:0px auto;
        margin-top: 50px;
        text-align: left;
        position: relative;
        border:2px solid rgba($white, 0.3);
        border-radius: 32px;       
        @include bp(sm-down) {
            padding:20px;
        }

        form {
            padding-top: 20px;
            .row-wrapper {
                display: flex;
                margin-left: -15px;
                margin-right: -15px;
                margin-bottom: 30px;
                @include bp(sm-down) {
                    margin-bottom: 15px;
                    display: block;
                    margin-left: 0px;
                    margin-right: 0px;
                }
                .form-block {
                    width: 50%;
                    margin-right: 15px;
                    margin-left: 15px;
                    @include bp(sm-down) {
                        width: 100%;
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                }
                .form-block-full {
                    width: 100%;
                }
            }
            label {
                margin-bottom: 11px;
                font-family: $font-semi;
                display: block;
                color:$white;
                span {
                    color:red;
                }
            }
            input[type="text"], input[type="tel"], input[type="email"], select {

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                width: 100%;
                height: 62px;
                border-radius: 24px;
                background: #fff;
                border: 2px solid #e7f0f6;
                padding: 0 25px;
                font-size: 16px;
                color: #2d2d2d;
                transition: 0.25s ease-in-out;      
                font-family: $font-main;      
                
            }    
            select {
                background:url('../img/ico/down.svg') no-repeat;
                background-position: 97% center;
                background-size:24px;
                background-color: $white;
            }  
            textarea {
                width: 100%;
                height: 136px;
                border-radius: 24px;
                background: #fff;
                border: 2px solid #e7f0f6;
                padding: 25px;
                font-size: 16px;
                color: #2d2d2d;
                transition: 0.25s ease-in-out;     
                font-family: $font-main;                  
            }      
            .ff-el-form-check {
                padding-top: 8px;
                font-size: 16px;
                a {
                    color:$color-pale;
                    &:hover {
                        color:$color-blue;
                    }
                }
                input[type="checkbox"] {
                    margin-right: 5px;
                    position: relative;
                    top:-1px;
                }  
            }   
            .ff-btn-submit, .wpcf7-submit {
                padding: 0;
                border: 0;
                width: auto;
                padding-left: 60px;
                padding-right: 60px;
                height: 86px;
                line-height: 84px;
                text-align: center;
                border-radius: 999px;
                background-repeat: no-repeat;
                background-position: center center;
                background-color: $color-pale;
                background-image: none;
                font-size: 1.250em;
                @include animate-fade;
                font-family: $font-semi;
                margin:0px;
                border:2px solid transparent;
                margin-right: 0px;
                color:#1e1e1e;
                background:$color-yellow;
                margin-top: 20px;
                @include bp(md-down) {
                    width: 100%;
                    height: 70px;
                    line-height: 68px;
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 15px;
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 100%;
                    text-align: center;                            
                }
                @include bp(md) {
                    width: 100%;
                    height: 70px;
                    line-height: 68px;    
                    margin-bottom: 0px;
                    margin-top: 15px;                
                }                    
                &:hover {
                    background: #082140;
                    color: #fff;                        
                }                      
            } 
        }

        .checkbox-wrapper {
            font-size: 16px;
            margin-bottom: 10px;
            color:$white;
            label {
                color:$white;
                span {
                    color:$white;
                }
            }
            a {
                color:$color-yellow;
                &:hover {
                    color:$white;
                }
            }
            .wpcf7-list-item {
                margin-left: 0px;
            }
            input[type="checkbox"] {
                margin-right: 5px;
                position: relative;
                top:1px;

                position: absolute;
                width: auto;
                opacity: 0;

            }    
            .wpcf7-list-item-label {
                &:before {
                    content: ' ';
                    width: 24px;
                    height: 24px;
                    background: #fff;
                    border: 1px solid #afafb0;
                    margin-right: 15px;
                    display: inline-block;
                    cursor: pointer;
                    position: relative;
                    top: 7px;
                    border-radius: 6px;
                }
            }     
            input:checked+span.wpcf7-list-item-label:before {
                background-color: $color-blue-dark;
                border: 1px solid $color-blue;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 70%;
            }       
        }
        .wpcf7-checkbox {
            font-size: 16px;
            a {
                color:$color-yellow;
                &:hover {
                    color:$white;
                }
            }
        }
        .form-block {
            @include bp(sm-down) {
                margin-bottom: 15px;
            }
            input[type="text"], input[type="tel"], input[type="email"] {
                &.wpcf7-not-valid {
                    border-color:#ffd4d4;
                }    
                .fieldset-cf7mls-wrapper div.wpcf7-validation-errors {
                    border:none;
                    padding:0px;
                    margin:0px;
                }      
            }
        }        
    
            // validate
            .wpcf7-not-valid-tip {
                font-size: 14px;
                padding-left: 15px;
                padding-top: 7px;
                color:#ffd4d4;
            }
            .wpcf7-response-output {
                border:none;
                padding:0px;
                width: 100%;
                margin-left: 15px;
                color:#ffd4d4;
            }


    }
}