// Homepage hero banner
#hero-banner {
    .container {
        display: flex;
        justify-content: space-between;
        margin-top: 177px;
        @include bp(lg) {
            margin-top: 150px;
        }
        @include bp(md) {
            margin-top: 100px;
        }
        @include bp(sm-down) {
            margin-top: 90px;
            flex-wrap: wrap;
            padding-left: 30px;
            padding-right: 30px;
        }
        // image
        .hero-image {
            @include bp(sm-down) {
                text-align: center;
                width: 100%;
                margin-top: 25px;
            }
            img {
                @include bp(sm-down) { 
                    margin: 0px auto;
                    display: block;
                    width: 90%;
                }
            }
        }
        // caption 
        .hero-caption {
            padding-top: 54px;
            width: 465px;
            @include bp(md) {
                padding-top: 45px;
                padding-right: 30px;
            }
            @include bp(sm-down) {
                width: 100%;
                padding-top: 40px;
                text-align: center;
            }
            h1 {
                font-size: 42px;
                line-height: 60px;
                font-family: $font-heading;
                font-weight: 400;
                margin-bottom: 15px;
                @include bp(lg) {
                    font-size: 38px;
                    line-height: 56px;
                }
                @include bp(md) {
                    font-size: 32px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                @include bp(sm-down) {
                    font-size: 30px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                strong {
                    display: block;
                    font-size: 72px;
                    line-height: 74px;
                    font-family: $font-heading;
                    font-weight: bold;
                    @include bp(lg) {
                        font-size: 64px;
                        line-height: 68px;
                    }
                    @include bp(md) {
                        font-size: 48px;
                        line-height: 1.2;
                    }
                    @include bp(sm-down) {
                        font-size: 44px;
                        line-height: 1.2;
                    }
                }
            }
            p {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 32px;
                max-width: 377px;
                @include bp(md) {
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 25px;
                }
                @include bp(sm-down) {
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 25px;                    
                }
            }
            a.btn-cta {
                @include yellow-btn;
            }
        }
    }
}

// About
#about {
    margin-top: 110px;
    padding-bottom: 165px;
    text-align: center;
    @include bp(lg) {
        margin-top: 100px;
        padding-bottom: 100px;
    }
    @include bp(md) {
        margin-top: 70px;
        padding-bottom: 100px;
    }
    @include bp(sm-down) {
        margin-top: 50px;
        padding-bottom: 60px;
    }
    .container {
        @include bp(sm-down) {
            padding-left: 30px;
            padding-right: 30px;
        }        
    }
    header {
        width: 550px;
        margin:0px auto;
        padding-bottom: 100px;
        @include bp(lg) {
            padding-bottom: 80px;
        }
        @include bp(md) {
            padding-bottom: 50px;
        }
        @include bp(sm-down) {
            width: 100%;
            padding-bottom: 40px;
        }
        h2 {
            @include main-title;
        }
        p {
            @include intro-text;
            a {
                color:$color-blue;
                font-family: $font-semi;
                &:hover {
                    color:$color-blue-dark;
                }
            }
        }
        &.header-tech {
            width: 690px;
            @include bp(sm-down) {
                width: 90%;
            }
        }
    }
    // card
    .about-card {
        @include bp(sm-down) {
            margin-bottom: 40px;
        }
        .card-icon {
            margin-bottom: 14px;
            img {
                max-height: 64px;
                max-width: 64px;
            }
        }
        .card-photo {
            margin-bottom: 45px;
            img {
                width: 70%;
            }
        }
        .card-text {
            h3 {
                font-size: 22px;
                line-height: 1.2;
                margin-bottom: 10px;
                @include bp(lg) {
                    font-size: 20px;
                }
                @include bp(md) {
                    font-size: 18px;
                }
                @include bp(sm-down) {
                    font-size: 18px;
                }
                span {
                    color: #227aeb;
                    padding-right: 5px;
                }
            }
            p {
                max-width: 275px;
                margin:0px auto;
                margin-bottom: 15px!important;
                @include bp(md) {
                    font-size: 15px;
                    line-height: 1.5;
                }
                @include bp(sm-down) {
                    font-size: 15px;
                    line-height: 1.5;                    
                }
            }
        }
    }
    .steps {
        text-align: left;
        padding:0px;
        max-width: 100%;
        @include bp(sm-down) {
            margin:0px;
        }
        .about-card {
            margin-bottom: 50px;
        }
        h3 {
            margin-bottom: 15px;
            span {
                color:$color-pale;
                padding-right: 5px;
            }
        }
        p {
            margin:0px!important;
            max-width: 95%!important;
        }
    }
    // cta
    .cta-card {
        background:$color-pale;
        text-align: center;
        height: 320px;
        margin-top: 60px;
        border-radius: 12px;
        position: relative;
        @include bp(sm-down) {
            margin-top: 20px;
        }
        .caption {
            @include centre(both);
            text-align: center;
            width: 80%;
            h4 {
                color:$white;
                margin-bottom: 20px;
                font-family: $font-med;
                line-height: 1.2;
                @include bp(md) {
                    font-size: 20px;
                }
            }
            a.btn-cta {
                @include btn-cta;
                @include bp(md) {
                    font-size: 15px;
                }
            }
        }
    }
}

// Control
#control {
    padding-bottom: 183px;
    @include bp(lg) {
        padding-bottom: 100px;
    }
    @include bp(md) {
        padding-bottom: 100px;
    }
    @include bp(sm-down) {
        padding-bottom: 100px;
    }
    &.intro-tech {
        padding-bottom: 120px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include bp(sm-down) {
            display: block;
            padding-left: 30px;
            padding-right: 30px;
        }
        // image
        .image-holder {
            width: 500px;
            @include bp(lg) {
                width: 450px;
            }
            @include bp(md) {
                width: 50%;
            }
            @include bp(sm-down) {
                width: 100%;
            }
            img {
                width: 100%;
            }
        }
        // text
        .control-text {
            flex:1;
            padding-left: 52px;
            @include bp(md) {
                padding-left: 30px;
            }
            @include bp(sm-down) {
                text-align: center;
                padding-left: 0px;
                padding-top: 30px;
            }
            h4 {
                a {
                    color:$color-pale;
                    &:hover {
                        color:$color-blue;
                    }
                }
            }
            h3 {
                font-size: 33px;
                line-height: 44px;
                margin-bottom: 20px;
                @include bp(lg) {
                    font-size: 30px;
                    line-height: 40px;
                }
                @include bp(md) {
                    font-size: 28px;
                    line-height: 34px;
                    margin-bottom: 15px;
                }
                @include bp(sm-down) {
                    font-size: 28px;
                    line-height: 34px;
                    margin-bottom: 5px;                    
                }
            }
            ol {
                li {
                    display: block;
                    margin-bottom: 10px;
                    color: $color-text;
                    margin-top: 0;
                    font-weight: normal;
                    font-family: $font-main;
                    font-size: 16px;
                    line-height: 26px;    
                    position: relative;   
                }
            }
            ol {
                list-style: none; 
                counter-reset: li
            }
            li::before {
                content: counter(li); 
                color: white;
                background:#227aeb;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: 100%;
                display: inline-block; 
                margin-left: -25px;
                margin-right: 20px;
                position: absolute;
                left:-15px;
                top:1px;
            }
            li {
                counter-increment: li
            }
        }
    }
}

// Features
#features {
    header {
        width: 566px;
        margin:0px auto;
        padding-bottom: 100px;
        text-align: center;
        @include bp(lg) {
            padding-bottom: 80px;
        }
        @include bp(md) {
            padding-bottom: 50px;
        }
        @include bp(sm-down) {
            width: 100%;
            padding-bottom: 40px;            
        }
        h2 {
            @include main-title;
        }
        p {
            @include intro-text;
            margin-bottom: 15px;
        }
        ul {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            li {
                text-transform: uppercase;
                font-size: 16px;
                font-family: $font-semi;
                &:after {
                    width: 6px;
                    height: 6px;
                    content:'';
                    display: inline-block;
                    margin-left: 10px;
                    margin-right: 10px;
                    border-radius: 50%;
                    position: relative;
                    top:-2px;
                    background:$color-blue;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }            
        }
    }
    .container {
        @include bp(sm-down) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    // main content
    .main-content {
        .content-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 60px;
            @include bp(lg) {
                margin-bottom: 40px;
            }
            @include bp(md) {
                margin-bottom: 40px;
            }
            @include bp(sm-down) {
                display: block;
            }
            // text
            .content-text {
                width: 550px;
                text-align: right;
                padding-right: 70px;
                @include bp(lg) {
                    width: 500px;
                }
                @include bp(md) {
                    width: 50%;
                    padding-right: 40px;
                }
                @include bp(sm-down) {
                    width: 100%;
                    padding-right: 0px;
                    text-align: center;
                }
                h3 {
                    font-size: 28px;
                    line-height: 1;
                    margin-bottom: 10px;
                    @include bp(lg) {
                        font-size: 26px;
                    }
                    @include bp(md) {
                        font-size: 24px;
                        margin-bottom: 5px;
                    }
                    @include bp(sm-down) {
                        font-size: 24px;
                        margin-bottom: 5px;                        
                    }
                    strong {
                        color:#227AEB;;
                    }
                }
                p {
                    font-size: 18px;
                    line-height: 26px;
                    @include bp(md) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
            // image
            .content-image {
                width: 450px;
                @include bp(sm-down) {
                    width: 100%;
                    margin-top: 40px;
                }
                @include bp(lg) {
                    width: 420px;
                }
                @include bp(md) {
                    width: 50%;
                }
                img {
                    width: 100%;
                }
            }
            &:nth-child(even) {
                .content-image {
                    order:-1;
                }
                .content-text {
                    padding-right: 0px;
                    padding-left: 70px;
                    text-align: left;
                    @include bp(sm-down) {
                        padding-left: 0px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

// Bottom CTA
#bottom-cta {
    background:$color-blue;
    text-align: center;
    padding-top: 63px;
    padding-bottom: 60px;
    @include bp(lg) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @include bp(md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @include bp(sm-down) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .container {
        @include bp(sm-down) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .cta-content {
        width: 530px;
        margin:0px auto;
        @include bp(md) {
            width: 400px;
        }
        @include bp(sm-down) {
            width: 100%;
        }
        h3 {
            color:$white;
            font-size: 33px;
            line-height: 43px;
            margin-bottom: 15px;
            @include bp(lg) {
                font-size: 30px;
                line-height: 38px;
            }
            @include bp(md) {
                font-size: 26px;
                line-height: 1.3;
            }
            @include bp(sm-down) {
                font-size: 26px;
                line-height: 1.3;                
            }
        }
        p {
            color:$white;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 40px;
            @include bp(lg) {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 30px;
            }
            @include bp(md) {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 30px;                
            }
            @include bp(sm-down) {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 30px;                   
            }
        }
        a.btn-cta {
            display: inline-block;
            width: 197px;
            height: 59px;
            border-radius: 999px;
            background:$color-yellow;
            line-height: 59px;
            font-size: 18px;
            font-family: $font-semi;
            color:$color-gray;
            text-align: center;
            @include bp(md) {
                height: 52px;
                line-height: 52px;
                width: 150px;
            }
            @include bp(sm-down) {
                height: 50px;
                line-height: 50px;
                width: 150px;                
            }
            &:hover {
                background:$color-blue-dark;
                color:$white;
            }
        }
    }
}

// Contact CTA
#contact-cta {
    margin-top: 150px;
    margin-bottom: 150px;
    #contact-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
        background-color: #fff;
        border-radius: 50px;
        box-shadow: 0 40px 76px rgba(0, 0, 0,  0.08);  
        position: relative;
        @include bp(md) {
            padding:30px;
            border-radius: 30px;
        }
        @include bp(sm-down) {
            padding:30px;
            padding-top: 40px;
            padding-bottom: 40px;
            border-radius: 32px;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 90%;
            height: 100%;
            border-radius: 50px;
            background: #fff;
            opacity: .5;
            box-shadow: 0 30px 76px rgba(0, 0, 0, 0.08);
            top: calc(50% + 30px);
            z-index: -1; 
        }
        &:before {
            position: absolute;
            display: block;
            content: "";
            left: -175px;
            top: -50px;
            width: 350px;
            height: 350px;
            background: #eff7fe;
            border-radius: 50%;
            z-index: -1; 
        }
        h3 {
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 1.25;
            @include bp(md) {
                font-size: 30px;
            }
            @include bp(sm-down) {
                font-size: 30px;
            }
            strong {
                color:#227aeb;
            }
        }
        p {
            margin-bottom: 20px;
            margin-top: -10px;
            font-size: 1.25em;
            font-size: 18px;
        }
        .form-wrapper {
            // validate
            .wpcf7-not-valid-tip {
                font-size: 14px;
                padding-left: 15px;
                padding-top: 7px;
            }
            .wpcf7-response-output {
                border:none;
                padding:0px;
                width: 100%;
                margin-left: 15px;
                color:red;
            }


            .fluentform, form {
                padding-top: 20px;
                .row-wrapper {
                    display: flex;
                    margin-left: -15px;
                    margin-right: -15px;
                    margin-bottom: 30px;
                    @include bp(sm-down) {
                        margin-bottom: 15px;
                        display: block;
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                    .form-block {
                        width: 50%;
                        margin-right: 15px;
                        margin-left: 15px;
                        @include bp(sm-down) {
                            width: 100%;
                            margin-left: 0px;
                            margin-right: 0px;
                        }
                    }
                    .form-block-full {
                        width: 100%;
                    }
                }
                label {
                    margin-bottom: 11px;
                    font-family: $font-semi;
                    display: block;
                    span {
                        color:red;
                    }
                }
                input[type="text"], input[type="tel"], input[type="email"], select {

                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    width: 100%;
                    height: 62px;
                    border-radius: 24px;
                    background: #fff;
                    border: 2px solid #e7f0f6;
                    padding: 0 25px;
                    font-size: 16px;
                    color: #2d2d2d;
                    transition: 0.25s ease-in-out;      
                    font-family: $font-main;      
                    
                }    
                select {
                    background:url('../img/ico/down.svg') no-repeat;
                    background-position: 97% center;
                    background-size:24px;
                }  
                textarea {
                    width: 100%;
                    height: 136px;
                    border-radius: 24px;
                    background: #fff;
                    border: 2px solid #e7f0f6;
                    padding: 25px;
                    font-size: 16px;
                    color: #2d2d2d;
                    transition: 0.25s ease-in-out;     
                    font-family: $font-main;                  
                }      
                .ff-el-form-check {
                    padding-top: 8px;
                    font-size: 16px;
                    a {
                        color:$color-pale;
                        &:hover {
                            color:$color-blue;
                        }
                    }
                    input[type="checkbox"] {
                        margin-right: 5px;
                        position: relative;
                        top:-1px;
                    }  
                }   
                .ff-btn-submit, .wpcf7-submit {
                    padding: 0;
                    border: 0;
                    width: auto;
                    padding-left: 60px;
                    padding-right: 60px;
                    height: 86px;
                    line-height: 84px;
                    text-align: center;
                    border-radius: 999px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: $color-pale;
                    background-image: none;
                    font-size: 1.250em;
                    @include animate-fade;
                    font-family: $font-semi;
                    margin:0px;
                    border:2px solid transparent;
                    margin-right: 0px;
                    color:#1e1e1e;
                    background:$color-yellow;
                    margin-top: 20px;
                    @include bp(md-down) {
                        width: 100%;
                        height: 70px;
                        line-height: 68px;
                        margin-left: 0px;
                        margin-right: 0px;
                        margin-bottom: 15px;
                        padding-left: 0px;
                        padding-right: 0px;
                        width: 100%;
                        text-align: center;                            
                    }
                    @include bp(md) {
                        width: 100%;
                        height: 70px;
                        line-height: 68px;    
                        margin-bottom: 0px;
                        margin-top: 15px;                
                    }                    
                    &:hover {
                        background: #082140;
                        color: #fff;                        
                    }                      
                } 
            }

            fieldset {
                padding:0px;
                margin:0px;
                display: flex;
                flex-wrap: wrap;
                .row {
                    flex:1;
                    @include bp(md-down) {
                        width: 100%;
                        flex:auto;
                    }
                }
                .cf7mls-btns {
                    width: auto;
                    margin-left: 30px;
                    @include bp(md-down) {
                        margin-left: 0px;
                        width: 100%;
                    }
                }
                .cf7mls_next {
                    padding: 0;
                    border: 0;
                    width: 120px;
                    height: 86px;
                    text-align: center;
                    border-radius: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: $color-pale;
                    background-image: none;
                    font-size: 1.250em;
                    color: #fff;
                    @include animate-fade;
                    font-family: $font-semi;
                    margin:0px;
                    border:2px solid transparent;
                    @include bp(md) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;
                        margin-top: 15px;                  
                    }
                    @include bp(md-down) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;
                    }
                    &:hover {
                        background-image: url('../img/ico/Long-arrow-right.png');
                        font-size: 0;
                    }                       
                }
                .wpcf7-submit {
                    padding: 0;
                    border: 0;
                    width: auto;
                    padding-left: 40px;
                    padding-right: 40px;
                    height: 86px;
                    text-align: center;
                    border-radius: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: $color-pale;
                    background-image: none;
                    font-size: 1.250em;
                    @include animate-fade;
                    font-family: $font-semi;
                    margin:0px;
                    border:2px solid transparent;
                    margin-right: 0px;
                    color:#1e1e1e;
                    background:$color-yellow;
                    margin-left: 30px;
                    @include bp(md-down) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;
                        margin-left: 0px;
                        margin-right: 0px;
                        margin-bottom: 15px;
                    }
                    @include bp(md) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;    
                        margin-bottom: 0px;
                        margin-top: 15px;                    
                    }                    
                    &:hover {
                        background: #082140;
                        color: #fff;                        
                    }                       
                }
                .cf7mls_back {
                    padding: 0;
                    border: 0;
                    width: 120px;
                    height: 86px;
                    text-align: center;
                    border-radius: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: $color-pale;
                    background-image: none;
                    font-size: 1.250em;
                    color: #fff;
                    @include animate-fade;
                    font-family: $font-semi;
                    margin:0px;
                    border:2px solid $color-pale;
                    color:$color-pale;
                    background-color: transparent;
                    margin-right: 10px;
                    @include bp(md) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;   
                        margin-right: 0px;
                        margin-top: 15px;                     
                    }
                    @include bp(sm-down) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;
                        margin:0px;
                        margin-bottom: 15px;
                    }
                    &:hover {
                        background-image: url('../img/ico/long-arrow-left.png');
                        font-size: 0;
                    }  
                }
                #cf7mls-back-btn-cf7mls_step-3 {
                    display: none!important;
                }
            }
            .checkbox-wrapper {
                font-size: 16px;
                margin-bottom: 10px;
                color:$black;
                label {
                    color:$black;
                    span {
                        color:$black;
                    }
                }
                a {
                    color:$color-pale;
                    &:hover {
                        color:$color-blue;
                    }
                }
                .wpcf7-list-item {
                    margin-left: 0px;
                }
                input[type="checkbox"] {
                    margin-right: 5px;
                    position: relative;
                    top:1px;

                    position: absolute;
                    width: auto;
                    opacity: 0;

                }    
                .wpcf7-list-item-label {
                    &:before {
                        content: ' ';
                        width: 24px;
                        height: 24px;
                        background: #fff;
                        border: 1px solid #afafb0;
                        margin-right: 15px;
                        display: inline-block;
                        cursor: pointer;
                        position: relative;
                        top: 7px;
                        border-radius: 6px;
                    }
                }     
                input:checked+span.wpcf7-list-item-label:before {
                    background-color: $color-pale;
                    border: 1px solid $color-pale;
                    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 70%;
                }       
            }
            .wpcf7-checkbox {
                font-size: 16px;
                a {
                    color:$color-pale;
                    &:hover {
                        color:$color-blue;
                    }
                }
            }
            .form-block {
                @include bp(sm-down) {
                    margin-bottom: 15px;
                }
                input[type="text"], input[type="tel"], input[type="email"] {
                    &.wpcf7-not-valid {
                        border-color:red;
                    }    
                    .fieldset-cf7mls-wrapper div.wpcf7-validation-errors {
                        border:none;
                        padding:0px;
                        margin:0px;
                    }      
                }
            }
            .form-next {
                button {
                    padding: 0;
                    border: 0;
                    width: 151px;
                    height: 86px;
                    text-align: center;
                    border-radius: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: $color-pale;
                    background-image: none;
                    font-size: 1.250em;
                    color: #fff;
                    @include animate-fade;
                    font-family: $font-semi;
                    @include bp(md) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;                        
                    }
                    @include bp(sm-down) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;
                    }
                    &:hover {
                        background-image: url('../img/ico/Long-arrow-right.png');
                        font-size: 0;
                    }                    
                }
            }
        }
    }  
}

.site-main {
    padding-top: 100px!important;
}

p.success {
    display: block;
    padding-top: 5px;
    color:$color-pale;
    font-family: $font-semi;
}

.fluentform .ff-el-is-error .ff-el-form-control {
    border-color: #f56c6c!important;
}