#featured-podcast {
    margin-top: 80px;
    margin-bottom: 80px;
    @include  bp(lg) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    @include bp(md) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    @include bp(sm-down) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        @include bp(sm-down) {
            display: block;
        }
        .podcast-desc {
            width: 50%;
            padding-top: 30px;
            @include bp(sm-down) {
                width: 100%;
                padding-top: 0px;
            }
            .podcast-date {
                small {
                    display: block;
                    font-size: 16px;
                    font-family: $font-semi;
                    color: rgb(131, 136, 143);
                    margin-bottom: 15px;
                    @include bp(lg-down) {
                        font-size: 15px;
                    }                    
                }
            }
            h2 {
                font-size: 32px;
                margin-bottom: 15px;
                line-height: 1.313;
                @include bp(lg) {
                    font-size: 30px;
                }         
                @include bp(md) {
                    font-size: 24px;
                }
                @include bp(sm-down) {
                    font-size: 22px;
                }
                a {
                    display: block;
                    color: inherit;
                    &:hover {
                        color: $color-blue;
                    }
                }
            }
            p {
                margin-bottom: 35px;
            }
            a.btn-more {
                @include btn-cta;               
            }
            .author-info {
                display: flex;
                align-items: center;
                img {
                    width: 51px;
                    height: 51px;
                    border-radius: 50px;
                    min-width: 50px;
                    margin-right: 17px;
                }
                .author-name {
                    font-size: 16px;
                    font-family: $font-semi;
                    color: rgba(0, 0, 0, 0.588);
                    line-height: 1.875;
                    flex-grow: 1;
                    a {
                        color: rgba(0, 0, 0, 0.588);
                        &:hover {
                            color:$color-blue;
                        }
                    }                    
                }
            }
        }
        .podcast-image {
            width: 40%;
            @include bp(md) {
                width: 50%;
            }
            @include bp(sm-down) {
                width: 100%;
            }
            .image-holder {
                img {
                    width: 100%;
                    border-radius: 12px;
                }
            }
            .podcast-socials {
                text-align: center;
                padding-top: 30px;
                ul {
                    li {
                        display: inline-block;
                        margin-left: 10px;
                        margin-right: 10px;
                        a {
                            font-size: 20px;
                            color:$color-blue-dark;
                            &:hover {
                                color:$color-blue;
                            }
                        }
                    }
                }
            }
        }
        .podcast-footer  {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

#podcast-list {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #eff7fe;
    @include  bp(lg) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include bp(md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    header {
        h3 {
            font-size: 24px;
            color: rgb(0, 0, 0);
            line-height: 1.083;
            margin-bottom: 55px;
            @include bp(md-down) {
                margin-bottom: 30px;
            }            
        }
    }
    .podcast-list {
        .podcast-card {
            background: $white;
            border-radius: 12px;
            margin-bottom: 30px;
            display: flex;
            @include bp(sm-down) {
                display: block;
            }
            .podcast-image {
                width: 400px;
                @include bp(md) {
                    width: 300px;
                }
                @include bp(sm-down) {
                    width: 100%;
                }
                img  {
                    width: 100%;
                    object-fit: cover;
                    height: 350px;
                    border-radius: 12px;
                    display: block;
                }
            }
            .podcast-desc {
                flex:1;
                padding-left: 50px;
                padding-top: 50px;
                padding-right: 50px;
                @include  bp(lg) {
                    padding-left: 30px;
                    padding-top: 30px;
                    padding-right: 30px;
                    padding-bottom: 0px;
                }
                @include bp(md) {
                    padding:30px;
                }
                @include bp(sm-down) {
                    padding:30px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
                .podcast-meta {
                    span {
                        display: inline-block;
                        font-size: 16px;
                        font-family: $font-semi;
                        color: rgb(131, 136, 143);
                        margin-bottom: 15px;
                        margin-right: 30px;
                        @include  bp(lg) {
                            margin-bottom: 5px;
                        }
                        @include bp(lg-down) {
                            font-size: 15px;
                        }
                        @include bp(sm-down) {
                            font-size: 14px;
                            margin-right: 15px;
                        }
                    }
                }
                h3 {
                    font-size: 20px;
                    margin-bottom: 15px;
                    line-height: 1.5;
                    @include bp(lg) {
                        font-size: 19px;
                    }         
                    @include bp(md) {
                        font-size: 18px;
                    }
                    @include bp(sm-down) {
                        font-size: 17px;
                    }
                    a {
                        display: block;
                        color: inherit;
                        &:hover {
                            color: $color-blue;
                        }
                    }
                }
                p {
                    margin-bottom: 35px;
                    @include  bp(lg) {
                        margin-bottom: 25px;
                    }
                    @include bp(md) {
                        margin-bottom: 25px;
                    }
                    @include bp(sm-down) {
                        margin-bottom: 25px;
                    }
                }
                a.btn-more {
                    @include btn-cta;
                }
                footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .podcast-icons  {
                        ul  {
                            li {
                                display: inline-block;
                                margin-left: 15px;
                                @include  bp(lg) {
                                    margin-left: 10px;
                                }
                                @include bp(md) {
                                    margin-left: 3px;
                                }
                                @include bp(sm-down) {
                                    margin-left: 5px;
                                }
                                a {
                                    img {
                                        width: 22px;
                                        @include bp(md) {
                                            width: 16px;
                                        }
                                        @include bp(sm-down) {
                                            width: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#podcast-main  {
    padding-top: 80px;
    padding-bottom: 100px;
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    #faq {
        h3 {
            font-size: 17px;
            color: $black;
            line-height: 1.333;
            position: relative;
            padding: 24px 60px 24px 30px;
            cursor: pointer;
            @include animate-fade;
            border:0;
            margin: 0;
            border-radius: 8px;
            border:2px solid #bddbf5;
            position: relative;
            color:$color-heading;
            @include bp(sm-down) {
                font-size: 16px;
                padding:15px;
                padding-right: 40px;
            }
            &:hover {
                cursor: pointer;
            }
            &:focus {
                outline: none!important;
            }
            &:after {
                @include centre(y);
                right:30px;
                content:url('../img/ico/chevron-down-regular.svg');
                width: 20px;
                @include bp(sm-down) {
                    width: 12px;
                }
            }
            &.active-header {
                border-color:$color-blue;
                background: $color-blue;
                color:$white;
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                    color:$white!important;
                    filter: brightness(0) invert(1);

                }
            }
        }
        .content {
            border:0;
            padding: 35px 55px 35px 30px;
            padding-bottom: 15px;
            display: none;
            background:$white;
            border-radius: 12px;
            margin-top: 10px;
            padding-bottom: 30px;
            box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.04); 
            @include bp(sm-down) {
                padding:20px;
            }
            p {
                margin-bottom: 30px;
                strong {
                    font-weight: normal;
                    font-family: $font-med;
                }
                a {
                    color:$color-blue;
                    font-family: $font-med;
                    &:hover {
                        color:$black;
                        text-decoration:underline;
                    }
                }
            }
        }        
    }    
    .podcast-play {

    }
    .faq__accordion {
        max-width: 875px;
        margin-left: auto;
        margin-right: auto;
        .faq-wrap {
            margin-bottom: 10px;
        }
    }    
    .podcast-content {
        margin-top: 50px;
        @include bp(sm-down) {
            margin-top: 30px;
        }
        p {
            font-size: 19px;
            color: rgb(6, 6, 6);
            line-height: 1.684;
            margin-bottom: 30px;
            @include bp(md-down) {
                font-size: 18px;
            }
            a {
                color:$color-blue;
                font-family: $font-semi;
                &:hover {
                    text-decoration: underline;
                    color:$color-blue-dark;
                }
            }                    
        }    
        h2 {
            margin-bottom: 30px;
            line-height: 1.3;
            font-size: 30px;
            @include bp(lg) {
                margin-bottom: 20px;
            }
            @include bp(md) {
                margin-bottom: 20px;
            }
            @include bp(sm-down) {
                margin-bottom: 20px;
            }
        }
        h3 {
            margin-bottom: 20px;
            margin-top: 50px;
            line-height: 1.45;
            font-size: 26px;
            @include bp(lg) {
                margin-top: 30px;
                margin-bottom: 15px;
            }
            @include bp(md) {
                margin-bottom: 15px;
            }
            @include bp(sm-down) {
                margin-bottom: 15px;
            }
        }
        h4 {
            margin-bottom: 20px;
            font-family: $font-med;
            line-height: 1.3;
            font-size: 22px;
        }
        ul {
            margin-bottom: 30px;
            li {
                display: block;
                padding-left: 20px;
                margin-bottom: 10px;
                position: relative;
                font-size: 18px;
                a {
                    color:$color-blue;
                    font-family: $font-med;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &:before {
                    content:'';
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background:$color-pale;
                    position: absolute;
                    left:0px;
                    top:10px;
                }
            }
            ul {
                margin-top: 15px;
                margin-bottom: 15px;
                li {
                    font-size: 16px;
                }
            }
        }         
    }
    // sidebar
    .podcast-sidebar {
        background: #eff7fe;
        border-radius: 12px;
        padding:30px;
        text-align: center;    
        position: sticky;
        top:110px;    
        .podcast-links {
            li {
                display: inline-block;
                margin-left: 8px;
                margin-right: 8px;
                @include bp(lg) {
                    margin-left: 5px;
                    margin-right: 5px;
                }
                img {
                    width: 26px;
                    @include bp(lg) {
                        width: 22px;
                    }
                }
            }
            span {
                color:$white;
                text-transform: uppercase;
                font-size: 18px;
                display: block;
                margin-bottom: 15px;
                color:$black;
                font-family: $font-med;
            }
        }
        .form-wrapper {
            margin-top: 40px;
            input[type="email"] {
                width: 100%;
                height: 58px;
                line-height: 58px;
                padding-left: 25px;
                border-radius: 12px;
                border:none;
                background:$color-blue-light;
                background:$white;
                font-size: 16px;
                border:1px solid #ddd;
                @include bp(md) {
                    height: 50px;
                    line-height: 50px;
                    padding-left: 15px;
                }
            }
            input[type="submit"] {
                width: 100%;
                height: 58px;
                line-height: 58px;
                border-radius: 12px;
                color:$black;
                background-color: $color-yellow;
                margin-top: 10px;
                padding-top: 0px;
                padding-bottom: 0px;
                border:none;
                font-family: $font-med;
                &:hover {
                    background-color: $color-blue-dark;
                    color:$white;
                }
            }
        }
    }
}

#podcast-header .podcast-list .podcast-wrapper {
    margin-bottom: 50px;
    @include bp(lg) {
        margin-bottom: 80px;
    }
}

#blog-search-area.podcast-search {
    h3 {
        color:$white;
        @include bp(sm-down) {
            color:$color-blue;
        }
        b {
            color:$color-blue-light;
            @include bp(sm-down) {
                color:$color-pale;
            }
        }
    }
}

#featured-podcast .container .podcast-image {
    @include bp(sm-down) {
        margin-top: 30px;
    }
}

#podcast-list.white-bg {
    background: $white;;
}