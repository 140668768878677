// Page Footer
#page-footer {
    background:#f8f9fb;
    padding-top: 60px;
    ul {
        list-style-type: none!important;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .container {
        @include bp(sm-down) {
            padding-left: 30px;
            padding-right: 30px;
            text-align: center;
        }
    }
    // about footer
    .footer-about {
        padding-right: 30px;
        @include bp(sm-down) {
            padding-right: 0px;
        }
        @include bp(lg) {
            padding-right: 15px;
        }
        @include bp(md) {
            padding-right: 0px;
        }
        img {
            width: 220px;
            margin-bottom: 30px;
            @include bp(md) {
                width: 200px;
                margin-bottom: 15px;
            }
            @include bp(sm-down) {
                margin-bottom: 20px;
            }
        }
        p {
            font-size: 18px;
            margin-top: 15px;
            a {
                color:$color-pale;
                font-family: $font-med;
            }
        }
        .footer-socials {
            span.label {
                // display: block;
                // font-size: 18px;
                // font-family: $font-semi;
                // margin-bottom: 15px;
                // @include bp(md) {
                //     font-size: 17px;
                // }
                display: block;
                font-family: $font-semi;
                font-size: 20px;
                margin-bottom: 25px;
                color:#082140;
                @include bp(md) {
                    font-size: 18px;
                    margin-bottom: 15px;
                }
                @include bp(sm-down) {
                    font-size: 18px;
                    margin-bottom: 10px;
                }                
            }
            ul {
                display: flex;
                align-items: center;
                @include bp(sm-down) {
                    justify-content: center;
                }
                li {
                    margin-right: 20px;
                    @include bp(sm-down) {
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    a {
                        font-size: 24px;
                        color:#227aeb;
                        &:hover {
                            color:$color-blue-dark;
                        }
                    }
                }
            }
        }
        .footer-nl {
            border-top:1px solid #ddd;
            margin-top: 30px;
            padding-top: 30px;
            @include bp(sm-down) {
                margin-bottom: 40px;
            }
            .title {
                display: block;
                font-size: 18px;
                font-family: $font-semi;
                margin-bottom: 15px;           
                @include bp(md) {
                    font-size: 17px;
                }     
            }
            p {
                font-size: 16px;
                margin-bottom: 15px;
                @include bp(md) {
                    font-size: 15px;
                }
            }
            small {
                opacity: 0.8;
                display: block;
                padding-top: 10px;
                a {
                    color:$color-pale;
                    &:hover {
                        color:$color-blue;
                    }
                }
            }
            .form-wrapper {
                position: relative;
                input[type="email"] {
                    width: 100%;
                    height: 58px;
                    line-height: 58px;
                    padding-left: 25px;
                    border-radius: 12px;
                    border:none;
                    background:$color-blue-light;
                    background:$white;
                    font-size: 16px;
                    border:1px solid #ddd;
                    @include bp(md) {
                        height: 50px;
                        line-height: 50px;
                        padding-left: 15px;
                    }
                }
                input[type="submit"], button {
                    position: absolute;
                    right:4px;
                    font-size: 0px;
                    display: block;
                    width: 70px;
                    height: 50px;
                    top:4px;
                    background:$color-yellow;
                    border:none;
                    border-radius: 12px;
                    color:#1e1e1e;
                    @include bp(md) {
                        height: 42px;
                        width: 50px;
                    }
                    &:hover {
                        background:$color-blue-dark;
                        color:$white;
                    }
                    span {
                        font-size: 32px;
                        @include bp(md) {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
    // nav
    .footer-nav {
        @include bp(sm-down) {
            margin-bottom: 40px;
        }
        span.title {
            display: block;
            font-family: $font-semi;
            font-size: 20px;
            margin-bottom: 25px;
            color:#082140;
            @include bp(md) {
                font-size: 18px;
                margin-bottom: 15px;
            }
            @include bp(sm-down) {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
        ul {
            li {
                display: block;
                margin-bottom: 15px;
                font-size: 16px;
                @include bp(md) {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
                @include bp(sm-down) {
                    font-size: 15px;
                    margin-bottom: 10px;
                }
                a {
                    color:$color-text;
                    color:$black;
                    opacity: 0.6;
                    &:hover {
                        opacity: 1;
                        color:$color-pale;
                    }
                }
            }
        }
    }
    // Copy Bar
    #copy-bar {
        padding-top: 30px;
        border-top: 1px solid #ddd;
        margin-top: 50px;
        padding-bottom: 30px;
        @include bp(md) {
            margin-top: 40px;
            padding-top: 25px;
            padding-bottom: 25px;
        }
        @include bp(sm-down) {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 20px;
        }
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include bp(sm-down) {
                display: block;
                text-align: center;
            }
        }
        .copy-left {
            small {
                font-size: 16px;
                color:$color-text;
                @include bp(lg) {
                    font-size: 15px;
                }
                @include bp(md) {
                    font-size: 14px;
                }
                @include bp(sm-down) {
                    font-size: 15px;
                }
                strong {
                    color:$color-blue-dark;
                    font-family: $font-semi;
                }
            }
        }
        .copy-right {
            ul {
                display: flex;
                align-items: center;
                @include bp(sm-down) {
                    display: block;
                    margin-top: 20px;
                }
                li {
                    margin-left: 30px;
                    @include bp(md) {
                        margin-left: 15px;
                    }
                    @include bp(sm-down) {
                        margin-left: 0px;
                        margin-bottom: 10px;
                    }
                    a {
                        color:$color-text;
                        font-size: 16px;
                        @include bp(lg) {
                            font-size: 15px;
                        }
                        @include bp(md) {
                            font-size: 14px;
                        }
                        @include bp(sm-down) {
                            font-size: 15px;
                        }
                        &:hover {
                            color:$color-pale;
                        }
                    }
                    &:last-child {
                        font-family: $font-semi;
                        a {
                            color:$color-blue;
                        }
                    }
                }
            }
        }
    }
}

.page-template-lp-template #page-footer {
    padding-top: 0px!important;
    #copy-bar {
        margin-top: 0px!important;
    }
}

.footer-branding {
    text-align: center;
    img {
        width: 260px;
        @include bp(md) {
            width: 200px;
        }      
    }
    span.tagline {
        display: block;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 45px;
        font-family: $font-med;
        color:$color-pale;
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
}

#cookie-notice {
    background-color: rgba($color-blue ,0.9);
    z-index: 9997;
    position: fixed;
    min-width: 100%;
    height: auto;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    left: 0;
    text-align: center;
    bottom: 0;
    display: none;
    &.slide-up {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: block!important;
    }
    &.slide-down {
        -webkit-animation-name: slideOutDown;
        animation-name: slideOutDown;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        opacity: 0;
    }
    &-in {
        padding: 15px 30px;
        @include bp(md) {
            padding: 15px 50px;
        }
        @include bp(sm-down) {
            padding: 15px 40px;
        }
    }
    .notice-text, .notice-btns {
        display: inline-block;
    }
    p {
        color: #fff;
        font-size: 15px;
        margin-bottom: 0px;
        @include bp(sm-down) {
            line-height: 1.3;
            margin-bottom: 10px;
        }
        a {
            color:$color-blue-light;
            text-decoration: underline;
            &:hover {
                color:$white;
                text-decoration: none;
            }
        }
    }
    .notice-btns {
        a {
            background-color: $color-yellow;
            display: inline-block;
            font-size: 16px;
            font-family: $font-semi;
            color:$color-gray;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 999px;
            margin-left: 15px;
            @include bp(sm-down) {
                margin-left: 0px;
            }
            &:hover {
                background-color: $white;
                color:$black;
            }
        }
    }
}