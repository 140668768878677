// Custom Mixins
// fade animation
@mixin animate-fade {
    transition: .25s ease-in-out;
}

@mixin clear-pos {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
}

// center vert horizontal
@mixin centre($axis: "both") {
    position: absolute;
    @if $axis=="y" {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    @if $axis=="x" {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    @if $axis=="both" {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

// plceholder
@mixin input-placeholder {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

// clearfix
%clearfix {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// breakpoints
@mixin bp($value) {
    @if $value=='xs-down' {
        @media only screen and (max-width: 575.98px) {
            @content;
        }
    }
    @else if $value=='sm-down' {
        @media only screen and (max-width: 767.98px) {
            @content;
        }
    }
    @else if $value=='md-down' {
        @media only screen and (max-width: 991.98px) {
            @content;
        }
    }
    @else if $value=='lg-down' {
        @media only screen and (max-width: 1199.98px) {
            @content;
        }
    }
    @else if $value=='md' {
        @media only screen and (min-width: 768px) and (max-width: 991.98px) {
            @content;
        }
    }
    @else if $value=='lg' {
        @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
            @content;
        }
    }
    @else if $value=='small-lap' {
        @media only screen and (min-width: 1025px) and (max-height: 767px) {
            @content;
        }
    }
    @else if $value=='desktop' {
        @media only screen and (min-width: 1200px) and (max-width:1550px) {
            @content;
        }
    }
    @else if $value=='desktop-lg' {
        @media only screen and (min-width: 1551px) {
            @content;
        }
    }
    @else if $value=='xs-up' {
        @media only screen and (min-width: 576px) {
            @content;
        }
    }
    @else if $value=='sm-up' {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }
    @else if $value=='md-up' {
        @media only screen and (min-width: 992px) {
            @content;
        }
    }
    @else if $value=='lg-up' {
        @media only screen and (min-width: 1200px) {
            @content;
        }
    }
    @else {
        @media only screen and (min-width: 240px) and (max-width: $value) {
            @content;
        }
    }
}

@mixin main-title {
    font-size: 33px;
    line-height: 43px;
    margin-bottom: 16px;   
    @include bp(lg) {
        font-size: 31px;
        line-height: 40px;
    } 
    @include bp(md) {
        font-size: 28px;
        line-height: 32px;
    }
    @include bp(sm-down) {
        font-size: 26px;
        line-height: 30px;
    }
}

@mixin intro-text {
    font-size: 18px;
    line-height: 26px;    
    @include bp(lg) {
        font-size: 16px;
        line-height: 24px;
    }
    @include bp(md) {
        font-size: 16px;
        line-height: 24px;        
    }
    @include bp(sm-down) {
        font-size: 16px;
        line-height: 24px;           
    }
}

@mixin btn-cta {
    display: inline-block;
    width: 197px;
    height: 59px;
    border-radius: 999px;
    background:$color-yellow;
    line-height: 59px;
    font-size: 18px;
    font-family: $font-semi;
    color:$color-gray;
    text-align: center;
    @include bp(md) {
        height: 52px;
        line-height: 52px;
        width: 150px;
    }
    @include bp(sm-down) {
        height: 50px;
        line-height: 50px;
        width: 150px;                
    }
    &:hover {
        background:$color-blue-dark;
        color:$white;
    }    
}

@mixin btn-more {
    display: inline-block;
    margin-left: auto;
    font-size: 16px;
    font-family: $font-heading;
    color: $color-pale;
    line-height: 1.625;
    text-transform: capitalize;
    @include bp(md-down) {
        font-size: 14px;
    }
    &:hover {
        color: $color-heading;
    }
}

@mixin hero-title {
    font-size: 50px;
    line-height: 1.1;
    margin-bottom: 35px;
    @include bp(lg) {
        font-size: 50px;
        margin-bottom: 25px;
    }
    @include bp(md) {
        font-size: 42px;
        margin-bottom: 20px;
    }
    @include bp(sm-down) {
        font-size: 38px;
        margin-bottom: 15px;
    }
    b {
        color:$color-pale;
    }
    strong {
        display: block;
        font-size: 42px;
        line-height: 60px;
        font-family: $font-main;
        font-weight: 400;       
        @include bp(lg) {
            font-size: 36px;
        }         
        @include bp(md) {
            font-size: 30px;
        }
        @include bp(sm-down) {
            font-size: 24px;
        }
    }    
}

@mixin yellow-btn {
    display: inline-block;
    height: 62px;
    border-radius: 999px;
    background:$color-yellow;
    line-height: 62px;
    font-size: 18px;
    font-family: $font-semi;
    color:$color-gray;
    text-align: center;
    text-transform: uppercase;
    padding-left: 40px;
    padding-right: 40px;
    @include bp(md) {
        height: 52px;
        line-height: 52px;
    }
    @include bp(sm-down) {
        height: 50px;
        line-height: 50px;
    }
    &:hover {
        background:$color-blue-dark;
        color:$white;
    }    
}