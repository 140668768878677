// Top Bar
.single-technologies {
    #top-bar {
        background: transparent;
    }
}
#top-bar {
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    background:$white;
    @include animate-fade;
    z-index:1025;
    ul {
        list-style-type: none!important;
        margin-left: 0px;
        margin-bottom: 0px;
    }    
    .container {
        display: flex;
        padding-top: 42px;
        padding-bottom: 42px;
        justify-content: space-between;
        align-items: center;
        @include animate-fade;
        position: relative;
        @include bp(lg) {
            padding-top: 35px;
            padding-bottom: 35px;
        }
        @include bp(md) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        @include bp(sm-down) {
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 25px;
            padding-bottom: 25px;
        }
        // mobile
        #mobile-menu--btn {
            @include centre(y);
            width: 55px;
            right: 30px;
            display: none;
            @include bp(md) {
                display: block;
            }
            @include bp(sm-down) {
                display: block;
            }
            a {
                display: block;
                width: 55px;
                height: auto;
                span {
                    height: 3px;
                    border-radius:6px;
                    background-color:$color-blue;
                    width: 35px;
                    display: block;
                    float: right;
                    @include animate-fade;
                    margin-top: 3px;
                    margin-bottom: 3px;
                    border:none;
                }	
            }            
        }        
        // branding
        .top-bar__branding {
            a {
                display: block;
                width: 100%;
                height: 100%;
            }
            img {
                width: 260px;
                @include bp(lg) {
                    width: 220px;
                }
                @include bp(md) {
                    width: 200px;
                }
                @include bp(sm-down) {
                    width: 200px;
                }
            }
        }
        // top bar nav
        .top-bar__nav {
            ul {
                display: flex;
                align-items: center;
                @include bp(md) {
                    display: none;
                }
                @include bp(sm-down) {
                    display: none;
                }
                li {
                    margin-left: 30px;
                    @include bp(lg) {
                        margin-left: 25px;
                    }
                    a {
                        font-size: 18px;
                        color:$color-text;
                        height: 48px;
                        line-height: 48px;
                        font-family: $font-semi;
                        &:hover {
                            color:$color-blue;
                        }
                    }
                    &:nth-last-child(2) {
                        a {
                            color:$black;
                            position: relative;
                            &:before {
                                display: inline-block;
                                content:'';
                                background: url('../img/ico/phone-receiver-silhouette.svg');
                                background-size: contain;
                                width:24px;
                                height: 24px;
                                margin-right: 8px;
                                position: relative;
                                top:6px;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            display: inline-block;
                            background:$color-yellow;
                            padding-left: 30px;
                            padding-right: 30px;
                            border-radius: 999px;
                            font-family: $font-semi;
                            color:$color-gray;
                            &:hover {
                                background:$color-blue;
                                color:$white;
                            }
                        }
                    }
                }                
            }
        }
    }
    &.sticky {
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
        background:$white;
        .container {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

// Inner Header
#inner-header {
    margin-top: 200px;
    padding-bottom: 70px;
    @include bp(md) {
        margin-top: 150px;
    }
    @include bp(sm-down) {
        margin-top: 80px;
        padding-bottom: 50px;
        padding-top: 30px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        @include bp(sm-down) {
            display: block;
        }
    }
    .header-text {
        width: 50%;
        align-items: center;
        @include bp(md) {
            width: 60%;
        }
        @include bp(sm-down) {
            width: 100%;
            text-align: center;
            padding-bottom: 30px;
        }
        h1 {
            @include hero-title;
        }
        h2 {
            font-size: 24px;
            line-height: 1.33;
            font-family: $font-med;
            width: 70%;
            @include bp(lg) {
                font-size: 20px;
            }
            @include bp(md) {
                font-size: 19px;
            }
            @include bp(sm-down) {
                font-size: 16px;
                width: 100%;
            }
        }      
        p {
            max-width: 80%;
            font-family: $font-med;
            @include bp(sm-down) {
                width: 100%;
                max-width: 90%;
                margin:0px auto;
            }
        }  
        a.btn-cta {
            @include yellow-btn;
            min-width: 200px;
            text-align: center;
            margin-top: 15px;
        }
    }
    .header-image {
        width: 50%;
        @include bp(md) {
            width: 40%;
        }
        @include bp(sm-down) {
            width: 100%;
        }
        img {
            width: 610px;
            max-width: inherit;
            @include bp(lg) {
                width: 100%;
            }
            @include bp(md) {
                width: 100%;
            }
            @include bp(sm-down) {
                width: 100%;
            }
        }
    }
}

// tech header
#tech-header {
    margin-top: 135px;
    padding-bottom: 140px;
    @include bp(md) {
        margin-top: 100px;
    }
    @include bp(sm-down) {
        margin-top: 100px;
        padding-bottom: 50px;
    }    
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include bp(sm-down) {
            display: block;
        }
        .header-text {
            width: 45%;
            @include bp(md) {
                width: 50%;
            }
            @include bp(sm-down) {
                width: 100%;
            }
            .tech-ico {
                width: 64px;
                height: 64px;
                background:$white;
                border:1px solid #ddd;
                border-radius: 12px;
                @include animate-fade;
                position: relative;
                margin-right: 20px;
                margin-bottom: 20px;
                img {
                    @include centre(both);
                    width:36px;
                }
            }            
            h1 {
                @include hero-title;
                font-size: 50px;
                line-height: 1.2;
                @include bp(lg) {
                    font-size: 44px;
                }
                @include bp(md) {
                    font-size: 36px;
                }
            }       
            h2 {
                margin-bottom: 20px;
                font-size: 22px;
                line-height: 1.3;
            }
            p {
                max-width: 90%;
                font-family: $font-med;
                margin-bottom: 0px;
                @include bp(lg) {
                    max-width: 100%;
                }
                @include bp(md) {
                    max-width: 95%;
                }
                @include bp(sm-down) {
                    width: 100%;
                    max-width: 90%;
                }
            }    
        }
        // form
        .header-form {
            width: 50%;
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 40px;
            padding-right: 40px;
            background-color: #fff;
            border-radius: 50px;
            box-shadow: 0 40px 76px rgba(0, 0, 0,  0.08);  
            position: relative;     
            @include bp(lg) {
                padding:30px;
            }
            @include bp(md) {
                padding:30px;
            }
            @include bp(sm-down) {
                width: 100%;
                margin-top: 30px;
                padding:30px;
                border-radius: 24px;
            } 
            &:before{
                position: absolute;
                display: block;
                content: "";
                left: -175px;
                top: -50px;
                width: 350px;
                height: 350px;
                background: rgb(239, 247, 254);
                border-radius: 50%;
                z-index: -1;                
            }
            h3 {
                font-size: 18px;
                margin-bottom: 25px;
                @include bp(md) {
                    margin-bottom: 15px;
                }
                @include bp(sm-down) {
                    margin-bottom: 15px;
                }
            }
            .ff-el-input--label  {
                display: none;
            }
            .ff-el-input--label label {
                display: none;
            }     

            label {
                margin-bottom: 11px;
                font-family: $font-semi;
                display: block;
                @include bp(sm-down) {
                    font-size: 15px;
                    line-height: 1.3;
                }
                span {
                    color:red;
                }
            }

            select {
                background:url('../img/ico/down.svg') no-repeat;
                background-position: 97% center;
                background-size:24px;
            }    
            
            .row-wrapper {
                display: flex;
                margin-left: -10px;
                margin-right: -10px;
                margin-bottom: 30px;
                @include bp(sm-down) {
                    margin-bottom: 15px;
                    display: block;
                    margin-left: 0px;
                    margin-right: 0px;
                }
                .form-block {
                    width: 50%;
                    margin-right: 10px;
                    margin-left: 10px;
                    @include bp(sm-down) {
                        width: 100%;
                        margin-left: 0px;
                        margin-right: 0px;
                        margin-bottom: 15px;
                    }
                }
                .form-block-full {
                    width: 100%;
                }
            }            

            input[type="text"], input[type="tel"], input[type="email"], select {

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                width: 100%;
                height: 62px;
                border-radius: 24px;
                background: #fff;
                border: 2px solid #e7f0f6;
                padding: 0 25px;
                font-size: 16px;
                color: #2d2d2d;
                transition: 0.25s ease-in-out;      
                font-family: $font-main;     
                @include bp(lg) {
                    height: 52px;
                }
                @include bp(md) {
                    height: 52px;
                }
                @include bp(sm-down) {
                    height: 52px;
                } 
                
            }      
            .ff-el-form-check {
                padding-top: 8px;
                font-size: 16px;
                a {
                    color:$color-pale;
                    &:hover {
                        color:$color-blue;
                    }
                }
                input[type="checkbox"] {
                    margin-right: 5px;
                    position: relative;
                    top:-1px;
                }  
            }   

            .checkbox-wrapper {
                font-size: 16px;
                margin-bottom: 10px;
                color:$black;
                label {
                    color:$black;
                    @include bp(sm-down) {
                        font-size: 14px;
                    }
                    span {
                        color:$black;
                    }
                }
                a {
                    color:$color-pale;
                    &:hover {
                        color:$color-blue;
                    }
                }
                .wpcf7-list-item {
                    margin-left: 0px;
                    span {
                        @include bp(lg-down) {
                            font-size: 13px;
                        }
                    }
                }
                input[type="checkbox"] {
                    margin-right: 5px;
                    position: relative;
                    top:1px;

                    position: absolute;
                    width: auto;
                    opacity: 0;

                }    
                .wpcf7-list-item-label {
                    &:before {
                        content: ' ';
                        width: 24px;
                        height: 24px;
                        background: #fff;
                        border: 1px solid #afafb0;
                        margin-right: 15px;
                        display: inline-block;
                        cursor: pointer;
                        position: relative;
                        top: 7px;
                        border-radius: 6px;
                        @include bp(sm-down) {
                            width: 20px;
                            height: 20px;
                            margin-right: 5px;
                            top:5px;
                        }
                    }
                }     
                input:checked+span.wpcf7-list-item-label:before {
                    background-color: $color-pale;
                    border: 1px solid $color-pale;
                    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 70%;
                }       
            }
            .wpcf7-checkbox {
                font-size: 16px;
                a {
                    color:$color-pale;
                    &:hover {
                        color:$color-blue;
                    }
                }
            }

            // validate
            .wpcf7-not-valid-tip {
                font-size: 14px;
                padding-left: 15px;
                padding-top: 7px;
            }
            .wpcf7-response-output {
                border:none;
                padding:0px;
                width: 100%;
                margin-left: 15px;
                color:red;
                @include bp(sm-down) {
                    font-size: 14px;
                    margin:0px;
                    padding:0px;
                }
            }

            .ff-btn-submit, .wpcf7-submit {
                padding: 0;
                border: 0;
                width: auto;
                padding-left: 60px;
                padding-right: 60px;
                height: 86px;
                text-align: center;
                border-radius:999px;
                background-repeat: no-repeat;
                background-position: center center;
                background-color: $color-pale;
                background-image: none;
                font-size: 1.250em;
                @include animate-fade;
                font-family: $font-semi;
                margin:0px;
                border:2px solid transparent;
                margin-right: 0px;
                color:#1e1e1e;
                background:$color-yellow;
                margin-top: 10px;
                @include bp(lg) {
                    margin-top: 15px;
                    height: 70px;
                    line-height: 68px;
                }
                @include bp(md) {
                    margin-top: 15px;
                }
                @include bp(md-down) {
                    width: 100%;
                    height: 70px;
                    line-height: 66px;
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 15px;
                    width: 100%;
                    text-align: center;   
                    padding-left: 0px;
                    padding-right: 0%;      
                    margin-bottom: 5px;              
                }
                @include bp(md) {
                    width: 100%;
                    height: 70px;
                    line-height: 68px;    
                    margin-bottom: 0px;
                    margin-top: 15px;                 
                }                    
                &:hover {
                    background: #082140;
                    color: #fff;                        
                }                      
            }                   
        }
    }
}

// about header
#about-header {
    margin-top: 100px;
    min-height: calc(100vh - 100px);
    // background:url('../img/about-header.jpg') no-repeat;
    background-position: center top;
    position: relative;
    @include bp(md) {
        margin-top: 50px;
        min-height: 80vh;
        height: 80vh;
    }
    @include bp(sm-down) {
        min-height: 500px;
        margin-top: 80px;

    }
    .container {
        text-align: center;
        height: calc(100vh - 100px);
        position: relative;
        .caption {
            @include centre(both);
            width: 90%;
            margin-top: -100px;
            @media screen and (min-width:1024px) and (max-height:780px) {
                margin-top: -30px;
            }
        }
        h1 {
            font-size: 55px;
            line-height: 1.3;
            font-family: $font-med;
            font-weight: 500;
            @include bp(lg) {
                font-size: 45px;
                margin-bottom: 0px;
            }
            @include bp(md) {
                font-size: 40px;
            }
            @include bp(sm-down) {
                font-size: 32px;
            }
            b {
                display: block;
                font-family: $font-heading;
            }
            em {
                position: relative;
                font-style: normal;
                span {
                    z-index:10;
                    position: relative;
                }
                &:before {
                    width: 104%;
                    display: block;
                    content:'';
                    height: 10px;
                    background:$color-yellow;
                    position: absolute;
                    left:-2%;
                    bottom:13px;
                    z-index:0;
                }
            }
        }
        p {
            max-width: 600px;
            margin:0px auto;
            margin-top: 40px;
            @include intro-text;
            @include bp(lg) {
                margin-top: 20px;
            }
            @include bp(md) {
                margin-top: 20px;
            }
            @include bp(sm-down) {
                margin-top: 20px;
            }
        }
    }
    // btn
    #btn-down {
        width: 80px;
        height: 80px;
        background:$color-pale;
        @include centre(x);
        bottom:100px;
        text-align: center;
        border-radius: 100%;
        color:$white;
        font-size: 32px;
        @include animate-fade;
        @media screen and (min-width:1024px) and (max-height:780px) {
            bottom:30px;
            width: 60px;
            height: 60px;
        }
        @include bp(lg) {
            font-size: 28px;
            width: 60px;
            height: 60px;
        }
        @include bp(md) {
            font-size: 26px;
            width: 60px;
            height: 60px;
        }
        @include bp(sm-down) {
            font-size: 26px;
            width: 60px;
            height: 60px;            
        }
        &:hover {
            background:$color-blue;
        }
        a {
            display: block;
            width: 100%;
            height: 100%;
            color:$white;
            -moz-animation: bounce 3s infinite;
            -webkit-animation: bounce 3s infinite;
            animation: bounce 3s infinite;        
            padding-top: 15px;        
        }
    }
    // ovelray
    .overlay {
        position: absolute;
        left:0px;
        width: 100%;
        top:0px;
        height: 100%;
        z-index:-1;
        .tech-logo {
            border-radius: 100%;
            position: absolute;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 24px;
            background:$white;
            opacity: 0.2;
            img {
                @include centre(both);
                width: 60%;
                max-height: 50%;
            }
        }
        .logo-1 {
            top:10vh;
            left:10vw;
            width: 100px;
            height: 100px;
            transition: .15s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                width: 80px;
                height: 80px;
            }
            @include bp(lg) {
                width: 80px;
                height: 80px;
            }
            @include bp(md) {
                width: 60px;
                height: 60px;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
            }
        }
        .logo-2 {
            width: 120px;
            height: 120px;
            top:37vh;
            left:3vw;
            transition: .35s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                width: 100px;
                height: 100px;                
            }
            @include bp(lg) {
                width: 100px;
                height: 100px;
            }
            @include bp(md) {
                width: 80px;
                height: 80px;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
            }            
        }
        .logo-3 {
            width: 140px;
            height: 140px;
            top:25vh;
            left:17vw;
            transition: .25s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                width: 120px;
                height: 120px;                
            }
            @include bp(lg) {
                width: 120px;
                height: 120px;
            }
            @include bp(md) {
                width: 100px;
                height: 100px;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
            }            
        }
        .logo-4 {
            width: 150px;
            height: 150px;
            bottom:12vh;
            left:10vw;
            transition: .55s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                width: 130px;
                height: 130px;                
            }
            @include bp(lg) {
                width: 130px;
                height: 130px;
            }
            @include bp(md) {
                width: 110px;
                height: 110px;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
            }            
        }
        .logo-5 {
            width: 100px;
            height: 100px;
            left:25vw;
            bottom:25vh;
            transition: .5s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                width: 80px;
                height: 80px;                
            }
            @include bp(lg) {
                width: 80px;
                height: 80px;
            }
            @include bp(md) {
                width: 60px;
                height: 60px;
                left:30vw;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
            }            
        }
        .logo-6 {
            width: 140px;
            height: 140px;
            right:18vw;
            top:29vh;
            transition: .85s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                width: 120px;
                height: 120px;                
            }
            @include bp(lg) {
                width: 120px;
                height: 120px;
            }
            @include bp(md) {
                width: 100px;
                height: 100px;
                right:10vw;
                top:23vh;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
            }            
        }
        .logo-7 {
            width: 100px;
            height: 100px;
            right:13vw;
            top:8vh;
            transition: .35s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                height: 80px;
                width: 80px;                
            }
            @include bp(lg) {
                height: 80px;
                width: 80px;
            }
            @include bp(md) {
                width: 60px;
                height: 60px;
                right:10vw;
                top:7vh;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
            }            
        }
        .logo-8 {
            width: 115px;
            height: 115px;
            top:40vh;
            right:5vw;
            transition: .9s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                width: 90px;
                height: 90px;                
            }
            @include bp(lg) {
                width: 90px;
                height: 90px;
            }
            @include bp(md) {
                width: 70px;
                height: 70px;
                top:45vh;
                right:3vw;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
            }            
        }
        .logo-9 {
            width: 135px;
            height: 135px;
            bottom:15vh;
            right:25vw;
            transition: 1.1s ease-in-out;
            @media screen and (min-width:1024px) and (max-height:780px) {
                width: 110px;
                height: 110px;                
            }
            @include bp(lg) {
                width: 110px;
                height: 110px;
            }
            @include bp(md) {
                width: 90px;
                height: 90px;
            }
            @include bp(sm-down) {
                width: 40px;
                height: 40px;
                right:15vw;
                bottom:25vh;
            }            
        }
    }
    // sticl
    &.sticky-about {
        .tech-logo {
            opacity: 1;
        }
    }
}

 @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-5px);
    }
    60% {
      transform: translateY(-5px);
    }
    100% {
        transform: translateY(-5px);
      }    
  }

.cat-header {
    background:$color-blue-light;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 141px;
    margin-bottom: 60px;
    @include bp(lg) {
        margin-top: 119px;
    }
    @include bp(md-down) {
        margin-top: 85px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    h2 {
        margin-bottom: 0px;
        font-size: 32px;
        line-height: 1.3;
        @include bp(md-down) {
            font-size: 24px;
            text-align: center;
        }
    }
}

// Regular header
#regular-header {
    background:$color-blue-light;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 141px;
    text-align: center;
    @include bp(lg) {
        margin-top: 119px;
    }
    @include bp(md-down) {
        margin-top: 95px;
        padding-top: 60px;
        padding-bottom: 60px;
    }    
    h1 {
        @include main-title;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

// Contact header
#tech-header.contact-header {
    background: $color-pale;
    padding-bottom:70px;
    padding-top: 70px;
    .header-text {
        h1 {
            color:$white;
        }
        p {
            color:$white;
        }
        ul {
            margin-top: 55px;
            display: block;
            width: 100%;
            display: flex;
            justify-content: space-between;
            li {
                width: 30%;
                .icon {
                    margin-bottom: 15px;
                    text-align: left;
                    @include bp(sm-down) {
                        text-align: center;
                    }                    
                    img {
                        filter: brightness(0) invert(1);
                    }
                }
                .label {
                    text-align: left;
                    @include bp(sm-down) {
                        text-align: center;
                    }
                    span {
                        text-transform: uppercase;
                        color:$white;
                        font-size: 15px;
                        font-family: $font-semi;
                        line-height: 1.25;
                        display: block;
                        @include bp(md) {
                            line-height: 1.2;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

// Podcast
#podcast-header {
    margin-top: 135px;
    padding-bottom: 140px;
    background-color: $color-pale;
    text-align: center;
    padding-top: 100px;
    @include  bp(lg) {
        padding-bottom: 100px;
        padding-top: 80px;
        margin-top: 100px;
    }
    @include bp(md) {
        margin-top: 85px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include bp(sm-down) {
        margin-top: 100px;
        padding-bottom: 50px;
        padding-top: 50px;
    }    
    h1 {
        font-size: 50px;
        line-height: 1.3;
        font-family: $font-med;
        font-weight: 500;
        color:$white;
        @include bp(lg) {
            font-size: 42px;
            margin-bottom: 0px;
        }
        @include bp(md) {
            font-size: 40px;
        }
        @include bp(sm-down) {
            font-size: 32px;
        }
        b {
            font-family: $font-heading;
        }        
    }
    .podcast-list {
        margin-top: 50px;
        @include bp(md) {
            margin-top: 30px;
        }
        @include bp(sm-down) {
            margin-top: 30px;
        }
        small {
            color:$white;
            text-transform: uppercase;
            font-size: 20px;
            display: block;
            font-family: $font-med;
            margin-bottom: 30px;
        }
        .podcast-wrapper {
            display: flex;
            justify-content: center;
            @include bp(md) {
                flex-wrap: wrap;
            }
            @include bp(sm-down) {
                flex-wrap: wrap;
            }
            .podcast-block {
                border-radius: 10px;
                border:1px solid $white;
                width: 15%;
                margin-left: 10px;
                margin-right: 10px;
                @include animate-fade;
                @include bp(md) {
                    margin-bottom: 15px;
                    width: 30%;
                }
                @include bp(sm-down) {
                    width: 40%;
                    margin-bottom: 15px;
                }
                a {
                    display: block;
                    padding: 15px 28px;

                }
                &:hover {
                    background-color: $white;
                    .podcast-icon {
                        img {
                            filter:none;
                        }
                    }
                    h3 {
                        color:$color-blue;
                    }
                }
                .podcast-icon {
                    height: 75px;
                    position: relative;
                    margin-bottom: 10px;
                    @include bp(md) {
                        height: 60px;
                    }
                    img {
                        filter: brightness(0) invert(1);
                        @include centre(both);
                        height: 42px;

                    }
                }
                h3 {
                    font-family: $font-med;
                    font-size: 18px;
                    color: rgb(0, 0, 0);
                    line-height: 1.333;
                    @include animate-fade;
                    font-weight: 500;
                    color:$white;
                    @include bp(lg) {
                        font-size: 17px;
                    }         
                    @include bp(md) {
                        font-size: 17px;
                    }
                    @include bp(sm-down) {
                        font-size: 16px;
                    }
                }                
            }
        }
    }
}

// soingl
#podcast-single--header {
    margin-top: 135px;
    padding-bottom: 100px;
    background-color: $color-pale;
    padding-top: 100px;
    @include  bp(lg) {
        padding-bottom: 80px;
        padding-top: 80px;
        margin-top: 100px;
    }
    @include bp(md) {
        margin-top: 85px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include bp(sm-down) {
        margin-top: 100px;
        padding-bottom: 50px;
        padding-top: 50px;
    }     
    .container {
        display: flex;
        justify-content: space-between;
        @include bp(sm-down) {
            display: block;
        }
        .podcast-intro  {
            width: 50%;
            padding-top: 30px;
            @include bp(lg) {
                padding-top: 0px;
            }
            @include bp(md) {
                padding-top: 0px;
            }
            @include bp(sm-down) {
                padding-top: 0px;
                width: 100%;
            }
            h1 {
                color:$white;
                font-size: 42px;
                line-height: 1.3;
                margin-bottom: 30px;
                @include bp(lg) {
                    font-size: 32px;
                }
                @include bp(md) {
                    font-size: 30px;
                }
                @include bp(sm-down) {
                    font-size: 26px;
                }
            }
            .author-info {
                display: flex;
                align-items: center;
                margin-bottom: 35px;
                border-bottom: 1px  solid rgba($white,0.3);
                padding-bottom: 20px;
                @include bp(md) {
                    margin-bottom: 15px;
                }
                @include bp(sm-down) {
                    flex-direction: column;
                    text-align: center;
                    margin-bottom: 20px;
                }
                img {
                    border-radius: 50%;
                    width: 48px;
                    margin-right: 17px;
                    @include bp(sm-down) {
                        margin-bottom: 15px;
                        margin-right: 0;
                    }
                }
                .author-content {
                    flex-grow: 1;
                    .author-name {
                        display: block;
                        font-family: $font-semi;
                        font-size: 18px;
                        line-height: 1.2;
                        color: $white;
                        margin-bottom: 5px;
                    }
                    .date-time {
                        span {
                            display: inline-block;
                            font-family: $font-main;
                            font-size: 16px;
                            line-height: 1.2;
                            color: $white;
                            &.post-date {
                                margin-right: 15px;
                            }
                            &.sep {
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }    
            .author-about  {
                p {
                    color:$white;
                }
            }                    
        }
        .podcast-image  {
            width: 45%;
            @include bp(sm-down) {
                width: 100%;
                margin-top: 30px;
            }
            img {
                width: 100%;
                object-fit: cover;
                display: block;
                border-radius: 10px;
                max-height: 600px;
            }
            .podcast-socials {
                text-align: center;
                padding-top: 30px;
                ul {
                    li {
                        display: inline-block;
                        margin-left: 10px;
                        margin-right: 10px;
                        a {
                            font-size: 24px;
                            color:$color-yellow;
                            &:hover {
                                color:$white;
                            }
                        }
                    }
                }
            }            
        }
    }
}