button {
    background: $white;
}

// Listing
#jobs-list-page  {
    padding-top: 80px;
    padding-bottom: 100px;
    @include bp(md-down) {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    .regular-content {
        p {
            font-size: 19px;
            color: rgb(6, 6, 6);
            line-height: 1.684;
            margin-bottom: 30px;
            @include bp(md-down) {
                font-size: 18px;
            }
            a {
                color:$color-blue;
                font-family: $font-semi;
                &:hover {
                    text-decoration: underline;
                    color:$color-blue-dark;
                }
            }                    
        }    
        h2, h1 {
            margin-bottom: 30px;
            line-height: 1.3;
            font-size: 30px;
            @include bp(lg) {
                margin-bottom: 20px;
                font-size: 28px;
            }
            @include bp(md) {
                margin-bottom: 20px;
                font-size: 26px;
            }
            @include bp(sm-down) {
                margin-bottom: 20px;
                font-size: 24px;
            }
        }
        h1 {
            font-size: 35px;
        }        
        ul {
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            border:none!important;
            justify-content: start;
           > li.job_listing {
                background:$white;
                border-radius: 12px;
                padding:15px;
                @include animate-fade;
                
                margin-bottom: 30px;                
                width: 31%;
                padding:0px;
                margin:0px;
                border:none;
                margin-bottom: 30px!important;
                position: relative;
                padding-bottom: 50px;
                border:1px solid #ddd;
                margin-left: 1%;
                margin-right: 1%;
                @include bp(sm-down) {
                    width: 100%;
                }
                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.05%) 0px 5px 20px 0px;
                    transform: scale(1.05);
                    border-color:$color-pale;
                    h3 {
                        color:$color-pale;
                    }
                }
                a {
                    padding-top:25px!important;
                    padding-left: 0px!important;
                    padding-right: 0px!important;
                    position: unset;
                    overflow: unset;
                    background-color: transparent;
                }
                .position {
                    float:none;
                    padding:0px;
                    width: 100%;
                    padding-left: 25px;
                    padding-right: 25px;
                    font-family: $font-med;
                }
                h3 {
                    font-size: 20px;
                    margin-bottom: 15px;
                    color:$color-blue;
                    color:$color-heading;
                    @include bp(lg) {
                        font-size: 19px;
                    }
                    @include bp(md) {
                        font-size: 18px;
                    }
                    
                }
                .location {
                    padding-left: 25px;
                    padding-right: 25px;
                    float:none;
                    width: 100%;
                    padding-left: 25px;
                    margin-left: 0px;
                    margin-bottom: 15px;
                    color:$color-gray;
                    text-transform: uppercase;
                    font-family: $font-med;
                    text-align: left!important;
                    font-size: 16px;
                }
                ul.meta {
                    float:none;
                    width: 100%;
                    display: block;
                    text-align: left;
                    padding-left: 0px;
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-left: 0px;
                    margin-bottom: 0px;
                    background-color: #ececec;
                    color:$black;
                    font-size: 15px;
                    border-bottom-right-radius: 12px;
                    border-bottom-left-radius: 12px;
                    position: absolute;
                    left:0px;
                    width: 100%;
                    bottom:0px!important;
                    z-index:100;
                    li {
                        width: 100%;
                    }
                    li.date {
                        @include bp(md) {
                            font-size: 13px;
                        }
                    }
                }
            }
        }         
    }     
}

// Apply page
.apply-template-default {
    #primary  {
        @include bp(sm-down) {
            margin-top: 0px!important;
        }
        article {
            .entry-header {
                h1 {
                    font-size: 58px;
                    line-height: 1.17;
                    margin-bottom: 55px;
                    max-width: 880px;
                    @include bp(desktop) {
                        font-size: 50px;
                    }
                    @include bp(lg) {
                        font-size: 45px;
                        margin-bottom: 65px;
                    }
                    @include bp(md) {
                        font-size: 40px;
                        margin-bottom: 55px;
                    }
                    @include bp(sm-down) {
                        font-size: 32px;
                        margin-bottom: 45px;
                    }                    
                }
            }
            ul.meta {
                display: flex;
                width: 100%;
                justify-content: space-between;
                background-color: #eff7fe;
                padding:20px;
                border-radius: 12px;
                @include bp(sm-down) {
                    display: block;
                }
            }
            ul#social-share {
                display: flex;
                align-items: center;
                margin-right: 15px;
                svg {
                    width: 22px;
                }
            }
            ul.meta {
                li {
                    @include bp(sm-down) {
                        float:none;
                        width: 100%;
                    }
                }
            }
            li.location {
                font-family: $font-semi;
                font-size: 18px;
                line-height: 1.2;
                color: #000;
                margin-bottom: 0px;
                display: flex;
                align-items: center;
                @include bp(md-down) {
                    font-size: 1em;
                }
                @include bp(sm-down) {
                    margin-right: 0;
                    margin-bottom: 15px;
                }
                &:before {
                    content:url('../img/ico/map-marker-alt-solid.svg');
                    margin-right: 10px;
                    position: relative;
                    top:-1px;
                }                
            }
            .application_dj {
                @include btn-cta;
                @include bp(sm-down) {
                    width: 100%;
                    margin-top: 10px;
                    margin-left: 0%;
                    margin-right: 0px;
                }
            }
            .entry-content {
                p {
                    font-size: 19px;
                    color: rgb(6, 6, 6);
                    line-height: 1.684;
                    margin-bottom: 30px;
                    @include bp(md-down) {
                        font-size: 18px;
                    }
                    a {
                        color:$color-blue;
                        font-family: $font-semi;
                        &:hover {
                            text-decoration: underline;
                            color:$color-blue-dark;
                        }
                    }                    
                }    
                h2 {
                    margin-bottom: 30px;
                    line-height: 1.3;
                    font-size: 30px;
                    @include bp(lg) {
                        margin-bottom: 20px;
                    }
                    @include bp(md) {
                        margin-bottom: 20px;
                    }
                    @include bp(sm-down) {
                        margin-bottom: 20px;
                    }
                }
                h3 {
                    margin-bottom: 20px;
                    margin-top: 50px;
                    line-height: 1.45;
                    font-size: 26px;
                    @include bp(lg) {
                        margin-top: 30px;
                        margin-bottom: 15px;
                    }
                    @include bp(md) {
                        margin-bottom: 15px;
                    }
                    @include bp(sm-down) {
                        margin-bottom: 15px;
                    }
                }
                h4 {
                    margin-bottom: 20px;
                    font-family: $font-med;
                    line-height: 1.3;
                    font-size: 22px;
                }
                ul {
                    margin-bottom: 30px;
                    li {
                        display: block;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        position: relative;
                        font-size: 18px;
                        a {
                            color:$color-blue;
                            font-family: $font-med;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &:before {
                            content:'';
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-radius: 100%;
                            background:$color-pale;
                            position: absolute;
                            left:0px;
                            top:10px;
                        }
                    }
                    ul {
                        margin-top: 15px;
                        margin-bottom: 15px;
                        li {
                            font-size: 16px;
                        }
                    }
                }                  
            }
        }
    }
}

#apply-form {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0 40px 76px rgba(0, 0, 0,  0.08);  
    position: relative;
    margin-bottom: 100px!important;
    @include bp(md) {
        padding:30px;
        border-radius: 30px;
    }
    @include bp(sm-down) {
        padding:30px;
        padding-top: 40px;
        padding-bottom: 40px;
        border-radius: 32px;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 90%;
        height: 100%;
        border-radius: 50px;
        background: #fff;
        opacity: .5;
        box-shadow: 0 30px 76px rgba(0, 0, 0, 0.08);
        top: calc(50% + 30px);
        z-index: -1; 
    }
    &:before {
        position: absolute;
        display: block;
        content: "";
        left: -175px;
        top: -50px;
        width: 350px;
        height: 350px;
        background: #eff7fe;
        border-radius: 50%;
        z-index: -1; 
    }    
    .form-wrapper {
        h2 {
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 1.25;
            @include bp(md) {
                font-size: 30px;
            }
            @include bp(sm-down) {
                font-size: 30px;
            }
            strong {
                color:#227aeb;
            }
        }
        #submitJobForm {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            .field {
                width: 50%;
                padding-right: 15px;
                padding-left: 15px;
                @include bp(sm-down) {
                    width: 100%;
                }
                &:nth-child(5) {
                    width: 100%;
                }
                &:last-child {
                    width: 100%;
                }
                &.Skype {
                    width: 100%;
                }
                label {
                    margin-bottom: 11px;
                    font-family: $font-semi;
                    display: block;
                    span {
                        color:red;
                    }
                }
                input[type="text"], input[type="tel"], input[type="email"], select {

                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    width: 100%;
                    height: 62px;
                    border-radius: 24px;
                    background: #fff;
                    border: 2px solid #e7f0f6;
                    padding: 0 25px;
                    font-size: 16px;
                    color: #2d2d2d;
                    transition: 0.25s ease-in-out;      
                    font-family: $font-main;      
                    
                }    
                select {
                    background:url('../img/ico/down.svg') no-repeat;
                    background-position: 97% center;
                    background-size:24px;
                }  
                textarea {
                    width: 100%;
                    height: 136px;
                    border-radius: 24px;
                    background: #fff;
                    border: 2px solid #e7f0f6;
                    padding: 25px;
                    font-size: 16px;
                    color: #2d2d2d;
                    transition: 0.25s ease-in-out;     
                    font-family: $font-main;                  
                }
                .choose-button {
                    font-size: 20px;
                    font-family: $font-semi;
                    margin-right: 15px;
                    a {
                        border-bottom: 2px solid $color-blue;
                        padding-bottom: 2px;                        
                        color:$color-blue;
                    }
                }
            }
            .How + .field {
                width: 100%;
            }
            #submit_buttons {
                width: 100%;
                margin-top: 0px;
                button.mandatory {
                    padding: 0;
                    border: 0;
                    width: auto;
                    padding-left: 60px;
                    padding-right: 60px;
                    height: 86px;
                    line-height: 84px;
                    text-align: center;
                    border-radius: 999px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: $color-pale;
                    background-image: none;
                    font-size: 1.250em;
                    @include animate-fade;
                    font-family: $font-semi;
                    margin:0px;
                    border:2px solid transparent;
                    margin-right: 0px;
                    color:#1e1e1e;
                    background:$color-yellow;
                    @include bp(md-down) {
                        width: 100%;
                        height: 70px;
                        line-height: 68px;
                        margin-left: 0px;
                        margin-right: 0px;
                        margin-bottom: 15px;
                        padding-left: 0px;
                        padding-right: 0px;
                        width: 100%;
                        text-align: center;                            
                    }
                    @include bp(md) {
                        width: 100%;
                        height: 70px;
                        line-height: 68px;    
                        margin-bottom: 0px;
                        margin-top: 15px;                
                    }                    
                    &:hover {
                        background: #082140;
                        color: #fff;                        
                    }                     
                }
            }
        }
    }
}