@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro Semi";
  src: url("../fonts/SourceSansPro-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins Medium";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins Semi";
  src: url("../fonts/Poppins-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins Bold";
  src: url("../fonts/Poppins-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.fa, .fab, .fas, .fal {
  font-family: "icomoon";
}

body {
  font-size: 16px;
  font-family: "Source Sans Pro";
  -webkit-text-size-adjust: 100%;
  color: #181818;
  padding: 0;
  margin: 0;
  font-weight: 400;
}

p {
  color: #181818;
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: normal;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 26px;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

body h1, body h2, body h3, body h4, body h5, body h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: "Poppins Bold";
  color: #082140;
}

a {
  transition: 0.25s ease-in-out;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

a:focus, button:focus, input:focus, textarea:focus {
  outline: none !important;
}

button, input[type=submit] {
  transition: 0.25s ease-in-out;
  cursor: pointer;
}

address {
  margin: 0px;
  padding: 0px;
}

iframe {
  border: 0;
}

i {
  font-style: normal;
}

#secondary img, article img, figure, img, img.wp-post-image {
  max-width: 100%;
  height: auto;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?xecj5f");
  src: url("../fonts/icomoon.eot?xecj5f#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff2?xecj5f") format("woff2"), url("../fonts/icomoon.ttf?xecj5f") format("truetype"), url("../fonts/icomoon.woff?xecj5f") format("woff"), url("../fonts/icomoon.svg?xecj5f#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-globe-light:before {
  content: "\e90e";
}

.icon-pinterest-p-brands:before {
  content: "\e90a";
}

.icon-instagram-brands:before {
  content: "\e90b";
}

.icon-arrow-right-long-solid:before {
  content: "\e90c";
}

.icon-arrow-left-long-solid:before {
  content: "\e90d";
}

.icon-search:before {
  content: "\e909";
}

.icon-long-arrow-down-light:before {
  content: "\e908";
}

.icon-long-arrow-right-light:before {
  content: "\e907";
}

.icon-facebook-brands:before {
  content: "\e900";
}

.icon-facebook-f-brands:before {
  content: "\e901";
}

.icon-linkedin-brands:before {
  content: "\e902";
}

.icon-linkedin-in-brands:before {
  content: "\e903";
}

.icon-twitter-brands:before {
  content: "\e904";
}

.icon-twitter-square-brands:before {
  content: "\e905";
}

.icon-youtube-brands:before {
  content: "\e906";
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 960px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 720px;
  }
}
@media (min-width: 1400px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  /*@media (max-width: 767.98px) {
      flex-basis: 100%;
  }*/
}

@media (min-width: 576px) {
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*===================================
=            Slick Theme            =
===================================*/
/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../img/ico/ajax-loader.gif") center center no-repeat;
}

.slick-dots {
  list-style: none;
  display: block;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 30px;
}
@media only screen and (max-width: 767.98px) {
  .slick-dots {
    text-align: center;
  }
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin: 0 6px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 12px;
  width: 12px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: #fff;
  background: #CEE7FD;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  /* &:before {
       position: absolute;
       top: 0;
       left: 0;
       content: $slick-dot-character;
       width: 20px;
       height: 20px;
       font-family: $slick-font-family;
       font-size: $slick-dot-size;
       line-height: 20px;
       text-align: center;
       color: $slick-dot-color;
       opacity: $slick-opacity-not-active;
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
   }*/
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
  color: #1E1E1E;
  background: #1E1E1E;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
  color: #1E1E1E;
  background: #1E1E1E;
}
.slick-dots li.slick-active button {
  color: #0D539F;
  background-color: #0D539F;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.single-technologies #top-bar {
  background: transparent;
}

#top-bar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background: #fff;
  transition: 0.25s ease-in-out;
  z-index: 1025;
}
#top-bar ul {
  list-style-type: none !important;
  margin-left: 0px;
  margin-bottom: 0px;
}
#top-bar .container {
  display: flex;
  padding-top: 42px;
  padding-bottom: 42px;
  justify-content: space-between;
  align-items: center;
  transition: 0.25s ease-in-out;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #top-bar .container {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #top-bar .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #top-bar .container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
#top-bar .container #mobile-menu--btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 55px;
  right: 30px;
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #top-bar .container #mobile-menu--btn {
    display: block;
  }
}
@media only screen and (max-width: 767.98px) {
  #top-bar .container #mobile-menu--btn {
    display: block;
  }
}
#top-bar .container #mobile-menu--btn a {
  display: block;
  width: 55px;
  height: auto;
}
#top-bar .container #mobile-menu--btn a span {
  height: 3px;
  border-radius: 6px;
  background-color: #0D539F;
  width: 35px;
  display: block;
  float: right;
  transition: 0.25s ease-in-out;
  margin-top: 3px;
  margin-bottom: 3px;
  border: none;
}
#top-bar .container .top-bar__branding a {
  display: block;
  width: 100%;
  height: 100%;
}
#top-bar .container .top-bar__branding img {
  width: 260px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #top-bar .container .top-bar__branding img {
    width: 220px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #top-bar .container .top-bar__branding img {
    width: 200px;
  }
}
@media only screen and (max-width: 767.98px) {
  #top-bar .container .top-bar__branding img {
    width: 200px;
  }
}
#top-bar .container .top-bar__nav ul {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #top-bar .container .top-bar__nav ul {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  #top-bar .container .top-bar__nav ul {
    display: none;
  }
}
#top-bar .container .top-bar__nav ul li {
  margin-left: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #top-bar .container .top-bar__nav ul li {
    margin-left: 25px;
  }
}
#top-bar .container .top-bar__nav ul li a {
  font-size: 18px;
  color: #181818;
  height: 48px;
  line-height: 48px;
  font-family: "Source Sans Pro Semi";
}
#top-bar .container .top-bar__nav ul li a:hover {
  color: #0D539F;
}
#top-bar .container .top-bar__nav ul li:nth-last-child(2) a {
  color: #000;
  position: relative;
}
#top-bar .container .top-bar__nav ul li:nth-last-child(2) a:before {
  display: inline-block;
  content: "";
  background: url("../img/ico/phone-receiver-silhouette.svg");
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  position: relative;
  top: 6px;
}
#top-bar .container .top-bar__nav ul li:last-child a {
  display: inline-block;
  background: #FED647;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 999px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
}
#top-bar .container .top-bar__nav ul li:last-child a:hover {
  background: #0D539F;
  color: #fff;
}
#top-bar.sticky {
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
}
#top-bar.sticky .container {
  padding-top: 15px;
  padding-bottom: 15px;
}

#inner-header {
  margin-top: 200px;
  padding-bottom: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #inner-header {
    margin-top: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #inner-header {
    margin-top: 80px;
    padding-bottom: 50px;
    padding-top: 30px;
  }
}
#inner-header .container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767.98px) {
  #inner-header .container {
    display: block;
  }
}
#inner-header .header-text {
  width: 50%;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #inner-header .header-text {
    width: 60%;
  }
}
@media only screen and (max-width: 767.98px) {
  #inner-header .header-text {
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
  }
}
#inner-header .header-text h1 {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #inner-header .header-text h1 {
    font-size: 50px;
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #inner-header .header-text h1 {
    font-size: 42px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #inner-header .header-text h1 {
    font-size: 38px;
    margin-bottom: 15px;
  }
}
#inner-header .header-text h1 b {
  color: #227aeb;
}
#inner-header .header-text h1 strong {
  display: block;
  font-size: 42px;
  line-height: 60px;
  font-family: "Source Sans Pro";
  font-weight: 400;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #inner-header .header-text h1 strong {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #inner-header .header-text h1 strong {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #inner-header .header-text h1 strong {
    font-size: 24px;
  }
}
#inner-header .header-text h2 {
  font-size: 24px;
  line-height: 1.33;
  font-family: "Poppins Medium";
  width: 70%;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #inner-header .header-text h2 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #inner-header .header-text h2 {
    font-size: 19px;
  }
}
@media only screen and (max-width: 767.98px) {
  #inner-header .header-text h2 {
    font-size: 16px;
    width: 100%;
  }
}
#inner-header .header-text p {
  max-width: 80%;
  font-family: "Poppins Medium";
}
@media only screen and (max-width: 767.98px) {
  #inner-header .header-text p {
    width: 100%;
    max-width: 90%;
    margin: 0px auto;
  }
}
#inner-header .header-text a.btn-cta {
  display: inline-block;
  height: 62px;
  border-radius: 999px;
  background: #FED647;
  line-height: 62px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
  text-transform: uppercase;
  padding-left: 40px;
  padding-right: 40px;
  min-width: 200px;
  text-align: center;
  margin-top: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #inner-header .header-text a.btn-cta {
    height: 52px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767.98px) {
  #inner-header .header-text a.btn-cta {
    height: 50px;
    line-height: 50px;
  }
}
#inner-header .header-text a.btn-cta:hover {
  background: #082140;
  color: #fff;
}
#inner-header .header-image {
  width: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #inner-header .header-image {
    width: 40%;
  }
}
@media only screen and (max-width: 767.98px) {
  #inner-header .header-image {
    width: 100%;
  }
}
#inner-header .header-image img {
  width: 610px;
  max-width: inherit;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #inner-header .header-image img {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #inner-header .header-image img {
    width: 100%;
  }
}
@media only screen and (max-width: 767.98px) {
  #inner-header .header-image img {
    width: 100%;
  }
}

#tech-header {
  margin-top: 135px;
  padding-bottom: 140px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-header {
    margin-top: 100px;
    padding-bottom: 50px;
  }
}
#tech-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container {
    display: block;
  }
}
#tech-header .container .header-text {
  width: 45%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-text {
    width: 50%;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-text {
    width: 100%;
  }
}
#tech-header .container .header-text .tech-ico {
  width: 64px;
  height: 64px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  transition: 0.25s ease-in-out;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}
#tech-header .container .header-text .tech-ico img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 36px;
}
#tech-header .container .header-text h1 {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 35px;
  font-size: 50px;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-header .container .header-text h1 {
    font-size: 50px;
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-text h1 {
    font-size: 42px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-text h1 {
    font-size: 38px;
    margin-bottom: 15px;
  }
}
#tech-header .container .header-text h1 b {
  color: #227aeb;
}
#tech-header .container .header-text h1 strong {
  display: block;
  font-size: 42px;
  line-height: 60px;
  font-family: "Source Sans Pro";
  font-weight: 400;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-header .container .header-text h1 strong {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-text h1 strong {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-text h1 strong {
    font-size: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-header .container .header-text h1 {
    font-size: 44px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-text h1 {
    font-size: 36px;
  }
}
#tech-header .container .header-text h2 {
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 1.3;
}
#tech-header .container .header-text p {
  max-width: 90%;
  font-family: "Poppins Medium";
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-header .container .header-text p {
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-text p {
    max-width: 95%;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-text p {
    width: 100%;
    max-width: 90%;
  }
}
#tech-header .container .header-form {
  width: 50%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 40px 76px rgba(0, 0, 0, 0.08);
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-header .container .header-form {
    padding: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-form {
    padding: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form {
    width: 100%;
    margin-top: 30px;
    padding: 30px;
    border-radius: 24px;
  }
}
#tech-header .container .header-form:before {
  position: absolute;
  display: block;
  content: "";
  left: -175px;
  top: -50px;
  width: 350px;
  height: 350px;
  background: rgb(239, 247, 254);
  border-radius: 50%;
  z-index: -1;
}
#tech-header .container .header-form h3 {
  font-size: 18px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-form h3 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form h3 {
    margin-bottom: 15px;
  }
}
#tech-header .container .header-form .ff-el-input--label {
  display: none;
}
#tech-header .container .header-form .ff-el-input--label label {
  display: none;
}
#tech-header .container .header-form label {
  margin-bottom: 11px;
  font-family: "Source Sans Pro Semi";
  display: block;
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form label {
    font-size: 15px;
    line-height: 1.3;
  }
}
#tech-header .container .header-form label span {
  color: red;
}
#tech-header .container .header-form select {
  background: url("../img/ico/down.svg") no-repeat;
  background-position: 97% center;
  background-size: 24px;
}
#tech-header .container .header-form .row-wrapper {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form .row-wrapper {
    margin-bottom: 15px;
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
}
#tech-header .container .header-form .row-wrapper .form-block {
  width: 50%;
  margin-right: 10px;
  margin-left: 10px;
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form .row-wrapper .form-block {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}
#tech-header .container .header-form .row-wrapper .form-block-full {
  width: 100%;
}
#tech-header .container .header-form input[type=text], #tech-header .container .header-form input[type=tel], #tech-header .container .header-form input[type=email], #tech-header .container .header-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 62px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 0 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-header .container .header-form input[type=text], #tech-header .container .header-form input[type=tel], #tech-header .container .header-form input[type=email], #tech-header .container .header-form select {
    height: 52px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-form input[type=text], #tech-header .container .header-form input[type=tel], #tech-header .container .header-form input[type=email], #tech-header .container .header-form select {
    height: 52px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form input[type=text], #tech-header .container .header-form input[type=tel], #tech-header .container .header-form input[type=email], #tech-header .container .header-form select {
    height: 52px;
  }
}
#tech-header .container .header-form .ff-el-form-check {
  padding-top: 8px;
  font-size: 16px;
}
#tech-header .container .header-form .ff-el-form-check a {
  color: #227aeb;
}
#tech-header .container .header-form .ff-el-form-check a:hover {
  color: #0D539F;
}
#tech-header .container .header-form .ff-el-form-check input[type=checkbox] {
  margin-right: 5px;
  position: relative;
  top: -1px;
}
#tech-header .container .header-form .checkbox-wrapper {
  font-size: 16px;
  margin-bottom: 10px;
  color: #000;
}
#tech-header .container .header-form .checkbox-wrapper label {
  color: #000;
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form .checkbox-wrapper label {
    font-size: 14px;
  }
}
#tech-header .container .header-form .checkbox-wrapper label span {
  color: #000;
}
#tech-header .container .header-form .checkbox-wrapper a {
  color: #227aeb;
}
#tech-header .container .header-form .checkbox-wrapper a:hover {
  color: #0D539F;
}
#tech-header .container .header-form .checkbox-wrapper .wpcf7-list-item {
  margin-left: 0px;
}
@media only screen and (max-width: 1199.98px) {
  #tech-header .container .header-form .checkbox-wrapper .wpcf7-list-item span {
    font-size: 13px;
  }
}
#tech-header .container .header-form .checkbox-wrapper input[type=checkbox] {
  margin-right: 5px;
  position: relative;
  top: 1px;
  position: absolute;
  width: auto;
  opacity: 0;
}
#tech-header .container .header-form .checkbox-wrapper .wpcf7-list-item-label:before {
  content: " ";
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #afafb0;
  margin-right: 15px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 7px;
  border-radius: 6px;
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form .checkbox-wrapper .wpcf7-list-item-label:before {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    top: 5px;
  }
}
#tech-header .container .header-form .checkbox-wrapper input:checked + span.wpcf7-list-item-label:before {
  background-color: #227aeb;
  border: 1px solid #227aeb;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
}
#tech-header .container .header-form .wpcf7-checkbox {
  font-size: 16px;
}
#tech-header .container .header-form .wpcf7-checkbox a {
  color: #227aeb;
}
#tech-header .container .header-form .wpcf7-checkbox a:hover {
  color: #0D539F;
}
#tech-header .container .header-form .wpcf7-not-valid-tip {
  font-size: 14px;
  padding-left: 15px;
  padding-top: 7px;
}
#tech-header .container .header-form .wpcf7-response-output {
  border: none;
  padding: 0px;
  width: 100%;
  margin-left: 15px;
  color: red;
}
@media only screen and (max-width: 767.98px) {
  #tech-header .container .header-form .wpcf7-response-output {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
  }
}
#tech-header .container .header-form .ff-btn-submit, #tech-header .container .header-form .wpcf7-submit {
  padding: 0;
  border: 0;
  width: auto;
  padding-left: 60px;
  padding-right: 60px;
  height: 86px;
  text-align: center;
  border-radius: 999px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid transparent;
  margin-right: 0px;
  color: #1e1e1e;
  background: #FED647;
  margin-top: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-header .container .header-form .ff-btn-submit, #tech-header .container .header-form .wpcf7-submit {
    margin-top: 15px;
    height: 70px;
    line-height: 68px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-form .ff-btn-submit, #tech-header .container .header-form .wpcf7-submit {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 991.98px) {
  #tech-header .container .header-form .ff-btn-submit, #tech-header .container .header-form .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 66px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    padding-left: 0px;
    padding-right: 0%;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header .container .header-form .ff-btn-submit, #tech-header .container .header-form .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
#tech-header .container .header-form .ff-btn-submit:hover, #tech-header .container .header-form .wpcf7-submit:hover {
  background: #082140;
  color: #fff;
}

#about-header {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  background-position: center top;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header {
    margin-top: 50px;
    min-height: 80vh;
    height: 80vh;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header {
    min-height: 500px;
    margin-top: 80px;
  }
}
#about-header .container {
  text-align: center;
  height: calc(100vh - 100px);
  position: relative;
}
#about-header .container .caption {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  margin-top: -100px;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .container .caption {
    margin-top: -30px;
  }
}
#about-header .container h1 {
  font-size: 55px;
  line-height: 1.3;
  font-family: "Poppins Medium";
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .container h1 {
    font-size: 45px;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .container h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .container h1 {
    font-size: 32px;
  }
}
#about-header .container h1 b {
  display: block;
  font-family: "Poppins Bold";
}
#about-header .container h1 em {
  position: relative;
  font-style: normal;
}
#about-header .container h1 em span {
  z-index: 10;
  position: relative;
}
#about-header .container h1 em:before {
  width: 104%;
  display: block;
  content: "";
  height: 10px;
  background: #FED647;
  position: absolute;
  left: -2%;
  bottom: 13px;
  z-index: 0;
}
#about-header .container p {
  max-width: 600px;
  margin: 0px auto;
  margin-top: 40px;
  font-size: 18px;
  line-height: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .container p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .container p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .container p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .container p {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .container p {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .container p {
    margin-top: 20px;
  }
}
#about-header #btn-down {
  width: 80px;
  height: 80px;
  background: #227aeb;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 100px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  font-size: 32px;
  transition: 0.25s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header #btn-down {
    bottom: 30px;
    width: 60px;
    height: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header #btn-down {
    font-size: 28px;
    width: 60px;
    height: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header #btn-down {
    font-size: 26px;
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header #btn-down {
    font-size: 26px;
    width: 60px;
    height: 60px;
  }
}
#about-header #btn-down:hover {
  background: #0D539F;
}
#about-header #btn-down a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
  padding-top: 15px;
}
#about-header .overlay {
  position: absolute;
  left: 0px;
  width: 100%;
  top: 0px;
  height: 100%;
  z-index: -1;
}
#about-header .overlay .tech-logo {
  border-radius: 100%;
  position: absolute;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 24px;
  background: #fff;
  opacity: 0.2;
}
#about-header .overlay .tech-logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60%;
  max-height: 50%;
}
#about-header .overlay .logo-1 {
  top: 10vh;
  left: 10vw;
  width: 100px;
  height: 100px;
  transition: 0.15s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-1 {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-1 {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-1 {
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-1 {
    width: 40px;
    height: 40px;
  }
}
#about-header .overlay .logo-2 {
  width: 120px;
  height: 120px;
  top: 37vh;
  left: 3vw;
  transition: 0.35s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-2 {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-2 {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-2 {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-2 {
    width: 40px;
    height: 40px;
  }
}
#about-header .overlay .logo-3 {
  width: 140px;
  height: 140px;
  top: 25vh;
  left: 17vw;
  transition: 0.25s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-3 {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-3 {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-3 {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-3 {
    width: 40px;
    height: 40px;
  }
}
#about-header .overlay .logo-4 {
  width: 150px;
  height: 150px;
  bottom: 12vh;
  left: 10vw;
  transition: 0.55s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-4 {
    width: 130px;
    height: 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-4 {
    width: 130px;
    height: 130px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-4 {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-4 {
    width: 40px;
    height: 40px;
  }
}
#about-header .overlay .logo-5 {
  width: 100px;
  height: 100px;
  left: 25vw;
  bottom: 25vh;
  transition: 0.5s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-5 {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-5 {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-5 {
    width: 60px;
    height: 60px;
    left: 30vw;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-5 {
    width: 40px;
    height: 40px;
  }
}
#about-header .overlay .logo-6 {
  width: 140px;
  height: 140px;
  right: 18vw;
  top: 29vh;
  transition: 0.85s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-6 {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-6 {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-6 {
    width: 100px;
    height: 100px;
    right: 10vw;
    top: 23vh;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-6 {
    width: 40px;
    height: 40px;
  }
}
#about-header .overlay .logo-7 {
  width: 100px;
  height: 100px;
  right: 13vw;
  top: 8vh;
  transition: 0.35s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-7 {
    height: 80px;
    width: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-7 {
    height: 80px;
    width: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-7 {
    width: 60px;
    height: 60px;
    right: 10vw;
    top: 7vh;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-7 {
    width: 40px;
    height: 40px;
  }
}
#about-header .overlay .logo-8 {
  width: 115px;
  height: 115px;
  top: 40vh;
  right: 5vw;
  transition: 0.9s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-8 {
    width: 90px;
    height: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-8 {
    width: 90px;
    height: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-8 {
    width: 70px;
    height: 70px;
    top: 45vh;
    right: 3vw;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-8 {
    width: 40px;
    height: 40px;
  }
}
#about-header .overlay .logo-9 {
  width: 135px;
  height: 135px;
  bottom: 15vh;
  right: 25vw;
  transition: 1.1s ease-in-out;
}
@media screen and (min-width: 1024px) and (max-height: 780px) {
  #about-header .overlay .logo-9 {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-header .overlay .logo-9 {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-header .overlay .logo-9 {
    width: 90px;
    height: 90px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-header .overlay .logo-9 {
    width: 40px;
    height: 40px;
    right: 15vw;
    bottom: 25vh;
  }
}
#about-header.sticky-about .tech-logo {
  opacity: 1;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(-5px);
  }
}
.cat-header {
  background: #CEE7FD;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 141px;
  margin-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .cat-header {
    margin-top: 119px;
  }
}
@media only screen and (max-width: 991.98px) {
  .cat-header {
    margin-top: 85px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.cat-header h2 {
  margin-bottom: 0px;
  font-size: 32px;
  line-height: 1.3;
}
@media only screen and (max-width: 991.98px) {
  .cat-header h2 {
    font-size: 24px;
    text-align: center;
  }
}

#regular-header {
  background: #CEE7FD;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 141px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #regular-header {
    margin-top: 119px;
  }
}
@media only screen and (max-width: 991.98px) {
  #regular-header {
    margin-top: 95px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
#regular-header h1 {
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #regular-header h1 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #regular-header h1 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #regular-header h1 {
    font-size: 26px;
    line-height: 30px;
  }
}

#tech-header.contact-header {
  background: #227aeb;
  padding-bottom: 70px;
  padding-top: 70px;
}
#tech-header.contact-header .header-text h1 {
  color: #fff;
}
#tech-header.contact-header .header-text p {
  color: #fff;
}
#tech-header.contact-header .header-text ul {
  margin-top: 55px;
  display: block;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#tech-header.contact-header .header-text ul li {
  width: 30%;
}
#tech-header.contact-header .header-text ul li .icon {
  margin-bottom: 15px;
  text-align: left;
}
@media only screen and (max-width: 767.98px) {
  #tech-header.contact-header .header-text ul li .icon {
    text-align: center;
  }
}
#tech-header.contact-header .header-text ul li .icon img {
  filter: brightness(0) invert(1);
}
#tech-header.contact-header .header-text ul li .label {
  text-align: left;
}
@media only screen and (max-width: 767.98px) {
  #tech-header.contact-header .header-text ul li .label {
    text-align: center;
  }
}
#tech-header.contact-header .header-text ul li .label span {
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  font-family: "Source Sans Pro Semi";
  line-height: 1.25;
  display: block;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-header.contact-header .header-text ul li .label span {
    line-height: 1.2;
    font-size: 14px;
  }
}

#podcast-header {
  margin-top: 135px;
  padding-bottom: 140px;
  background-color: #227aeb;
  text-align: center;
  padding-top: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-header {
    padding-bottom: 100px;
    padding-top: 80px;
    margin-top: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-header {
    margin-top: 85px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-header {
    margin-top: 100px;
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
#podcast-header h1 {
  font-size: 50px;
  line-height: 1.3;
  font-family: "Poppins Medium";
  font-weight: 500;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-header h1 {
    font-size: 42px;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-header h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-header h1 {
    font-size: 32px;
  }
}
#podcast-header h1 b {
  font-family: "Poppins Bold";
}
#podcast-header .podcast-list {
  margin-top: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-header .podcast-list {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-header .podcast-list {
    margin-top: 30px;
  }
}
#podcast-header .podcast-list small {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  display: block;
  font-family: "Poppins Medium";
  margin-bottom: 30px;
}
#podcast-header .podcast-list .podcast-wrapper {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-header .podcast-list .podcast-wrapper {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-header .podcast-list .podcast-wrapper {
    flex-wrap: wrap;
  }
}
#podcast-header .podcast-list .podcast-wrapper .podcast-block {
  border-radius: 10px;
  border: 1px solid #fff;
  width: 15%;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.25s ease-in-out;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-header .podcast-list .podcast-wrapper .podcast-block {
    margin-bottom: 15px;
    width: 30%;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-header .podcast-list .podcast-wrapper .podcast-block {
    width: 40%;
    margin-bottom: 15px;
  }
}
#podcast-header .podcast-list .podcast-wrapper .podcast-block a {
  display: block;
  padding: 15px 28px;
}
#podcast-header .podcast-list .podcast-wrapper .podcast-block:hover {
  background-color: #fff;
}
#podcast-header .podcast-list .podcast-wrapper .podcast-block:hover .podcast-icon img {
  filter: none;
}
#podcast-header .podcast-list .podcast-wrapper .podcast-block:hover h3 {
  color: #0D539F;
}
#podcast-header .podcast-list .podcast-wrapper .podcast-block .podcast-icon {
  height: 75px;
  position: relative;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-header .podcast-list .podcast-wrapper .podcast-block .podcast-icon {
    height: 60px;
  }
}
#podcast-header .podcast-list .podcast-wrapper .podcast-block .podcast-icon img {
  filter: brightness(0) invert(1);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 42px;
}
#podcast-header .podcast-list .podcast-wrapper .podcast-block h3 {
  font-family: "Poppins Medium";
  font-size: 18px;
  color: rgb(0, 0, 0);
  line-height: 1.333;
  transition: 0.25s ease-in-out;
  font-weight: 500;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-header .podcast-list .podcast-wrapper .podcast-block h3 {
    font-size: 17px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-header .podcast-list .podcast-wrapper .podcast-block h3 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-header .podcast-list .podcast-wrapper .podcast-block h3 {
    font-size: 16px;
  }
}

#podcast-single--header {
  margin-top: 135px;
  padding-bottom: 100px;
  background-color: #227aeb;
  padding-top: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-single--header {
    padding-bottom: 80px;
    padding-top: 80px;
    margin-top: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-single--header {
    margin-top: 85px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-single--header {
    margin-top: 100px;
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
#podcast-single--header .container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767.98px) {
  #podcast-single--header .container {
    display: block;
  }
}
#podcast-single--header .container .podcast-intro {
  width: 50%;
  padding-top: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-single--header .container .podcast-intro {
    padding-top: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-single--header .container .podcast-intro {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-single--header .container .podcast-intro {
    padding-top: 0px;
    width: 100%;
  }
}
#podcast-single--header .container .podcast-intro h1 {
  color: #fff;
  font-size: 42px;
  line-height: 1.3;
  margin-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-single--header .container .podcast-intro h1 {
    font-size: 32px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-single--header .container .podcast-intro h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-single--header .container .podcast-intro h1 {
    font-size: 26px;
  }
}
#podcast-single--header .container .podcast-intro .author-info {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-single--header .container .podcast-intro .author-info {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-single--header .container .podcast-intro .author-info {
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }
}
#podcast-single--header .container .podcast-intro .author-info img {
  border-radius: 50%;
  width: 48px;
  margin-right: 17px;
}
@media only screen and (max-width: 767.98px) {
  #podcast-single--header .container .podcast-intro .author-info img {
    margin-bottom: 15px;
    margin-right: 0;
  }
}
#podcast-single--header .container .podcast-intro .author-info .author-content {
  flex-grow: 1;
}
#podcast-single--header .container .podcast-intro .author-info .author-content .author-name {
  display: block;
  font-family: "Source Sans Pro Semi";
  font-size: 18px;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 5px;
}
#podcast-single--header .container .podcast-intro .author-info .author-content .date-time span {
  display: inline-block;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.2;
  color: #fff;
}
#podcast-single--header .container .podcast-intro .author-info .author-content .date-time span.post-date {
  margin-right: 15px;
}
#podcast-single--header .container .podcast-intro .author-info .author-content .date-time span.sep {
  margin-right: 15px;
}
#podcast-single--header .container .podcast-intro .author-about p {
  color: #fff;
}
#podcast-single--header .container .podcast-image {
  width: 45%;
}
@media only screen and (max-width: 767.98px) {
  #podcast-single--header .container .podcast-image {
    width: 100%;
    margin-top: 30px;
  }
}
#podcast-single--header .container .podcast-image img {
  width: 100%;
  object-fit: cover;
  display: block;
  border-radius: 10px;
  max-height: 600px;
}
#podcast-single--header .container .podcast-image .podcast-socials {
  text-align: center;
  padding-top: 30px;
}
#podcast-single--header .container .podcast-image .podcast-socials ul li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
#podcast-single--header .container .podcast-image .podcast-socials ul li a {
  font-size: 24px;
  color: #FED647;
}
#podcast-single--header .container .podcast-image .podcast-socials ul li a:hover {
  color: #fff;
}

.single .elementor h1, .single .elementor h2, .single .elementor h3, .single .elementor h4, .single .elementor h5 {
  margin-bottom: 30px;
  margin-top: 20px;
}

.page-content h1, .page-content h2, .page-content h3, .page-content h4, .page-content h5 {
  margin-bottom: 30px;
  margin-top: 20px;
}

.elementor-section-wrap {
  overflow-x: hidden;
}

.elementor-container {
  max-width: 1140px !important;
}

.rp4wp-related-job_listing > ul li.job_listing a, .rp4wp-related-job_listing > ul li.no_job_listings_found a, ul.job_listings li.job_listing a, ul.job_listings li.no_job_listings_found a {
  padding-left: 0px !important;
}

#ermac-wrapper {
  padding-top: 180px;
  padding-bottom: 150px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #ermac-wrapper {
    padding-top: 140px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #ermac-wrapper {
    padding-top: 150px;
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #ermac-wrapper {
    padding-top: 130px;
    padding-bottom: 80px;
  }
}
#ermac-wrapper .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #ermac-wrapper .container {
    display: block;
    text-align: center;
  }
}
@media only screen and (max-width: 767.98px) {
  #ermac-wrapper .container {
    display: block;
    text-align: center;
  }
}
#ermac-wrapper .ermac-image {
  width: 45%;
}
@media only screen and (max-width: 991.98px) {
  #ermac-wrapper .ermac-image {
    width: 100%;
    margin-top: 50px;
  }
}
#ermac-wrapper .ermac-image img {
  max-width: 90%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #ermac-wrapper .ermac-image img {
    max-width: 400px;
  }
}
#ermac-wrapper .ermac-content {
  width: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #ermac-wrapper .ermac-content {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #ermac-wrapper .ermac-content {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}
#ermac-wrapper h1 {
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #227aeb;
  font-size: 64px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #ermac-wrapper h1 {
    font-size: 52px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #ermac-wrapper h1 {
    font-size: 52px;
  }
}
@media only screen and (max-width: 767.98px) {
  #ermac-wrapper h1 {
    font-size: 42px;
  }
}
#ermac-wrapper h2 {
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #ermac-wrapper h2 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #ermac-wrapper h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767.98px) {
  #ermac-wrapper h2 {
    font-size: 22px;
  }
}
#ermac-wrapper h3 {
  margin-top: 40px;
  margin-bottom: 15px;
  font-size: 22px;
}
#ermac-wrapper p:first-of-type {
  font-size: 18px;
  line-height: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #ermac-wrapper p:first-of-type {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #ermac-wrapper p:first-of-type {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #ermac-wrapper p:first-of-type {
    font-size: 16px;
    line-height: 24px;
  }
}
#ermac-wrapper a.btn-cta {
  display: inline-block;
  height: 62px;
  border-radius: 999px;
  background: #FED647;
  line-height: 62px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
  text-transform: uppercase;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #ermac-wrapper a.btn-cta {
    height: 52px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767.98px) {
  #ermac-wrapper a.btn-cta {
    height: 50px;
    line-height: 50px;
  }
}
#ermac-wrapper a.btn-cta:hover {
  background: #082140;
  color: #fff;
}

#tech-stack {
  background: #eff7fe;
  padding-top: 100px;
  padding-bottom: 300px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-stack {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-stack {
    padding-top: 60px;
    padding-bottom: 250px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-stack {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#tech-stack header {
  text-align: center;
  width: 690px;
  margin: 0 auto;
  padding-bottom: 50px;
}
@media only screen and (max-width: 767.98px) {
  #tech-stack header {
    width: 90%;
  }
}
#tech-stack header h2 {
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-stack header h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-stack header h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-stack header h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
#tech-stack header h2 strong {
  color: #227aeb;
}
#tech-stack header p {
  font-size: 18px;
  line-height: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-stack header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-stack header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-stack header p {
    font-size: 16px;
    line-height: 24px;
  }
}
#tech-stack .tab {
  margin: 0px auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #227aeb;
  width: auto;
  padding: 5px;
  width: 400px;
  border-radius: 999px;
}
@media only screen and (max-width: 767.98px) {
  #tech-stack .tab {
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 4px;
  }
}
#tech-stack .tab button {
  width: 200px;
  border-radius: 999px;
  border: none;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #227aeb;
  padding-top: 13px;
  padding-bottom: 13px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-stack .tab button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-stack .tab button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
  }
}
#tech-stack .tab button.active {
  background: #227aeb !important;
  color: #fff !important;
}
#tech-stack .tab button:hover {
  color: #227aeb;
  background: none;
}
#tech-stack .tech-list a {
  display: block;
  width: 100%;
  height: 100%;
}
#tech-stack .tech-list a:hover .tech-card {
  background: #0D539F;
  transform: scale(1.1);
}
#tech-stack .tech-list a:hover .tech-card h4 {
  color: #fff;
}
#tech-stack .tech-list a:hover .tech-card p {
  color: #fff;
}
#tech-stack .tech-list a:hover .tech-card span {
  color: #FED647;
}
#tech-stack .tech-list .tech-card {
  background: #fff;
  border-radius: 16px;
  padding: 15px;
  transition: 0.25s ease-in-out;
  box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #tech-stack .tech-list .tech-card {
    margin-bottom: 15px;
  }
}
#tech-stack .tech-list .tech-card .tech-ico {
  width: 64px;
  height: 64px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  transition: 0.25s ease-in-out;
  position: relative;
  margin-right: 20px;
}
#tech-stack .tech-list .tech-card .tech-ico img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 36px;
}
#tech-stack .tech-list .tech-card .tech-desc {
  flex: 1;
}
#tech-stack .tech-list .tech-card h4 {
  margin-top: 0px;
  font-size: 18px;
  margin-bottom: 3px;
  transition: 0.25s ease-in-out;
  font-family: "Poppins Medium";
  font-weight: 500;
}
#tech-stack .tech-list .tech-card p {
  font-size: 16px;
  line-height: 24px;
  transition: 0.25s ease-in-out;
  margin-bottom: 0px;
  line-height: 20px;
  padding-right: 0px;
}
#tech-stack .tech-list .tech-card span {
  font-family: "Source Sans Pro Semi";
  color: #227aeb;
  transition: 0.25s ease-in-out;
}

#contact-cta--inner {
  margin-bottom: 150px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #contact-cta--inner {
    margin-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta--inner {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta--inner {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
#contact-cta--inner #contact-wrapper {
  margin: 0px auto;
  max-width: 960px;
  padding: 40px 65px 62px 65px;
  background-color: #227aeb;
  border-radius: 50px;
  box-shadow: 0 40px 76px rgba(0, 0, 0, 0.08);
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta--inner #contact-wrapper {
    padding: 30px;
    border-radius: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta--inner #contact-wrapper {
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta--inner #contact-wrapper {
    padding: 30px;
  }
}
#contact-cta--inner #contact-wrapper h3 {
  margin-bottom: 20px;
  font-size: 32px;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta--inner #contact-wrapper h3 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta--inner #contact-wrapper h3 {
    font-size: 30px;
  }
}
#contact-cta--inner #contact-wrapper h3 strong {
  color: #227aeb;
}
#contact-cta--inner #contact-wrapper p {
  margin-bottom: 20px;
  margin-top: -10px;
  font-size: 1.25em;
  font-size: 18px;
  color: #fff;
  width: 80%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta--inner #contact-wrapper p {
    width: 100%;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta--inner #contact-wrapper p {
    width: 100%;
  }
}
#contact-cta--inner #contact-wrapper .form-wrapper .wpcf7-not-valid-tip {
  font-size: 14px;
  padding-left: 15px;
  padding-top: 7px;
  color: #ffd4d4;
}
#contact-cta--inner #contact-wrapper .form-wrapper .wpcf7-response-output {
  border: none;
  padding: 0px;
  width: 100%;
  margin-left: 15px;
  color: #ffd4d4;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform, #contact-cta--inner #contact-wrapper .form-wrapper form {
  padding-top: 20px;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .row-wrapper, #contact-cta--inner #contact-wrapper .form-wrapper form .row-wrapper {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767.98px) {
  #contact-cta--inner #contact-wrapper .form-wrapper .fluentform .row-wrapper, #contact-cta--inner #contact-wrapper .form-wrapper form .row-wrapper {
    margin-bottom: 15px;
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .row-wrapper .form-block, #contact-cta--inner #contact-wrapper .form-wrapper form .row-wrapper .form-block {
  width: 50%;
  margin-right: 15px;
  margin-left: 15px;
}
@media only screen and (max-width: 767.98px) {
  #contact-cta--inner #contact-wrapper .form-wrapper .fluentform .row-wrapper .form-block, #contact-cta--inner #contact-wrapper .form-wrapper form .row-wrapper .form-block {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .row-wrapper .form-block-full, #contact-cta--inner #contact-wrapper .form-wrapper form .row-wrapper .form-block-full {
  width: 100%;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform label, #contact-cta--inner #contact-wrapper .form-wrapper form label {
  margin-bottom: 11px;
  font-family: "Source Sans Pro Semi";
  display: block;
  color: #fff;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform label span, #contact-cta--inner #contact-wrapper .form-wrapper form label span {
  color: red;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform input[type=text], #contact-cta--inner #contact-wrapper .form-wrapper .fluentform input[type=tel], #contact-cta--inner #contact-wrapper .form-wrapper .fluentform input[type=email], #contact-cta--inner #contact-wrapper .form-wrapper .fluentform select, #contact-cta--inner #contact-wrapper .form-wrapper form input[type=text], #contact-cta--inner #contact-wrapper .form-wrapper form input[type=tel], #contact-cta--inner #contact-wrapper .form-wrapper form input[type=email], #contact-cta--inner #contact-wrapper .form-wrapper form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 62px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 0 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform select, #contact-cta--inner #contact-wrapper .form-wrapper form select {
  background: url("../img/ico/down.svg") no-repeat;
  background-position: 97% center;
  background-size: 24px;
  background-color: #fff;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform textarea, #contact-cta--inner #contact-wrapper .form-wrapper form textarea {
  width: 100%;
  height: 136px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .ff-el-form-check, #contact-cta--inner #contact-wrapper .form-wrapper form .ff-el-form-check {
  padding-top: 8px;
  font-size: 16px;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .ff-el-form-check a, #contact-cta--inner #contact-wrapper .form-wrapper form .ff-el-form-check a {
  color: #227aeb;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .ff-el-form-check a:hover, #contact-cta--inner #contact-wrapper .form-wrapper form .ff-el-form-check a:hover {
  color: #0D539F;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .ff-el-form-check input[type=checkbox], #contact-cta--inner #contact-wrapper .form-wrapper form .ff-el-form-check input[type=checkbox] {
  margin-right: 5px;
  position: relative;
  top: -1px;
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .ff-btn-submit, #contact-cta--inner #contact-wrapper .form-wrapper .fluentform .wpcf7-submit, #contact-cta--inner #contact-wrapper .form-wrapper form .ff-btn-submit, #contact-cta--inner #contact-wrapper .form-wrapper form .wpcf7-submit {
  padding: 0;
  border: 0;
  width: auto;
  padding-left: 60px;
  padding-right: 60px;
  height: 86px;
  line-height: 84px;
  text-align: center;
  border-radius: 999px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid transparent;
  margin-right: 0px;
  color: #1e1e1e;
  background: #FED647;
  margin-top: 20px;
}
@media only screen and (max-width: 991.98px) {
  #contact-cta--inner #contact-wrapper .form-wrapper .fluentform .ff-btn-submit, #contact-cta--inner #contact-wrapper .form-wrapper .fluentform .wpcf7-submit, #contact-cta--inner #contact-wrapper .form-wrapper form .ff-btn-submit, #contact-cta--inner #contact-wrapper .form-wrapper form .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta--inner #contact-wrapper .form-wrapper .fluentform .ff-btn-submit, #contact-cta--inner #contact-wrapper .form-wrapper .fluentform .wpcf7-submit, #contact-cta--inner #contact-wrapper .form-wrapper form .ff-btn-submit, #contact-cta--inner #contact-wrapper .form-wrapper form .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
#contact-cta--inner #contact-wrapper .form-wrapper .fluentform .ff-btn-submit:hover, #contact-cta--inner #contact-wrapper .form-wrapper .fluentform .wpcf7-submit:hover, #contact-cta--inner #contact-wrapper .form-wrapper form .ff-btn-submit:hover, #contact-cta--inner #contact-wrapper .form-wrapper form .wpcf7-submit:hover {
  background: #082140;
  color: #fff;
}
#contact-cta--inner #contact-wrapper .form-wrapper fieldset {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}
#contact-cta--inner #contact-wrapper .form-wrapper fieldset .row {
  flex: 1;
}
@media only screen and (max-width: 991.98px) {
  #contact-cta--inner #contact-wrapper .form-wrapper fieldset .row {
    width: 100%;
    flex: auto;
  }
}
#contact-cta--inner #contact-wrapper .form-wrapper fieldset .wpcf7-submit {
  padding: 0;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  height: 86px;
  text-align: center;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid transparent;
  margin-right: 0px;
  color: #1e1e1e;
  background: #FED647;
  margin-left: 30px;
}
@media only screen and (max-width: 991.98px) {
  #contact-cta--inner #contact-wrapper .form-wrapper fieldset .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 70px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta--inner #contact-wrapper .form-wrapper fieldset .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 70px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
#contact-cta--inner #contact-wrapper .form-wrapper fieldset .wpcf7-submit:hover {
  background: #082140;
  color: #fff;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper {
  font-size: 16px;
  margin-bottom: 10px;
  color: #fff;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper label {
  color: #fff;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper label span {
  color: #fff;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper a {
  color: #FED647;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper a:hover {
  color: #fff;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper .wpcf7-list-item {
  margin-left: 0px;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper input[type=checkbox] {
  margin-right: 5px;
  position: relative;
  top: 1px;
  position: absolute;
  width: auto;
  opacity: 0;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper .wpcf7-list-item-label:before {
  content: " ";
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #afafb0;
  margin-right: 15px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 7px;
  border-radius: 6px;
}
#contact-cta--inner #contact-wrapper .form-wrapper .checkbox-wrapper input:checked + span.wpcf7-list-item-label:before {
  background-color: #082140;
  border: 1px solid #0D539F;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
}
#contact-cta--inner #contact-wrapper .form-wrapper .wpcf7-checkbox {
  font-size: 16px;
}
#contact-cta--inner #contact-wrapper .form-wrapper .wpcf7-checkbox a {
  color: #FED647;
}
#contact-cta--inner #contact-wrapper .form-wrapper .wpcf7-checkbox a:hover {
  color: #fff;
}
@media only screen and (max-width: 767.98px) {
  #contact-cta--inner #contact-wrapper .form-wrapper .form-block {
    margin-bottom: 15px;
  }
}
#contact-cta--inner #contact-wrapper .form-wrapper .form-block input[type=text].wpcf7-not-valid, #contact-cta--inner #contact-wrapper .form-wrapper .form-block input[type=tel].wpcf7-not-valid, #contact-cta--inner #contact-wrapper .form-wrapper .form-block input[type=email].wpcf7-not-valid {
  border-color: #ffd4d4;
}
#contact-cta--inner #contact-wrapper .form-wrapper .form-block input[type=text] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors, #contact-cta--inner #contact-wrapper .form-wrapper .form-block input[type=tel] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors, #contact-cta--inner #contact-wrapper .form-wrapper .form-block input[type=email] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors {
  border: none;
  padding: 0px;
  margin: 0px;
}

#experience-tech header {
  text-align: center;
  padding-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #experience-tech header {
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #experience-tech header {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #experience-tech header {
    padding-bottom: 30px;
  }
}
#experience-tech header h2 {
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #experience-tech header h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #experience-tech header h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #experience-tech header h2 {
    font-size: 26px;
    line-height: 30px;
  }
}

.control-tech {
  padding-bottom: 100px !important;
}

#tech-features {
  padding-bottom: 100px;
}
#tech-features .feat-card {
  padding-left: 70px;
  padding-right: 30px;
  margin-bottom: 50px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-features .feat-card {
    padding-left: 55px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-features .feat-card {
    padding-left: 55px;
    padding-right: 0px;
  }
}
#tech-features .feat-card img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 42px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-features .feat-card img {
    width: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-features .feat-card img {
    width: 32px;
  }
}
#tech-features .feat-card h4 {
  margin-bottom: 20px;
  font-size: 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #tech-features .feat-card h4 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-features .feat-card h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-features .feat-card h4 {
    font-size: 19px;
    margin-bottom: 10px;
  }
}
#tech-features .feat-card p {
  font-size: 16px;
}
#tech-features .tech-cta {
  padding-top: 30px;
  text-align: center;
}
#tech-features .tech-cta a {
  display: inline-block;
  height: 62px;
  border-radius: 999px;
  background: #FED647;
  line-height: 62px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
  text-transform: uppercase;
  padding-left: 40px;
  padding-right: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #tech-features .tech-cta a {
    height: 52px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767.98px) {
  #tech-features .tech-cta a {
    height: 50px;
    line-height: 50px;
  }
}
#tech-features .tech-cta a:hover {
  background: #082140;
  color: #fff;
}

#faq-tech {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #eff7fe;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #faq-tech {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #faq-tech {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #faq-tech {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#faq-tech header {
  text-align: center;
  padding-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #faq-tech header {
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #faq-tech header {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #faq-tech header {
    padding-bottom: 35px;
  }
}
@media only screen and (max-width: 767.98px) {
  #faq-tech header h2 {
    font-size: 24px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 1.3;
  }
}
#faq-tech .faq__accordion {
  max-width: 875px;
  margin-left: auto;
  margin-right: auto;
}
#faq-tech .faq__accordion .faq-wrap {
  margin-bottom: 10px;
}
#faq-tech .faq__accordion h3 {
  font-size: 17px;
  color: #000;
  line-height: 1.333;
  position: relative;
  padding: 24px 60px 24px 30px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  border: 0;
  margin: 0;
  border-radius: 8px;
  border: 2px solid #bddbf5;
  position: relative;
  color: #082140;
}
@media only screen and (max-width: 767.98px) {
  #faq-tech .faq__accordion h3 {
    font-size: 16px;
    padding: 15px;
    padding-right: 40px;
  }
}
#faq-tech .faq__accordion h3:hover {
  cursor: pointer;
}
#faq-tech .faq__accordion h3:focus {
  outline: none !important;
}
#faq-tech .faq__accordion h3:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 30px;
  content: url("../img/ico/chevron-down-regular.svg");
  width: 20px;
}
@media only screen and (max-width: 767.98px) {
  #faq-tech .faq__accordion h3:after {
    width: 12px;
  }
}
#faq-tech .faq__accordion h3.active {
  border-color: #0D539F;
  background: #0D539F;
  color: #fff;
}
#faq-tech .faq__accordion h3.active:after {
  transform: translateY(-50%) rotate(180deg);
  color: #fff !important;
  filter: brightness(0) invert(1);
}
#faq-tech .faq__accordion .content {
  border: 0;
  padding: 35px 55px 35px 30px;
  padding-bottom: 15px;
  display: none;
  background: #fff;
  border-radius: 12px;
  margin-top: 10px;
  padding-bottom: 30px;
  box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.04);
}
@media only screen and (max-width: 767.98px) {
  #faq-tech .faq__accordion .content {
    padding: 20px;
  }
}
#faq-tech .faq__accordion .content p {
  margin-bottom: 30px;
}
#faq-tech .faq__accordion .content p strong {
  font-weight: normal;
  font-family: "Poppins Medium";
}
#faq-tech .faq__accordion .content p a {
  color: #0D539F;
  font-family: "Poppins Medium";
}
#faq-tech .faq__accordion .content p a:hover {
  color: #000;
  text-decoration: underline;
}

#technology-main #about {
  border-bottom: 1px solid #ddd;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 100px;
  padding-top: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main #about {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main #about {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
#technology-main #about header {
  padding-bottom: 30px;
}
#technology-main .tech-tabs {
  padding-bottom: 120px;
  padding-top: 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-tabs {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-tabs {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}
#technology-main .tech-tabs .side-nav .tab button {
  display: block;
  width: 100%;
  height: auto;
  padding: 0px;
  border: none;
  white-space: initial;
  text-align: left;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #fcfbfc;
  color: #393939;
  font-size: 18px;
  font-family: "Poppins Medium";
  line-height: 1.3;
  padding-top: 25px;
  padding-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-tabs .side-nav .tab button {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-tabs .side-nav .tab button {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-tabs .side-nav .tab button {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
  }
}
#technology-main .tech-tabs .side-nav .tab button.active {
  color: #0D539F;
}
#technology-main .tech-tabs .side-nav .tab button:hover {
  background: none;
  color: #227aeb;
}
#technology-main .tech-tabs .side-nav .tab button:focus {
  outline: 0;
  background: transparent;
}
#technology-main .tech-tabs .tab-list {
  padding-left: 40px;
  padding-top: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-tabs .tab-list {
    padding-left: 15px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-tabs .tab-list {
    padding-top: 0px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-tabs .tab-list {
    padding-left: 0px;
  }
}
#technology-main .tech-tabs .tab-list .tab-card h2 {
  margin-bottom: 30px;
  line-height: 1.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-tabs .tab-list .tab-card h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-tabs .tab-list .tab-card h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-tabs .tab-list .tab-card h2 {
    margin-bottom: 20px;
  }
}
#technology-main .tech-tabs .tab-list .tab-card h3 {
  margin-bottom: 20px;
  margin-top: 50px;
  font-family: "Poppins Medium";
  line-height: 1.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-tabs .tab-list .tab-card h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-tabs .tab-list .tab-card h3 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-tabs .tab-list .tab-card h3 {
    margin-bottom: 15px;
  }
}
#technology-main .tech-tabs .tab-list .tab-card h4 {
  margin-bottom: 20px;
  font-family: "Poppins Medium";
  line-height: 1.3;
}
#technology-main .tech-tabs .tab-list .tab-card p {
  margin-bottom: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-tabs .tab-list .tab-card p {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-tabs .tab-list .tab-card p {
    margin-bottom: 15px;
  }
}
#technology-main .tech-tabs .tab-list .tab-card p a {
  color: #0D539F;
  font-family: "Source Sans Pro Semi";
}
#technology-main .tech-tabs .tab-list .tab-card p a:hover {
  color: #000;
  text-decoration: underline;
}
#technology-main .tech-tabs .tab-list .tab-card table {
  width: 100%;
  border: none;
}
#technology-main .tech-tabs .tab-list .tab-card table tr {
  border: none;
}
#technology-main .tech-tabs .tab-list .tab-card table tr:nth-child(even) {
  background-color: #CEE7FD;
}
#technology-main .tech-tabs .tab-list .tab-card table td {
  border: none;
  padding: 10px;
}
#technology-main .tech-tabs .tab-list .tab-card table a {
  color: #0D539F;
  font-family: "Source Sans Pro Semi";
}
#technology-main .tech-tabs .tab-list .tab-card table a:hover {
  text-decoration: underline;
}
#technology-main .tech-tabs .tab-list .tab-card ul li {
  display: block;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
  line-height: 1.4;
}
#technology-main .tech-tabs .tab-list .tab-card ul li a {
  color: #0D539F;
  font-family: "Poppins Medium";
}
#technology-main .tech-tabs .tab-list .tab-card ul li a:hover {
  text-decoration: underline;
}
#technology-main .tech-tabs .tab-list .tab-card ul li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #227aeb;
  position: absolute;
  left: 0px;
  top: 8px;
}
#technology-main .tech-tabs .tab-list .tab-card ol li {
  margin-bottom: 10px;
  line-height: 1.4;
}
#technology-main .tech-why {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #eff7fe;
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#technology-main .tech-why header {
  text-align: center;
  width: 770px;
  margin: 0px auto;
  padding-bottom: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-why header {
    padding-bottom: 80px;
    width: 550px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why header {
    padding-bottom: 50px;
    width: 80%;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why header {
    width: 100%;
    padding-bottom: 40px;
  }
}
#technology-main .tech-why header h2 {
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-why header h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why header h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why header h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
#technology-main .tech-why header p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-why header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why header p {
    font-size: 16px;
    line-height: 24px;
  }
}
#technology-main .tech-why header ul {
  display: inline-block;
  margin-bottom: 15px;
}
#technology-main .tech-why header ul li {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
  font-size: 18px;
  line-height: 1.45;
  margin-right: 15px;
}
#technology-main .tech-why header ul li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #227aeb;
  position: absolute;
  left: 0px;
  top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why .features-list .col-md-4 {
    padding-right: 0px;
  }
}
#technology-main .tech-why .features-list .feat-card {
  padding-left: 60px;
  padding-right: 0px;
  margin-bottom: 50px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-why .features-list .feat-card {
    padding-left: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why .features-list .feat-card {
    padding-left: 35px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why .features-list .feat-card {
    padding-left: 55px;
    padding-right: 0px;
  }
}
#technology-main .tech-why .features-list .feat-card img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 42px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why .features-list .feat-card img {
    width: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why .features-list .feat-card img {
    width: 32px;
  }
}
#technology-main .tech-why .features-list .feat-card h4 {
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 1.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main .tech-why .features-list .feat-card h4 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why .features-list .feat-card h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why .features-list .feat-card h4 {
    font-size: 19px;
    margin-bottom: 10px;
  }
}
#technology-main .tech-why .features-list .feat-card p {
  font-size: 16px;
  line-height: 1.45;
}
#technology-main .tech-why .features-list .feat-card ul li {
  display: block;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
  font-size: 16px;
  line-height: 1.45;
}
#technology-main .tech-why .features-list .feat-card ul li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #227aeb;
  position: absolute;
  left: 0px;
  top: 8px;
}
#technology-main .tech-why .features-list .tech-cta {
  padding-top: 30px;
  text-align: center;
}
#technology-main .tech-why .features-list .tech-cta a {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why .features-list .tech-cta a {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why .features-list .tech-cta a {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#technology-main .tech-why .features-list .tech-cta a:hover {
  background: #082140;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main .tech-why .features-list .tech-cta a {
    width: 250px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main .tech-why .features-list .tech-cta a {
    width: 250px;
  }
}
#technology-main #tech-list--related {
  padding-bottom: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main #tech-list--related {
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main #tech-list--related {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main #tech-list--related {
    padding-bottom: 50px;
  }
}
#technology-main #tech-list--related header h4 {
  margin-bottom: 50px;
  font-size: 24px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main #tech-list--related header h4 {
    font-size: 22px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #technology-main #tech-list--related header h4 {
    font-size: 22px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #technology-main #tech-list--related header h4 {
    margin-bottom: 30px;
  }
}
#technology-main #tech-list--related a {
  display: block;
  width: 100%;
  height: 100%;
}
#technology-main #tech-list--related a:hover .tech-card {
  background: #0D539F;
  transform: scale(1.1);
}
#technology-main #tech-list--related a:hover .tech-card h5 {
  color: #fff;
}
#technology-main #tech-list--related a:hover .tech-card p {
  color: #fff;
}
#technology-main #tech-list--related a:hover .tech-card span {
  color: #FED647;
}
#technology-main #tech-list--related .tech-card {
  background: #fff;
  border-radius: 16px;
  padding: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.25s ease-in-out;
  box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #technology-main #tech-list--related .tech-card {
    margin-bottom: 15px;
  }
}
#technology-main #tech-list--related .tech-card .tech-ico {
  width: 44px;
  height: 44px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  transition: 0.25s ease-in-out;
  position: relative;
  margin-right: 20px;
}
#technology-main #tech-list--related .tech-card .tech-ico img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 28px;
}
#technology-main #tech-list--related .tech-card .tech-desc {
  flex: 1;
}
#technology-main #tech-list--related .tech-card h5 {
  margin-top: 0px;
  font-size: 18px;
  margin-bottom: 0px;
  transition: 0.25s ease-in-out;
  font-family: "Poppins Medium";
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #technology-main #tech-list--related .tech-card h5 {
    font-size: 16px;
  }
}
#technology-main #tech-list--related .tech-card p {
  font-size: 16px;
  line-height: 24px;
  transition: 0.25s ease-in-out;
  margin-bottom: 0px;
  line-height: 20px;
  padding-right: 0px;
}
#technology-main #tech-list--related .tech-card span {
  font-family: "Source Sans Pro Semi";
  color: #227aeb;
  transition: 0.25s ease-in-out;
}

.related-posts {
  padding-bottom: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .related-posts {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  .related-posts {
    padding-bottom: 50px;
  }
}
.related-posts h4 {
  margin-bottom: 50px;
  font-size: 24px;
}
@media only screen and (max-width: 767.98px) {
  .related-posts h4 {
    margin-bottom: 30px;
  }
}
.related-posts .article-card .article-photo {
  background: #0D539F;
  border-radius: 12px;
}
.related-posts .article-card .article-photo a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
.related-posts .article-card .article-photo a small {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 9px;
  background: #FED647;
  transition: 0.25s ease-in-out;
  opacity: 0;
  margin-top: 30px;
}
.related-posts .article-card .article-photo a small span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #082140;
  font-size: 32px;
}
.related-posts .article-card .article-photo a:hover small {
  opacity: 1;
  margin-top: 0px;
}
.related-posts .article-card .article-photo a:hover img {
  opacity: 0.1;
}
.related-posts .article-card .article-photo img {
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
  display: block;
  transition: 0.25s ease-in-out;
}
.related-posts .article-card .article-desc {
  padding-top: 16px;
}
.related-posts .article-card .article-desc span.date {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  color: #83888f;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .related-posts .article-card .article-desc span.date {
    margin-bottom: 15px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .related-posts .article-card .article-desc span.date {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.related-posts .article-card .article-desc span.date strong {
  font-weight: 500;
  font-family: "Source Sans Pro Semi";
}
.related-posts .article-card .article-desc p {
  max-height: 80px;
  overflow: hidden;
}
.related-posts .article-card .article-desc h3 {
  font-size: 20px;
  line-height: 1.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .related-posts .article-card .article-desc h3 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .related-posts .article-card .article-desc h3 {
    font-size: 18px;
  }
}
.related-posts .article-card .article-desc h3 a {
  color: #082140;
}
.related-posts .article-card .article-desc h3 a:hover {
  color: #227aeb;
}

.single-technologies #contact-cta--inner {
  margin-bottom: 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .single-technologies #contact-cta--inner {
    margin-bottom: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .single-technologies #contact-cta--inner {
    margin-bottom: 70px;
  }
}

#tech-breadcrumb {
  margin-bottom: 40px;
  margin-top: -30px;
}
#tech-breadcrumb .breadcrumb-wrapper {
  display: inline-flex;
  border: 1px solid #ddd;
  align-items: center;
  border-radius: 6px;
}
#tech-breadcrumb .home-link img {
  width: 52px;
}
#tech-breadcrumb ul {
  display: flex;
  align-items: center;
}
#tech-breadcrumb ul li {
  display: inline-block;
}

.breadcrumb-wrapper {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}
.breadcrumb-wrapper .tech-ico {
  margin-right: 10px !important;
  margin-bottom: 0px !important;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .breadcrumb-wrapper .tech-ico {
    margin-right: 5px !important;
  }
}
.breadcrumb-wrapper ul {
  display: flex;
  align-items: center;
}
.breadcrumb-wrapper ul li {
  display: inline-block;
}
.breadcrumb-wrapper ul li a {
  color: #646464;
  font-size: 14px;
  font-family: "Poppins Medium";
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .breadcrumb-wrapper ul li a {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.breadcrumb-wrapper ul li a:after {
  content: ">";
  font-size: 18px;
  position: relative;
  left: 10px;
  top: 2px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .breadcrumb-wrapper ul li a:after {
    left: 5px;
  }
}
.breadcrumb-wrapper ul li:last-child a {
  color: #0D539F;
  font-family: "Poppins Medium";
  padding-right: 0px;
}
.breadcrumb-wrapper ul li:last-child a:after {
  display: none;
}

#elementor-popup-modal-7091 {
  display: none !important;
}

.about-hire {
  padding-bottom: 80px !important;
}

.contact-hire {
  margin-top: 0px !important;
}

.page-id-20505 #inner-header {
  margin-top: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .page-id-20505 #inner-header {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  .page-id-20505 #inner-header {
    margin-top: 30px;
  }
}

#bottom-cta .thanks-cta-content {
  width: 90% !important;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .thanks-cta-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
#bottom-cta .thanks-cta-content p {
  margin: 0px auto;
  max-width: 600px;
  margin-bottom: 0px !important;
}
#bottom-cta .thanks-cta-content h3 {
  margin-bottom: 35px;
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .thanks-cta-content h3 {
    margin-bottom: 15px;
  }
}
#bottom-cta .thanks-cta-content footer {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .thanks-cta-content footer {
    display: block;
  }
}
#bottom-cta .thanks-cta-content footer a.btn-cta {
  width: auto !important;
  padding-left: 35px;
  padding-right: 35px;
  max-width: 100%;
  margin-right: 30px;
  min-width: 200px;
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .thanks-cta-content footer a.btn-cta {
    width: 100%;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    display: block;
  }
}
#bottom-cta .thanks-cta-content footer a.btn-home {
  color: #fff;
  font-size: 17px;
  display: inline-block;
  font-family: "Source Sans Pro Semi";
  position: relative;
  margin-left: 20px;
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .thanks-cta-content footer a.btn-home {
    margin-left: 0px;
  }
}
#bottom-cta .thanks-cta-content footer a.btn-home:after {
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 2px;
  background: #fff;
  content: "";
  opacity: 1;
  transition: 0.25s ease-in-out;
}
#bottom-cta .thanks-cta-content footer a.btn-home:hover:after {
  width: 0%;
  opacity: 0;
}

#regular-page {
  padding-top: 80px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 991.98px) {
  #regular-page {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
#regular-page .regular-content p {
  font-size: 19px;
  color: rgb(6, 6, 6);
  line-height: 1.684;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991.98px) {
  #regular-page .regular-content p {
    font-size: 18px;
  }
}
#regular-page .regular-content p a {
  color: #0D539F;
  font-family: "Source Sans Pro Semi";
}
#regular-page .regular-content p a:hover {
  text-decoration: underline;
  color: #082140;
}
#regular-page .regular-content h2 {
  margin-bottom: 30px;
  line-height: 1.3;
  font-size: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #regular-page .regular-content h2 {
    margin-bottom: 20px;
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #regular-page .regular-content h2 {
    margin-bottom: 20px;
    font-size: 26px;
  }
}
@media only screen and (max-width: 767.98px) {
  #regular-page .regular-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
}
#regular-page .regular-content h3 {
  margin-bottom: 20px;
  margin-top: 50px;
  line-height: 1.45;
  font-size: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #regular-page .regular-content h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #regular-page .regular-content h3 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #regular-page .regular-content h3 {
    margin-bottom: 15px;
  }
}
#regular-page .regular-content h4 {
  margin-bottom: 20px;
  font-family: "Poppins Medium";
  line-height: 1.3;
  font-size: 22px;
}
#regular-page .regular-content ul {
  margin-bottom: 30px;
}
#regular-page .regular-content ul li {
  display: block;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
  font-size: 18px;
}
#regular-page .regular-content ul li a {
  color: #0D539F;
  font-family: "Poppins Medium";
}
#regular-page .regular-content ul li a:hover {
  text-decoration: underline;
}
#regular-page .regular-content ul li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #227aeb;
  position: absolute;
  left: 0px;
  top: 10px;
}

#contact-info {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 90px;
}
@media only screen and (max-width: 767.98px) {
  #contact-info {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
#contact-info header {
  padding-bottom: 60px;
  text-align: center;
}
@media only screen and (max-width: 767.98px) {
  #contact-info header {
    padding-bottom: 30px;
  }
}
#contact-info header h2 {
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #contact-info header h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-info header h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-info header h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
#contact-info .tab {
  margin: 0px auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #227aeb;
  width: auto;
  padding: 5px;
  width: 400px;
  border-radius: 999px;
}
@media only screen and (max-width: 767.98px) {
  #contact-info .tab {
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 4px;
  }
}
#contact-info .tab button {
  width: 200px;
  border-radius: 999px;
  border: none;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #227aeb;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-info .tab button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-info .tab button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
  }
}
#contact-info .tab button.active {
  background: #227aeb !important;
  color: #fff !important;
}
#contact-info .tab button:hover {
  color: #227aeb;
  background: none;
}
#contact-info .info-card {
  padding: 50px;
  box-shadow: 0 40px 76px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  height: 340px;
}
@media only screen and (max-width: 767.98px) {
  #contact-info .info-card {
    margin-bottom: 40px;
    height: auto;
    box-shadow: 0 20px 36px rgba(0, 0, 0, 0.08);
  }
}
#contact-info .info-card .icon {
  margin-bottom: 20px;
}
#contact-info .info-card .icon img {
  width: 74px;
}
#contact-info .info-card .card-text span.label {
  display: block;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 1.3;
  color: #1f6fd6;
  margin-bottom: 20px;
  font-family: "Poppins Bold";
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #contact-info .info-card .card-text span.label {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-info .info-card .card-text span.label {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-info .info-card .card-text span.label {
    font-size: 19px;
    margin-bottom: 10px;
  }
}
#contact-info .info-card .card-text p {
  font-size: 20px;
  color: #000;
  line-height: 1.4;
}
#contact-info .info-card .card-text p a {
  color: #000;
}
#contact-info .info-card .card-text p a:hover {
  color: #227aeb;
}

.map-holder {
  margin-bottom: 80px;
  margin-top: 50px;
}
.map-holder iframe {
  width: 100%;
  height: 450px;
  display: block;
  border-radius: 24px;
}

.socials-author {
  margin-bottom: 30px;
  text-align: center;
  margin-top: 30px;
}
.socials-author ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.socials-author ul li {
  margin-left: 15px;
  margin-right: 15px;
}
@media only screen and (max-width: 767.98px) {
  .socials-author ul li {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.socials-author ul li a img {
  width: 36px;
}
.socials-author ul li a img:hover {
  opacity: 0.6;
}

.schema-faq-section {
  margin-bottom: 10px;
}

.schema-faq {
  background: #f1f1f1;
}
.schema-faq .schema-faq-question {
  display: block;
  font-size: 17px;
  background: white;
  color: #000;
  line-height: 1.333;
  position: relative;
  padding: 24px 60px 24px 30px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  border: 0;
  margin: 0;
  border-radius: 8px;
  border: 2px solid #bddbf5;
  position: relative;
  color: #082140;
}
@media only screen and (max-width: 767.98px) {
  .schema-faq .schema-faq-question {
    font-size: 16px;
    padding: 15px;
    padding-right: 40px;
  }
}
.schema-faq .schema-faq-question:hover {
  cursor: pointer;
}
.schema-faq .schema-faq-question:focus {
  outline: none !important;
}
.schema-faq .schema-faq-question:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 30px;
  content: url("../img/ico/chevron-down-regular.svg");
  width: 20px;
}
@media only screen and (max-width: 767.98px) {
  .schema-faq .schema-faq-question:after {
    width: 12px;
  }
}
.schema-faq .schema-faq-question.active {
  border-color: #0D539F;
  background: #0D539F;
  color: #fff;
}
.schema-faq .schema-faq-question.active:after {
  transform: translateY(-50%) rotate(180deg);
  color: #fff !important;
  filter: brightness(0) invert(1);
}
.schema-faq .schema-faq-answer {
  border: 0;
  padding: 35px 55px 35px 30px;
  padding-bottom: 15px;
  display: none;
  background: #fff;
  border-radius: 12px;
  margin-top: 10px;
  padding-bottom: 30px;
  box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.04);
}
@media only screen and (max-width: 767.98px) {
  .schema-faq .schema-faq-answer {
    padding: 20px;
  }
}

.wp-block-table {
  padding: 0px !important;
  width: 100%;
  margin: 0px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767.98px) {
  .wp-block-table {
    overflow: scroll;
  }
}
.wp-block-table table {
  width: 100% !important;
}
.wp-block-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.wp-block-table td strong {
  color: #0D539F;
}
.wp-block-table th {
  background-color: #0D539F;
  color: #fff;
  padding: 10px;
}
.wp-block-table .wp-element-caption {
  font-size: 15px;
  opacity: 0.6;
  display: block;
  padding-top: 10px;
  background: #f1f1f1;
  padding: 10px;
}

#page-footer {
  background: #f8f9fb;
  padding-top: 60px;
}
#page-footer ul {
  list-style-type: none !important;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 767.98px) {
  #page-footer .container {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
}
#page-footer .footer-about {
  padding-right: 30px;
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-about {
    padding-right: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #page-footer .footer-about {
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-about {
    padding-right: 0px;
  }
}
#page-footer .footer-about img {
  width: 220px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-about img {
    width: 200px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-about img {
    margin-bottom: 20px;
  }
}
#page-footer .footer-about p {
  font-size: 18px;
  margin-top: 15px;
}
#page-footer .footer-about p a {
  color: #227aeb;
  font-family: "Poppins Medium";
}
#page-footer .footer-about .footer-socials span.label {
  display: block;
  font-family: "Source Sans Pro Semi";
  font-size: 20px;
  margin-bottom: 25px;
  color: #082140;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-about .footer-socials span.label {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-about .footer-socials span.label {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
#page-footer .footer-about .footer-socials ul {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-about .footer-socials ul {
    justify-content: center;
  }
}
#page-footer .footer-about .footer-socials ul li {
  margin-right: 20px;
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-about .footer-socials ul li {
    margin-left: 10px;
    margin-right: 10px;
  }
}
#page-footer .footer-about .footer-socials ul li a {
  font-size: 24px;
  color: #227aeb;
}
#page-footer .footer-about .footer-socials ul li a:hover {
  color: #082140;
}
#page-footer .footer-about .footer-nl {
  border-top: 1px solid #ddd;
  margin-top: 30px;
  padding-top: 30px;
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-about .footer-nl {
    margin-bottom: 40px;
  }
}
#page-footer .footer-about .footer-nl .title {
  display: block;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  margin-bottom: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-about .footer-nl .title {
    font-size: 17px;
  }
}
#page-footer .footer-about .footer-nl p {
  font-size: 16px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-about .footer-nl p {
    font-size: 15px;
  }
}
#page-footer .footer-about .footer-nl small {
  opacity: 0.8;
  display: block;
  padding-top: 10px;
}
#page-footer .footer-about .footer-nl small a {
  color: #227aeb;
}
#page-footer .footer-about .footer-nl small a:hover {
  color: #0D539F;
}
#page-footer .footer-about .footer-nl .form-wrapper {
  position: relative;
}
#page-footer .footer-about .footer-nl .form-wrapper input[type=email] {
  width: 100%;
  height: 58px;
  line-height: 58px;
  padding-left: 25px;
  border-radius: 12px;
  border: none;
  background: #CEE7FD;
  background: #fff;
  font-size: 16px;
  border: 1px solid #ddd;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-about .footer-nl .form-wrapper input[type=email] {
    height: 50px;
    line-height: 50px;
    padding-left: 15px;
  }
}
#page-footer .footer-about .footer-nl .form-wrapper input[type=submit], #page-footer .footer-about .footer-nl .form-wrapper button {
  position: absolute;
  right: 4px;
  font-size: 0px;
  display: block;
  width: 70px;
  height: 50px;
  top: 4px;
  background: #FED647;
  border: none;
  border-radius: 12px;
  color: #1e1e1e;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-about .footer-nl .form-wrapper input[type=submit], #page-footer .footer-about .footer-nl .form-wrapper button {
    height: 42px;
    width: 50px;
  }
}
#page-footer .footer-about .footer-nl .form-wrapper input[type=submit]:hover, #page-footer .footer-about .footer-nl .form-wrapper button:hover {
  background: #082140;
  color: #fff;
}
#page-footer .footer-about .footer-nl .form-wrapper input[type=submit] span, #page-footer .footer-about .footer-nl .form-wrapper button span {
  font-size: 32px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-about .footer-nl .form-wrapper input[type=submit] span, #page-footer .footer-about .footer-nl .form-wrapper button span {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-nav {
    margin-bottom: 40px;
  }
}
#page-footer .footer-nav span.title {
  display: block;
  font-family: "Source Sans Pro Semi";
  font-size: 20px;
  margin-bottom: 25px;
  color: #082140;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-nav span.title {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-nav span.title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
#page-footer .footer-nav ul li {
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer .footer-nav ul li {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer .footer-nav ul li {
    font-size: 15px;
    margin-bottom: 10px;
  }
}
#page-footer .footer-nav ul li a {
  color: #181818;
  color: #000;
  opacity: 0.6;
}
#page-footer .footer-nav ul li a:hover {
  opacity: 1;
  color: #227aeb;
}
#page-footer #copy-bar {
  padding-top: 30px;
  border-top: 1px solid #ddd;
  margin-top: 50px;
  padding-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer #copy-bar {
    margin-top: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer #copy-bar {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
  }
}
#page-footer #copy-bar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #page-footer #copy-bar .container {
    display: block;
    text-align: center;
  }
}
#page-footer #copy-bar .copy-left small {
  font-size: 16px;
  color: #181818;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #page-footer #copy-bar .copy-left small {
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer #copy-bar .copy-left small {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer #copy-bar .copy-left small {
    font-size: 15px;
  }
}
#page-footer #copy-bar .copy-left small strong {
  color: #082140;
  font-family: "Source Sans Pro Semi";
}
#page-footer #copy-bar .copy-right ul {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #page-footer #copy-bar .copy-right ul {
    display: block;
    margin-top: 20px;
  }
}
#page-footer #copy-bar .copy-right ul li {
  margin-left: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer #copy-bar .copy-right ul li {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer #copy-bar .copy-right ul li {
    margin-left: 0px;
    margin-bottom: 10px;
  }
}
#page-footer #copy-bar .copy-right ul li a {
  color: #181818;
  font-size: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #page-footer #copy-bar .copy-right ul li a {
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #page-footer #copy-bar .copy-right ul li a {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767.98px) {
  #page-footer #copy-bar .copy-right ul li a {
    font-size: 15px;
  }
}
#page-footer #copy-bar .copy-right ul li a:hover {
  color: #227aeb;
}
#page-footer #copy-bar .copy-right ul li:last-child {
  font-family: "Source Sans Pro Semi";
}
#page-footer #copy-bar .copy-right ul li:last-child a {
  color: #0D539F;
}

.page-template-lp-template #page-footer {
  padding-top: 0px !important;
}
.page-template-lp-template #page-footer #copy-bar {
  margin-top: 0px !important;
}

.footer-branding {
  text-align: center;
}
.footer-branding img {
  width: 260px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .footer-branding img {
    width: 200px;
  }
}
.footer-branding span.tagline {
  display: block;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 45px;
  font-family: "Poppins Medium";
  color: #227aeb;
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

#cookie-notice {
  background-color: rgba(13, 83, 159, 0.9);
  z-index: 9997;
  position: fixed;
  min-width: 100%;
  height: auto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  left: 0;
  text-align: center;
  bottom: 0;
  display: none;
}
#cookie-notice.slide-up {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
}
#cookie-notice.slide-down {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 0;
}
#cookie-notice-in {
  padding: 15px 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #cookie-notice-in {
    padding: 15px 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #cookie-notice-in {
    padding: 15px 40px;
  }
}
#cookie-notice .notice-text, #cookie-notice .notice-btns {
  display: inline-block;
}
#cookie-notice p {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 767.98px) {
  #cookie-notice p {
    line-height: 1.3;
    margin-bottom: 10px;
  }
}
#cookie-notice p a {
  color: #CEE7FD;
  text-decoration: underline;
}
#cookie-notice p a:hover {
  color: #fff;
  text-decoration: none;
}
#cookie-notice .notice-btns a {
  background-color: #FED647;
  display: inline-block;
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 999px;
  margin-left: 15px;
}
@media only screen and (max-width: 767.98px) {
  #cookie-notice .notice-btns a {
    margin-left: 0px;
  }
}
#cookie-notice .notice-btns a:hover {
  background-color: #fff;
  color: #000;
}

.menu-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.active-overlay {
  opacity: 1;
  visibility: visible;
}

.main-menu-sidebar {
  background: #0D539F;
  width: 310px;
  position: fixed;
  height: 100%;
  top: 0px;
  right: -310px;
  z-index: 99999;
  transition: 0.45s ease-in-out;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 30px;
  overflow-y: scroll;
  /* close menu */
  /* main menu */
}
.main-menu-sidebar header {
  position: relative;
}
.main-menu-sidebar .close-menu-btn {
  position: absolute;
  top: 30px;
  right: 0px;
  color: #fff;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.main-menu-sidebar .close-menu-btn i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.main-menu-sidebar small {
  display: block;
  width: 50px;
  height: 54px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.main-menu-sidebar small:after {
  color: #fff;
  font-size: 22px !important;
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.main-menu-sidebar small.active:after {
  color: #fff;
  transition: 0.25s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links {
  padding-top: 90px;
  text-align: center;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links .menu-item-has-children {
  position: relative;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  color: #fff;
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
  display: block;
  font-family: "Source Sans Pro Semi";
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links > li > a:hover {
  color: #fff;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links > li:last-child {
  margin-top: 10px;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links > li:last-child a {
  display: inline-block;
  background: #CEE7FD;
  padding-left: 46px;
  padding-right: 46px;
  border-radius: 999px;
  font-family: "Source Sans Pro Semi";
  color: #181818;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links > li:last-child a:hover {
  background: #0D539F;
  color: #fff;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links li ul {
  padding-top: 10px;
  padding-bottom: 10px;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links li ul li a {
  display: block;
  font-size: 1em;
  color: #5d5d5d;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  position: relative;
  color: #fff;
  line-height: 1.3;
  letter-spacing: 1px;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links li ul li a:hover {
  color: #fff;
  padding-left: 20px;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links li ul li a:hover:before {
  width: 10px;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links li ul li a:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #fff;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
  border-radius: 100%;
  transition: 0.25s ease-in-out;
}
.main-menu-sidebar #sidebar-menu-wrapper #menu .nav-links .has-menu > a {
  position: relative;
}

.body-scroll {
  overflow-y: hidden !important;
}

.menu-active {
  right: 0;
}

#hero-banner .container {
  display: flex;
  justify-content: space-between;
  margin-top: 177px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #hero-banner .container {
    margin-top: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #hero-banner .container {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #hero-banner .container {
    margin-top: 90px;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #hero-banner .container .hero-image {
    text-align: center;
    width: 100%;
    margin-top: 25px;
  }
}
@media only screen and (max-width: 767.98px) {
  #hero-banner .container .hero-image img {
    margin: 0px auto;
    display: block;
    width: 90%;
  }
}
#hero-banner .container .hero-caption {
  padding-top: 54px;
  width: 465px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #hero-banner .container .hero-caption {
    padding-top: 45px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #hero-banner .container .hero-caption {
    width: 100%;
    padding-top: 40px;
    text-align: center;
  }
}
#hero-banner .container .hero-caption h1 {
  font-size: 42px;
  line-height: 60px;
  font-family: "Poppins Bold";
  font-weight: 400;
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #hero-banner .container .hero-caption h1 {
    font-size: 38px;
    line-height: 56px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #hero-banner .container .hero-caption h1 {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  #hero-banner .container .hero-caption h1 {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}
#hero-banner .container .hero-caption h1 strong {
  display: block;
  font-size: 72px;
  line-height: 74px;
  font-family: "Poppins Bold";
  font-weight: bold;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #hero-banner .container .hero-caption h1 strong {
    font-size: 64px;
    line-height: 68px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #hero-banner .container .hero-caption h1 strong {
    font-size: 48px;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 767.98px) {
  #hero-banner .container .hero-caption h1 strong {
    font-size: 44px;
    line-height: 1.2;
  }
}
#hero-banner .container .hero-caption p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 32px;
  max-width: 377px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #hero-banner .container .hero-caption p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 767.98px) {
  #hero-banner .container .hero-caption p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 25px;
  }
}
#hero-banner .container .hero-caption a.btn-cta {
  display: inline-block;
  height: 62px;
  border-radius: 999px;
  background: #FED647;
  line-height: 62px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
  text-transform: uppercase;
  padding-left: 40px;
  padding-right: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #hero-banner .container .hero-caption a.btn-cta {
    height: 52px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767.98px) {
  #hero-banner .container .hero-caption a.btn-cta {
    height: 50px;
    line-height: 50px;
  }
}
#hero-banner .container .hero-caption a.btn-cta:hover {
  background: #082140;
  color: #fff;
}

#about {
  margin-top: 110px;
  padding-bottom: 165px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about {
    margin-top: 100px;
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about {
    margin-top: 70px;
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about {
    margin-top: 50px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
#about header {
  width: 550px;
  margin: 0px auto;
  padding-bottom: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about header {
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about header {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about header {
    width: 100%;
    padding-bottom: 40px;
  }
}
#about header h2 {
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about header h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about header h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about header h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
#about header p {
  font-size: 18px;
  line-height: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about header p {
    font-size: 16px;
    line-height: 24px;
  }
}
#about header p a {
  color: #0D539F;
  font-family: "Source Sans Pro Semi";
}
#about header p a:hover {
  color: #082140;
}
#about header.header-tech {
  width: 690px;
}
@media only screen and (max-width: 767.98px) {
  #about header.header-tech {
    width: 90%;
  }
}
@media only screen and (max-width: 767.98px) {
  #about .about-card {
    margin-bottom: 40px;
  }
}
#about .about-card .card-icon {
  margin-bottom: 14px;
}
#about .about-card .card-icon img {
  max-height: 64px;
  max-width: 64px;
}
#about .about-card .card-photo {
  margin-bottom: 45px;
}
#about .about-card .card-photo img {
  width: 70%;
}
#about .about-card .card-text h3 {
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about .about-card .card-text h3 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about .about-card .card-text h3 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about .about-card .card-text h3 {
    font-size: 18px;
  }
}
#about .about-card .card-text h3 span {
  color: #227aeb;
  padding-right: 5px;
}
#about .about-card .card-text p {
  max-width: 275px;
  margin: 0px auto;
  margin-bottom: 15px !important;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about .about-card .card-text p {
    font-size: 15px;
    line-height: 1.5;
  }
}
@media only screen and (max-width: 767.98px) {
  #about .about-card .card-text p {
    font-size: 15px;
    line-height: 1.5;
  }
}
#about .steps {
  text-align: left;
  padding: 0px;
  max-width: 100%;
}
@media only screen and (max-width: 767.98px) {
  #about .steps {
    margin: 0px;
  }
}
#about .steps .about-card {
  margin-bottom: 50px;
}
#about .steps h3 {
  margin-bottom: 15px;
}
#about .steps h3 span {
  color: #227aeb;
  padding-right: 5px;
}
#about .steps p {
  margin: 0px !important;
  max-width: 95% !important;
}
#about .cta-card {
  background: #227aeb;
  text-align: center;
  height: 320px;
  margin-top: 60px;
  border-radius: 12px;
  position: relative;
}
@media only screen and (max-width: 767.98px) {
  #about .cta-card {
    margin-top: 20px;
  }
}
#about .cta-card .caption {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}
#about .cta-card .caption h4 {
  color: #fff;
  margin-bottom: 20px;
  font-family: "Poppins Medium";
  line-height: 1.2;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about .cta-card .caption h4 {
    font-size: 20px;
  }
}
#about .cta-card .caption a.btn-cta {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about .cta-card .caption a.btn-cta {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about .cta-card .caption a.btn-cta {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#about .cta-card .caption a.btn-cta:hover {
  background: #082140;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about .cta-card .caption a.btn-cta {
    font-size: 15px;
  }
}

#control {
  padding-bottom: 183px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #control {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #control {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #control {
    padding-bottom: 100px;
  }
}
#control.intro-tech {
  padding-bottom: 120px;
}
#control .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #control .container {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }
}
#control .container .image-holder {
  width: 500px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #control .container .image-holder {
    width: 450px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #control .container .image-holder {
    width: 50%;
  }
}
@media only screen and (max-width: 767.98px) {
  #control .container .image-holder {
    width: 100%;
  }
}
#control .container .image-holder img {
  width: 100%;
}
#control .container .control-text {
  flex: 1;
  padding-left: 52px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #control .container .control-text {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #control .container .control-text {
    text-align: center;
    padding-left: 0px;
    padding-top: 30px;
  }
}
#control .container .control-text h4 a {
  color: #227aeb;
}
#control .container .control-text h4 a:hover {
  color: #0D539F;
}
#control .container .control-text h3 {
  font-size: 33px;
  line-height: 44px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #control .container .control-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #control .container .control-text h3 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #control .container .control-text h3 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 5px;
  }
}
#control .container .control-text ol li {
  display: block;
  margin-bottom: 10px;
  color: #181818;
  margin-top: 0;
  font-weight: normal;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 26px;
  position: relative;
}
#control .container .control-text ol {
  list-style: none;
  counter-reset: li;
}
#control .container .control-text li::before {
  content: counter(li);
  color: white;
  background: #227aeb;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  margin-left: -25px;
  margin-right: 20px;
  position: absolute;
  left: -15px;
  top: 1px;
}
#control .container .control-text li {
  counter-increment: li;
}

#features header {
  width: 566px;
  margin: 0px auto;
  padding-bottom: 100px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #features header {
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #features header {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #features header {
    width: 100%;
    padding-bottom: 40px;
  }
}
#features header h2 {
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #features header h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #features header h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #features header h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
#features header p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #features header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #features header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #features header p {
    font-size: 16px;
    line-height: 24px;
  }
}
#features header ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#features header ul li {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
}
#features header ul li:after {
  width: 6px;
  height: 6px;
  content: "";
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  position: relative;
  top: -2px;
  background: #0D539F;
}
#features header ul li:last-child:after {
  display: none;
}
@media only screen and (max-width: 767.98px) {
  #features .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
#features .main-content .content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #features .main-content .content-wrapper {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #features .main-content .content-wrapper {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #features .main-content .content-wrapper {
    display: block;
  }
}
#features .main-content .content-wrapper .content-text {
  width: 550px;
  text-align: right;
  padding-right: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #features .main-content .content-wrapper .content-text {
    width: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #features .main-content .content-wrapper .content-text {
    width: 50%;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #features .main-content .content-wrapper .content-text {
    width: 100%;
    padding-right: 0px;
    text-align: center;
  }
}
#features .main-content .content-wrapper .content-text h3 {
  font-size: 28px;
  line-height: 1;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #features .main-content .content-wrapper .content-text h3 {
    font-size: 26px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #features .main-content .content-wrapper .content-text h3 {
    font-size: 24px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 767.98px) {
  #features .main-content .content-wrapper .content-text h3 {
    font-size: 24px;
    margin-bottom: 5px;
  }
}
#features .main-content .content-wrapper .content-text h3 strong {
  color: #227AEB;
}
#features .main-content .content-wrapper .content-text p {
  font-size: 18px;
  line-height: 26px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #features .main-content .content-wrapper .content-text p {
    font-size: 16px;
    line-height: 22px;
  }
}
#features .main-content .content-wrapper .content-image {
  width: 450px;
}
@media only screen and (max-width: 767.98px) {
  #features .main-content .content-wrapper .content-image {
    width: 100%;
    margin-top: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #features .main-content .content-wrapper .content-image {
    width: 420px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #features .main-content .content-wrapper .content-image {
    width: 50%;
  }
}
#features .main-content .content-wrapper .content-image img {
  width: 100%;
}
#features .main-content .content-wrapper:nth-child(even) .content-image {
  order: -1;
}
#features .main-content .content-wrapper:nth-child(even) .content-text {
  padding-right: 0px;
  padding-left: 70px;
  text-align: left;
}
@media only screen and (max-width: 767.98px) {
  #features .main-content .content-wrapper:nth-child(even) .content-text {
    padding-left: 0px;
    text-align: center;
  }
}

#bottom-cta {
  background: #0D539F;
  text-align: center;
  padding-top: 63px;
  padding-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #bottom-cta {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #bottom-cta {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
#bottom-cta .cta-content {
  width: 530px;
  margin: 0px auto;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #bottom-cta .cta-content {
    width: 400px;
  }
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .cta-content {
    width: 100%;
  }
}
#bottom-cta .cta-content h3 {
  color: #fff;
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #bottom-cta .cta-content h3 {
    font-size: 30px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #bottom-cta .cta-content h3 {
    font-size: 26px;
    line-height: 1.3;
  }
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .cta-content h3 {
    font-size: 26px;
    line-height: 1.3;
  }
}
#bottom-cta .cta-content p {
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #bottom-cta .cta-content p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #bottom-cta .cta-content p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .cta-content p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}
#bottom-cta .cta-content a.btn-cta {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #bottom-cta .cta-content a.btn-cta {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #bottom-cta .cta-content a.btn-cta {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#bottom-cta .cta-content a.btn-cta:hover {
  background: #082140;
  color: #fff;
}

#contact-cta {
  margin-top: 150px;
  margin-bottom: 150px;
}
#contact-cta #contact-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 40px 76px rgba(0, 0, 0, 0.08);
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta #contact-wrapper {
    padding: 30px;
    border-radius: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta #contact-wrapper {
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 32px;
  }
}
#contact-cta #contact-wrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  border-radius: 50px;
  background: #fff;
  opacity: 0.5;
  box-shadow: 0 30px 76px rgba(0, 0, 0, 0.08);
  top: calc(50% + 30px);
  z-index: -1;
}
#contact-cta #contact-wrapper:before {
  position: absolute;
  display: block;
  content: "";
  left: -175px;
  top: -50px;
  width: 350px;
  height: 350px;
  background: #eff7fe;
  border-radius: 50%;
  z-index: -1;
}
#contact-cta #contact-wrapper h3 {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 1.25;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta #contact-wrapper h3 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta #contact-wrapper h3 {
    font-size: 30px;
  }
}
#contact-cta #contact-wrapper h3 strong {
  color: #227aeb;
}
#contact-cta #contact-wrapper p {
  margin-bottom: 20px;
  margin-top: -10px;
  font-size: 1.25em;
  font-size: 18px;
}
#contact-cta #contact-wrapper .form-wrapper .wpcf7-not-valid-tip {
  font-size: 14px;
  padding-left: 15px;
  padding-top: 7px;
}
#contact-cta #contact-wrapper .form-wrapper .wpcf7-response-output {
  border: none;
  padding: 0px;
  width: 100%;
  margin-left: 15px;
  color: red;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform, #contact-cta #contact-wrapper .form-wrapper form {
  padding-top: 20px;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .row-wrapper, #contact-cta #contact-wrapper .form-wrapper form .row-wrapper {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767.98px) {
  #contact-cta #contact-wrapper .form-wrapper .fluentform .row-wrapper, #contact-cta #contact-wrapper .form-wrapper form .row-wrapper {
    margin-bottom: 15px;
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .row-wrapper .form-block, #contact-cta #contact-wrapper .form-wrapper form .row-wrapper .form-block {
  width: 50%;
  margin-right: 15px;
  margin-left: 15px;
}
@media only screen and (max-width: 767.98px) {
  #contact-cta #contact-wrapper .form-wrapper .fluentform .row-wrapper .form-block, #contact-cta #contact-wrapper .form-wrapper form .row-wrapper .form-block {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .row-wrapper .form-block-full, #contact-cta #contact-wrapper .form-wrapper form .row-wrapper .form-block-full {
  width: 100%;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform label, #contact-cta #contact-wrapper .form-wrapper form label {
  margin-bottom: 11px;
  font-family: "Source Sans Pro Semi";
  display: block;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform label span, #contact-cta #contact-wrapper .form-wrapper form label span {
  color: red;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform input[type=text], #contact-cta #contact-wrapper .form-wrapper .fluentform input[type=tel], #contact-cta #contact-wrapper .form-wrapper .fluentform input[type=email], #contact-cta #contact-wrapper .form-wrapper .fluentform select, #contact-cta #contact-wrapper .form-wrapper form input[type=text], #contact-cta #contact-wrapper .form-wrapper form input[type=tel], #contact-cta #contact-wrapper .form-wrapper form input[type=email], #contact-cta #contact-wrapper .form-wrapper form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 62px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 0 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
#contact-cta #contact-wrapper .form-wrapper .fluentform select, #contact-cta #contact-wrapper .form-wrapper form select {
  background: url("../img/ico/down.svg") no-repeat;
  background-position: 97% center;
  background-size: 24px;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform textarea, #contact-cta #contact-wrapper .form-wrapper form textarea {
  width: 100%;
  height: 136px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .ff-el-form-check, #contact-cta #contact-wrapper .form-wrapper form .ff-el-form-check {
  padding-top: 8px;
  font-size: 16px;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .ff-el-form-check a, #contact-cta #contact-wrapper .form-wrapper form .ff-el-form-check a {
  color: #227aeb;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .ff-el-form-check a:hover, #contact-cta #contact-wrapper .form-wrapper form .ff-el-form-check a:hover {
  color: #0D539F;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .ff-el-form-check input[type=checkbox], #contact-cta #contact-wrapper .form-wrapper form .ff-el-form-check input[type=checkbox] {
  margin-right: 5px;
  position: relative;
  top: -1px;
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .ff-btn-submit, #contact-cta #contact-wrapper .form-wrapper .fluentform .wpcf7-submit, #contact-cta #contact-wrapper .form-wrapper form .ff-btn-submit, #contact-cta #contact-wrapper .form-wrapper form .wpcf7-submit {
  padding: 0;
  border: 0;
  width: auto;
  padding-left: 60px;
  padding-right: 60px;
  height: 86px;
  line-height: 84px;
  text-align: center;
  border-radius: 999px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid transparent;
  margin-right: 0px;
  color: #1e1e1e;
  background: #FED647;
  margin-top: 20px;
}
@media only screen and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper .fluentform .ff-btn-submit, #contact-cta #contact-wrapper .form-wrapper .fluentform .wpcf7-submit, #contact-cta #contact-wrapper .form-wrapper form .ff-btn-submit, #contact-cta #contact-wrapper .form-wrapper form .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper .fluentform .ff-btn-submit, #contact-cta #contact-wrapper .form-wrapper .fluentform .wpcf7-submit, #contact-cta #contact-wrapper .form-wrapper form .ff-btn-submit, #contact-cta #contact-wrapper .form-wrapper form .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
#contact-cta #contact-wrapper .form-wrapper .fluentform .ff-btn-submit:hover, #contact-cta #contact-wrapper .form-wrapper .fluentform .wpcf7-submit:hover, #contact-cta #contact-wrapper .form-wrapper form .ff-btn-submit:hover, #contact-cta #contact-wrapper .form-wrapper form .wpcf7-submit:hover {
  background: #082140;
  color: #fff;
}
#contact-cta #contact-wrapper .form-wrapper fieldset {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}
#contact-cta #contact-wrapper .form-wrapper fieldset .row {
  flex: 1;
}
@media only screen and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper fieldset .row {
    width: 100%;
    flex: auto;
  }
}
#contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls-btns {
  width: auto;
  margin-left: 30px;
}
@media only screen and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls-btns {
    margin-left: 0px;
    width: 100%;
  }
}
#contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls_next {
  padding: 0;
  border: 0;
  width: 120px;
  height: 86px;
  text-align: center;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  color: #fff;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid transparent;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls_next {
    width: 100%;
    height: 70px;
    line-height: 70px;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls_next {
    width: 100%;
    height: 70px;
    line-height: 70px;
  }
}
#contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls_next:hover {
  background-image: url("../img/ico/Long-arrow-right.png");
  font-size: 0;
}
#contact-cta #contact-wrapper .form-wrapper fieldset .wpcf7-submit {
  padding: 0;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  height: 86px;
  text-align: center;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid transparent;
  margin-right: 0px;
  color: #1e1e1e;
  background: #FED647;
  margin-left: 30px;
}
@media only screen and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper fieldset .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 70px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper fieldset .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 70px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
#contact-cta #contact-wrapper .form-wrapper fieldset .wpcf7-submit:hover {
  background: #082140;
  color: #fff;
}
#contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls_back {
  padding: 0;
  border: 0;
  width: 120px;
  height: 86px;
  text-align: center;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  color: #fff;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid #227aeb;
  color: #227aeb;
  background-color: transparent;
  margin-right: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls_back {
    width: 100%;
    height: 70px;
    line-height: 70px;
    margin-right: 0px;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls_back {
    width: 100%;
    height: 70px;
    line-height: 70px;
    margin: 0px;
    margin-bottom: 15px;
  }
}
#contact-cta #contact-wrapper .form-wrapper fieldset .cf7mls_back:hover {
  background-image: url("../img/ico/long-arrow-left.png");
  font-size: 0;
}
#contact-cta #contact-wrapper .form-wrapper fieldset #cf7mls-back-btn-cf7mls_step-3 {
  display: none !important;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper {
  font-size: 16px;
  margin-bottom: 10px;
  color: #000;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper label {
  color: #000;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper label span {
  color: #000;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper a {
  color: #227aeb;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper a:hover {
  color: #0D539F;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper .wpcf7-list-item {
  margin-left: 0px;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper input[type=checkbox] {
  margin-right: 5px;
  position: relative;
  top: 1px;
  position: absolute;
  width: auto;
  opacity: 0;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper .wpcf7-list-item-label:before {
  content: " ";
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #afafb0;
  margin-right: 15px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 7px;
  border-radius: 6px;
}
#contact-cta #contact-wrapper .form-wrapper .checkbox-wrapper input:checked + span.wpcf7-list-item-label:before {
  background-color: #227aeb;
  border: 1px solid #227aeb;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
}
#contact-cta #contact-wrapper .form-wrapper .wpcf7-checkbox {
  font-size: 16px;
}
#contact-cta #contact-wrapper .form-wrapper .wpcf7-checkbox a {
  color: #227aeb;
}
#contact-cta #contact-wrapper .form-wrapper .wpcf7-checkbox a:hover {
  color: #0D539F;
}
@media only screen and (max-width: 767.98px) {
  #contact-cta #contact-wrapper .form-wrapper .form-block {
    margin-bottom: 15px;
  }
}
#contact-cta #contact-wrapper .form-wrapper .form-block input[type=text].wpcf7-not-valid, #contact-cta #contact-wrapper .form-wrapper .form-block input[type=tel].wpcf7-not-valid, #contact-cta #contact-wrapper .form-wrapper .form-block input[type=email].wpcf7-not-valid {
  border-color: red;
}
#contact-cta #contact-wrapper .form-wrapper .form-block input[type=text] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors, #contact-cta #contact-wrapper .form-wrapper .form-block input[type=tel] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors, #contact-cta #contact-wrapper .form-wrapper .form-block input[type=email] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors {
  border: none;
  padding: 0px;
  margin: 0px;
}
#contact-cta #contact-wrapper .form-wrapper .form-next button {
  padding: 0;
  border: 0;
  width: 151px;
  height: 86px;
  text-align: center;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  color: #fff;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta #contact-wrapper .form-wrapper .form-next button {
    width: 100%;
    height: 70px;
    line-height: 70px;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta #contact-wrapper .form-wrapper .form-next button {
    width: 100%;
    height: 70px;
    line-height: 70px;
  }
}
#contact-cta #contact-wrapper .form-wrapper .form-next button:hover {
  background-image: url("../img/ico/Long-arrow-right.png");
  font-size: 0;
}

.site-main {
  padding-top: 100px !important;
}

p.success {
  display: block;
  padding-top: 5px;
  color: #227aeb;
  font-family: "Source Sans Pro Semi";
}

.fluentform .ff-el-is-error .ff-el-form-control {
  border-color: #f56c6c !important;
}

#our-mission {
  background: #eff7fe;
  padding-top: 150px;
  padding-bottom: 250px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #our-mission {
    padding-top: 100px;
    padding-bottom: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #our-mission {
    padding-top: 50px;
    padding-bottom: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #our-mission {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#our-mission .mission-intro {
  padding-right: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #our-mission .mission-intro {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #our-mission .mission-intro {
    padding-right: 0px;
  }
}
#our-mission .mission-intro h2 {
  font-size: 50px;
  line-height: 1.2;
  margin-bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #our-mission .mission-intro h2 {
    font-size: 40px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #our-mission .mission-intro h2 {
    font-size: 40px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #our-mission .mission-intro h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #our-mission .mission-intro h2 br {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  #our-mission .mission-intro h2 br {
    display: none;
  }
}
#our-mission .mission-intro p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #our-mission .mission-intro p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #our-mission .mission-intro p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #our-mission .mission-intro p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #our-mission .mission-intro p {
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #our-mission .mission-content {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #our-mission .mission-content {
    margin-top: 40px;
  }
}
#our-mission .mission-content .mission-card {
  position: relative;
  padding-left: 65px;
  margin-bottom: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #our-mission .mission-content .mission-card {
    margin-bottom: 45px;
    padding-left: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #our-mission .mission-content .mission-card {
    margin-bottom: 40px;
    padding-left: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #our-mission .mission-content .mission-card {
    margin-bottom: 40px;
  }
}
#our-mission .mission-content .mission-card img {
  position: absolute;
  left: 0px;
  width: 42px;
  top: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #our-mission .mission-content .mission-card img {
    width: 38px;
  }
}
#our-mission .mission-content .mission-card h3 {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #our-mission .mission-content .mission-card h3 {
    margin-bottom: 10px;
  }
}
#our-mission .mission-content .mission-card p {
  line-height: 1.4;
}

#about-cta {
  margin-bottom: 150px;
  margin-top: -180px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-cta {
    margin-bottom: 100px;
    margin-top: -130px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-cta {
    margin-bottom: 100px;
    margin-top: -130px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-cta {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
#about-cta .about-text {
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 150px;
  padding-right: 150px;
  background-color: #227aeb;
  border-radius: 50px;
  box-shadow: 0 40px 76px rgba(0, 0, 0, 0.08);
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-cta .about-text {
    padding: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-cta .about-text {
    padding: 60px;
    border-radius: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-cta .about-text {
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-cta .about-text {
    padding: 30px;
  }
}
#about-cta .about-text p {
  margin-bottom: 0px;
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-family: "Poppins Bold";
  line-height: 1.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-cta .about-text p {
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-cta .about-text p {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-cta .about-text p {
    font-size: 22px;
    line-height: 1.4;
  }
}
#about-cta .about-text a.btn-cta {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-cta .about-text a.btn-cta {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-cta .about-text a.btn-cta {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#about-cta .about-text a.btn-cta:hover {
  background: #082140;
  color: #fff;
}
#about-cta .about-text footer {
  text-align: center;
  padding-top: 30px;
}
#about-cta.about-cta--inner {
  margin-top: 0px !important;
  margin-bottom: 80px;
}
#about-cta.about-cta--inner .about-text {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 767.98px) {
  #about-cta.about-cta--inner .about-text {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

#about-logos {
  padding-bottom: 100px;
  margin-top: -100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-logos {
    margin-top: -50px;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-logos {
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-logos {
    margin-top: 0px;
    padding-bottom: 50px;
  }
}
#about-logos #logos-slider {
  display: flex;
}
@media only screen and (max-width: 767.98px) {
  #about-logos #logos-slider {
    flex-wrap: wrap;
    justify-content: center;
  }
}
#about-logos #logos-slider .item {
  width: 25%;
}
@media only screen and (max-width: 767.98px) {
  #about-logos #logos-slider .item {
    width: 33%;
  }
}
#about-logos #logos-slider .item .logo {
  height: 150px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-logos #logos-slider .item .logo {
    height: 130px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-logos #logos-slider .item .logo {
    height: 130px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-logos #logos-slider .item .logo {
    height: 80px;
  }
}
#about-logos #logos-slider .item .logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 70%;
  max-height: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-logos #logos-slider .item .logo img {
    max-height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-ceo {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-ceo {
    padding-top: 0px;
  }
}
#about-ceo .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 767.98px) {
  #about-ceo .container {
    display: block;
  }
}
#about-ceo .container .ceo-image {
  width: 50%;
  margin-left: -50px;
}
@media only screen and (max-width: 767.98px) {
  #about-ceo .container .ceo-image {
    margin-left: 0px;
    width: 100%;
  }
}
#about-ceo .container .ceo-image img {
  max-width: initial;
  width: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-ceo .container .ceo-image img {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-ceo .container .ceo-image img {
    width: 100%;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-ceo .container .ceo-image img {
    width: 100%;
  }
}
#about-ceo .container .ceo-bio {
  width: 50%;
  padding-left: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-ceo .container .ceo-bio {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-ceo .container .ceo-bio {
    padding-left: 0px;
    width: 55%;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-ceo .container .ceo-bio {
    padding-left: 0px;
    width: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
}
#about-ceo .container .ceo-bio h3 {
  font-size: 32px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-ceo .container .ceo-bio h3 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-ceo .container .ceo-bio h3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-ceo .container .ceo-bio h3 {
    font-size: 26px;
  }
}
#about-ceo .container .ceo-bio h4 {
  margin-bottom: 30px;
  font-size: 18px;
  color: #3c3c3c;
  font-family: "Poppins Medium";
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-ceo .container .ceo-bio h4 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-ceo .container .ceo-bio h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-ceo .container .ceo-bio h4 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
#about-ceo .container .ceo-bio p {
  font-size: 18px;
  line-height: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-ceo .container .ceo-bio p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-ceo .container .ceo-bio p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-ceo .container .ceo-bio p {
    font-size: 16px;
    line-height: 24px;
  }
}
#about-ceo .container .ceo-bio footer {
  padding-top: 10px;
}
#about-ceo .container .ceo-bio footer ul {
  display: inline-block;
}
#about-ceo .container .ceo-bio footer ul li {
  margin-right: 15px;
  display: inline-block;
}
@media only screen and (max-width: 767.98px) {
  #about-ceo .container .ceo-bio footer ul li {
    margin-left: 10px;
    margin-right: 10px;
  }
}
#about-ceo .container .ceo-bio footer ul li a {
  font-size: 24px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  color: #227aeb;
}
#about-ceo .container .ceo-bio footer ul li a:hover {
  color: #0D539F;
}

#team {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #team {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #team {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
#team header {
  text-align: center;
  padding-bottom: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #team header {
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team header {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #team header {
    padding-bottom: 30px;
  }
}
#team header h2 {
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #team header h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team header h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #team header h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team header h2 {
    margin-bottom: 0px;
  }
}
#team #team-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767.98px) {
  #team #team-wrapper {
    display: block;
  }
}
#team #team-wrapper .team-card {
  width: 25%;
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (min-width: 240px) and (max-width: tablet) {
  #team #team-wrapper .team-card {
    width: 50%;
  }
}
@media only screen and (max-width: 767.98px) {
  #team #team-wrapper .team-card {
    width: 100%;
  }
}
#team #team-wrapper .team-card:nth-child(5), #team #team-wrapper .team-card:nth-child(6), #team #team-wrapper .team-card:nth-child(7), #team #team-wrapper .team-card:nth-child(8) {
  width: 20%;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #team #team-wrapper .team-card:nth-child(5), #team #team-wrapper .team-card:nth-child(6), #team #team-wrapper .team-card:nth-child(7), #team #team-wrapper .team-card:nth-child(8) {
    width: 25%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team #team-wrapper .team-card:nth-child(5), #team #team-wrapper .team-card:nth-child(6), #team #team-wrapper .team-card:nth-child(7), #team #team-wrapper .team-card:nth-child(8) {
    width: 25%;
  }
}
@media only screen and (max-width: 767.98px) {
  #team #team-wrapper .team-card:nth-child(5), #team #team-wrapper .team-card:nth-child(6), #team #team-wrapper .team-card:nth-child(7), #team #team-wrapper .team-card:nth-child(8) {
    width: 100%;
  }
}
#team #team-wrapper .team-card:nth-child(5) .team-photo img, #team #team-wrapper .team-card:nth-child(6) .team-photo img, #team #team-wrapper .team-card:nth-child(7) .team-photo img, #team #team-wrapper .team-card:nth-child(8) .team-photo img {
  width: 200px !important;
  height: 200px !important;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team #team-wrapper .team-card:nth-child(5) .team-photo img, #team #team-wrapper .team-card:nth-child(6) .team-photo img, #team #team-wrapper .team-card:nth-child(7) .team-photo img, #team #team-wrapper .team-card:nth-child(8) .team-photo img {
    width: 160px !important;
    height: 160px !important;
  }
}
#team #team-wrapper .team-card:nth-child(5) .team-desc h3, #team #team-wrapper .team-card:nth-child(6) .team-desc h3, #team #team-wrapper .team-card:nth-child(7) .team-desc h3, #team #team-wrapper .team-card:nth-child(8) .team-desc h3 {
  font-size: 20px;
}
#team #team-wrapper .team-card:hover .team-photo img {
  transform: scale(1.05);
  box-shadow: 0px 0px 34.56px 10px rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 767.98px) {
  #team #team-wrapper .team-card {
    margin-bottom: 40px;
  }
}
#team #team-wrapper .team-card .team-photo img {
  transition: 0.25s ease-in-out;
  border-radius: 100%;
  width: 240px;
  height: 240px;
  object-fit: cover;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #team #team-wrapper .team-card .team-photo img {
    width: 200px;
    height: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team #team-wrapper .team-card .team-photo img {
    width: 160px;
    height: 160px;
  }
}
#team #team-wrapper .team-card .team-desc {
  padding-top: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #team #team-wrapper .team-card .team-desc {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team #team-wrapper .team-card .team-desc {
    padding-top: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #team #team-wrapper .team-card .team-desc {
    padding-top: 15px;
  }
}
#team #team-wrapper .team-card .team-desc h3 {
  font-size: 24px;
  line-height: 1.2;
  max-width: 85%;
  margin: 0px auto;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #team #team-wrapper .team-card .team-desc h3 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team #team-wrapper .team-card .team-desc h3 {
    font-size: 18px;
    max-width: 95%;
  }
}
@media only screen and (max-width: 767.98px) {
  #team #team-wrapper .team-card .team-desc h3 {
    font-size: 22px;
    margin-bottom: 5px;
    max-width: 100%;
  }
}
#team #team-wrapper .team-card .team-desc span.position {
  display: block;
  margin-bottom: 20px;
  color: #a4a4a4;
  font-size: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #team #team-wrapper .team-card .team-desc span.position {
    margin-bottom: 15px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #team #team-wrapper .team-card .team-desc span.position {
    font-size: 16px;
  }
}
#team #team-wrapper .team-card .team-desc footer ul li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
#team #team-wrapper .team-card .team-desc footer ul li a {
  background: #f2f2f2;
  color: #778178;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  font-size: 16px;
}
#team #team-wrapper .team-card .team-desc footer ul li a span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#team #team-wrapper .team-card .team-desc footer ul li a:hover {
  background: #227aeb;
  color: #fff;
}

#about-testimonials {
  background: #eff7fe;
  padding-top: 120px;
  padding-bottom: 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-testimonials {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#about-testimonials .testimonials-image img {
  width: 100%;
}
#about-testimonials .testimonials-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
#about-testimonials .testimonials-wrapper #reviews-slider {
  width: 100%;
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider {
    margin-top: 30px;
    text-align: center;
  }
}
#about-testimonials .testimonials-wrapper #reviews-slider .item {
  padding-top: 50px;
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item {
    padding-top: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item p {
  font-size: 18px;
  line-height: 26px;
  font-size: 28px;
  line-height: 42px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item p {
    font-size: 22px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item p {
    font-size: 20px;
    line-height: 30px;
  }
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer {
  margin-top: 35px;
  position: relative;
  padding-left: 170px;
  height: 140px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer {
    margin-top: 50px;
    padding-left: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer {
    height: auto;
    padding-left: 0px;
    margin-top: 30px;
    text-align: center;
    display: block;
  }
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer .profile-image {
  position: absolute;
  left: 0px;
  box-shadow: 0px 0px 14.56px 0px rgba(0, 0, 0, 0.05);
  top: 0px;
  width: 140px;
  height: 140px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer .profile-image {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer .profile-image {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin: 0px auto;
    margin-bottom: 25px;
    width: 120px;
    height: 120px;
  }
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer .profile-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 70%;
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer a.btn-more {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
  min-width: 100px;
  padding-left: 20px;
  padding-right: 20px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  margin-top: 10px;
  width: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer a.btn-more {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer a.btn-more {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer a.btn-more:hover {
  background: #082140;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer a.btn-more {
    font-size: 14px;
    width: auto;
  }
}
@media only screen and (max-width: 767.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer a.btn-more {
    margin-top: 15px;
    width: auto;
  }
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer span {
  display: block;
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer span.author {
  font-size: 20px;
  color: #0D539F;
  font-family: "Poppins Medium";
  margin-bottom: 3px;
  line-height: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer span.author {
    font-size: 18px;
  }
}
#about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer span.position {
  font-size: 18px;
  color: #a4a4a4;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #about-testimonials .testimonials-wrapper #reviews-slider .item .review-item footer span.position {
    font-size: 16px;
  }
}

#rest-team {
  padding-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #rest-team {
    padding-bottom: 70px;
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team {
    padding-bottom: 50px;
  }
}
#rest-team img.map {
  width: 100%;
}
@media only screen and (max-width: 767.98px) {
  #rest-team img.map {
    display: block;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #rest-team img.map {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team img.map {
    display: none;
  }
}
#rest-team .tab {
  margin: 0px auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #227aeb;
  width: auto;
  padding: 5px;
  width: 800px;
  border-radius: 999px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #rest-team .tab {
    width: 100% !important;
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team .tab {
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 4px;
  }
}
#rest-team .tab button {
  width: 200px;
  border-radius: 999px;
  border: none;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #227aeb;
  padding-top: 13px;
  padding-bottom: 13px;
  flex: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #rest-team .tab button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team .tab button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
  }
}
#rest-team .tab button.active {
  background: #227aeb !important;
  color: #fff !important;
}
#rest-team .tab button:hover {
  color: #227aeb;
  background: none;
}
#rest-team #dev-team .team-box:first-child {
  left: 49%;
  top: 32%;
}
#rest-team #dev-team .team-box:nth-child(2) {
  left: 55%;
  top: 30%;
}
#rest-team #dev-team .team-box:nth-child(3) {
  left: 46%;
  top: 40%;
}
#rest-team #dev-team .team-box:nth-child(4) {
  left: 45%;
  top: 24%;
}
#rest-team #dev-team .team-box:nth-child(5) {
  left: 41%;
  top: 35%;
}
#rest-team #dev-team .team-box:nth-child(6) {
  left: 53%;
  top: 39%;
}
#rest-team #dev-team .team-box:nth-child(7) {
  left: 52%;
  top: 20%;
}
#rest-team #dev-team .team-box:nth-child(8) {
  top: 71%;
  left: 30%;
}
#rest-team #dev-team .team-box:nth-child(9) {
  top: 44%;
  left: 68%;
}
#rest-team #dev-team .team-box:nth-child(10) {
  top: 80%;
  left: 28%;
}
#rest-team #dev-team .team-box:nth-child(11) {
  top: 53%;
  left: 70%;
}
#rest-team #dev-team .team-box:nth-child(12) {
  top: 41%;
  left: 60%;
}
#rest-team #marketing-team .team-box:first-child {
  left: 30%;
  top: 71%;
}
#rest-team #marketing-team .team-box:nth-child(2) {
  left: 67%;
  top: 46%;
}
#rest-team #marketing-team .team-box:nth-child(3) {
  left: 29%;
  top: 80%;
}
#rest-team #marketing-team .team-box:nth-child(4) {
  left: 65%;
  top: 54%;
}
#rest-team #marketing-team .team-box:nth-child(5) {
  left: 52%;
  top: 34%;
}
#rest-team #marketing-team .team-box:nth-child(6) {
  left: 12%;
  top: 40%;
}
#rest-team #marketing-team .team-box:nth-child(7) {
  left: 82%;
  top: 70%;
}
#rest-team #recruit-team .team-box:first-child {
  left: 25%;
  top: 35%;
}
#rest-team #recruit-team .team-box:nth-child(2) {
  left: 29%;
  top: 52%;
}
#rest-team #recruit-team .team-box:nth-child(3) {
  left: 23%;
  top: 55%;
}
#rest-team #recruit-team .team-box:nth-child(4) {
  left: 55%;
  top: 54%;
}
#rest-team #recruit-team .team-box:nth-child(5) {
  left: 48%;
  top: 35%;
}
#rest-team #recruit-team .team-box:nth-child(6) {
  left: 12%;
  top: 45%;
}
#rest-team #recruit-team .team-box:nth-child(7) {
  left: 30%;
  top: 79%;
}
#rest-team #recruit-team .team-box:nth-child(9) {
  left: 22%;
  top: 64%;
}
#rest-team #recruit-team .team-box:nth-child(10) {
  left: 59%;
  top: 30%;
}
#rest-team #recruit-team .team-box:nth-child(11) {
  left: 52%;
  top: 23%;
}
#rest-team #recruit-team .team-box:nth-child(12) {
  left: 27%;
  top: 70%;
}
#rest-team #recruit-team .team-box:nth-child(13) {
  left: 32%;
  top: 64%;
}
#rest-team #recruit-team .team-box:nth-child(14) {
  left: 39%;
  top: 44%;
}
#rest-team #recruit-team .team-box:nth-child(14) {
  left: 19%;
  top: 14%;
}
#rest-team #recruit-team .team-box:nth-child(15) {
  left: 27%;
  top: 61%;
}
#rest-team #recruit-team .team-box:nth-child(16) {
  left: 54%;
  top: 33%;
}
#rest-team #recruit-team .team-box:nth-child(17) {
  left: 78%;
  top: 52%;
}
#rest-team #recruit-team .team-box:nth-child(8) {
  left: 48%;
  top: 12%;
}
#rest-team #accounting-team .team-box:first-child {
  left: 55%;
  top: 29%;
}
#rest-team #accounting-team .team-box:nth-child(2) {
  left: 43%;
  top: 32%;
}
#rest-team #accounting-team .team-box:nth-child(3) {
  left: 25%;
  top: 62%;
}
#rest-team #accounting-team .team-box:nth-child(4) {
  left: 65%;
  top: 54%;
}
#rest-team #accounting-team .team-box:nth-child(5) {
  left: 24%;
  top: 30%;
}
#rest-team #accounting-team .team-box:nth-child(6) {
  left: 12%;
  top: 40%;
}
#rest-team #accounting-team .team-box:nth-child(7) {
  left: 82%;
  top: 70%;
}
#rest-team #map-holder {
  position: relative;
}
#rest-team #map-holder .team-box {
  position: absolute;
  top: 0px;
  left: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #rest-team #map-holder .team-box {
    position: relative;
    left: auto !important;
    top: auto !important;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 14.56px 0px rgba(0, 0, 0, 0.05);
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team #map-holder .team-box {
    position: relative;
    left: auto !important;
    top: auto !important;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 14.56px 0px rgba(0, 0, 0, 0.05);
  }
}
#rest-team #map-holder .team-box a {
  display: flex;
  align-items: center;
  position: relative;
  width: 56px;
  height: 56px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #rest-team #map-holder .team-box a {
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team #map-holder .team-box a {
    width: 100%;
    height: auto;
  }
}
#rest-team #map-holder .team-box a span.name {
  display: block;
  font-size: 18px;
  color: #0D539F;
  font-family: "Poppins Medium";
}
@media screen and (min-width: 1024px) {
  #rest-team #map-holder .team-box a span.name {
    background: #0D539F;
    color: #fff;
    font-size: 13px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 9px;
    text-align: center;
    line-height: 1.2;
    padding-top: 6px;
    padding-bottom: 6px;
    top: -50px;
    opacity: 0;
    transition: 0.25s ease-in-out;
    z-index: 1050;
    display: none;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in;
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team #map-holder .team-box a span.name {
    display: block;
    visibility: visible;
    opacity: 1;
    flex: 1;
    visibility: visible;
  }
}
#rest-team #map-holder .team-box a span.name:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12px 0 12px;
  border-color: #0D539F transparent transparent transparent;
  position: absolute;
  left: 0px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -10px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #rest-team #map-holder .team-box a span.name:after {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team #map-holder .team-box a span.name:after {
    display: none;
  }
}
#rest-team #map-holder .team-box a:hover img {
  transform: scale(1.1);
  box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.05);
}
#rest-team #map-holder .team-box a:hover span.name {
  opacity: 1;
  display: block;
  visibility: visible;
  opacity: 1;
  visibility: visible;
}
#rest-team #map-holder .team-box img {
  border-radius: 100%;
  width: 56px;
  height: 56px;
  object-fit: cover;
  transition: 0.25s ease-in-out;
  border: 3px solid #FED647;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #rest-team #map-holder .team-box img {
    margin-right: 25px;
  }
}
@media only screen and (max-width: 767.98px) {
  #rest-team #map-holder .team-box img {
    margin-right: 25px;
  }
}

/* Simple Modal*/
html.fixed {
  overflow: hidden;
}

@media only screen and (min-width: 40em) {
  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), visibility 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  }
  .modal-overlay.active {
    opacity: 1;
    visibility: visible;
  }
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100px;
  margin: 0 auto;
  background-color: #fff;
  width: 90%;
  max-width: 1170px;
  min-height: 20rem;
  padding: 0px;
  border-radius: 0px;
  opacity: 0;
  overflow-y: auto;
  visibility: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  backface-visibility: hidden;
  transform: scale(1.2);
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  justify-content: space-between;
}
.modal .close-modal {
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 50px;
  opacity: 0;
  backface-visibility: hidden;
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.3s;
  z-index: 9999999;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .close-modal {
    top: 15px;
    right: 30px;
  }
}
.modal .close-modal img {
  width: 36px;
  height: 36px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .close-modal img {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  .modal .close-modal img {
    width: 24px;
    height: 24px;
  }
}
.modal .img-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.modal .modal-content {
  opacity: 0;
  backface-visibility: hidden;
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.3s;
  padding: 60px;
  min-height: 600px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .modal .modal-content {
    padding: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .modal-content {
    padding: 30px;
    padding-top: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  .modal .modal-content {
    padding: 30px;
  }
}
.modal .modal-content .author-desc {
  padding-top: 19px;
}
.modal .modal-content .author-desc h4 {
  font-size: 22px;
  margin-bottom: 5px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .modal .modal-content .author-desc h4 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .modal-content .author-desc h4 {
    font-size: 20px;
  }
}
.modal .modal-content .author-desc span.position {
  display: block;
  font-size: 20px;
  color: #333333;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .modal-content .author-desc span.position {
    font-size: 18px;
  }
}
.modal .modal-content .author-desc .btn-in {
  position: relative;
  z-index: 999;
}
.modal .content-wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767.98px) {
  .modal .content-wrapper {
    display: block;
  }
}
.modal .content-wrapper .col-image {
  width: 40%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .content-wrapper .col-image {
    width: 35%;
  }
}
@media only screen and (max-width: 767.98px) {
  .modal .content-wrapper .col-image {
    width: 100%;
  }
}
.modal .content-wrapper .col-image img {
  width: 100%;
  border-radius: 100%;
}
@media only screen and (max-width: 767.98px) {
  .modal .content-wrapper .col-image img {
    width: 80%;
    margin: 0px auto;
    display: block;
  }
}
.modal .content-wrapper .col-image footer {
  text-align: center;
  padding-top: 20px;
}
.modal .content-wrapper .col-image ul li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.modal .content-wrapper .col-image ul li a {
  background: #f2f2f2;
  color: #778178;
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 32px;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  font-size: 20px;
}
.modal .content-wrapper .col-image ul li a span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.modal .content-wrapper .col-image ul li a:hover {
  background: #227aeb;
  color: #fff;
}
.modal .content-wrapper .col-text {
  width: 50%;
  padding-top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .content-wrapper .col-text {
    padding-top: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  .modal .content-wrapper .col-text {
    width: 100%;
  }
}
.modal .content-wrapper .col-text h4 {
  font-size: 32px;
}
.modal .content-wrapper .col-text span.position {
  display: block;
  margin-bottom: 30px;
}
.modal .content-wrapper .col-text p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .modal .content-wrapper .col-text p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .content-wrapper .col-text p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  .modal .content-wrapper .col-text p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .modal .content-wrapper .col-text p {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  .modal .content-wrapper .col-text p {
    margin-bottom: 15px;
  }
}
.modal .modal-text {
  padding-left: 50px;
  padding-right: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal .modal-text {
    padding-left: 30px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  .modal .modal-text {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.modal .modal-text h2 {
  font-size: 25px;
  margin-bottom: 30px;
}
.modal .modal-text p {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 25px;
}
.modal .col-text {
  flex: 1;
}
.modal.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.modal.active .modal-content {
  opacity: 1;
}
.modal.active .close-modal {
  transform: translateY(10px);
  opacity: 1;
  z-index: 9999999;
}

@media only screen and (max-width: 39.9375em) {
  .modal-overlay {
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), visibility 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  }
  .modal-overlay.active {
    opacity: 1;
    visibility: visible;
  }
  .modal {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    border-radius: 0;
    transform: scale(1.1);
    padding: 0 !important;
  }
  .modal .modal-content {
    position: absolute;
    top: 0px;
    left: 0;
  }
  .close-modal {
    right: 20px !important;
    z-index: 999999;
  }
}
/* Overlay */
.modal-overlay {
  display: none;
}
.modal-overlay.active {
  overflow-y: scroll;
}
.modal-overlay.show {
  display: flex;
}

.img-responsive {
  max-width: 100%;
}

body.page-template-about-template #about {
  padding-top: 0px;
  margin-top: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  body.page-template-about-template #about {
    padding-bottom: 70px;
  }
}

#lp-header {
  padding-top: 45px;
  padding-bottom: 45px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
  background-color: #fff;
  transition: 0.25s ease-in-out;
}
@media only screen and (max-width: 767.98px) {
  #lp-header {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
#lp-header.sticky-lp {
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}
#lp-header #lp-logo {
  width: 230px;
}
@media only screen and (max-width: 767.98px) {
  #lp-header #lp-logo {
    width: 180px;
  }
}
#lp-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#lp-header .lp-header--cta a.btn-cta {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-header .lp-header--cta a.btn-cta {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-header .lp-header--cta a.btn-cta {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#lp-header .lp-header--cta a.btn-cta:hover {
  background: #082140;
  color: #fff;
}

#lp-intro {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 75px;
}
@media only screen and (max-width: 767.98px) {
  #lp-intro {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
#lp-intro h1 {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 35px;
  margin-bottom: 35px;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-intro h1 {
    font-size: 50px;
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-intro h1 {
    font-size: 42px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-intro h1 {
    font-size: 38px;
    margin-bottom: 15px;
  }
}
#lp-intro h1 b {
  color: #227aeb;
}
#lp-intro h1 strong {
  display: block;
  font-size: 42px;
  line-height: 60px;
  font-family: "Source Sans Pro";
  font-weight: 400;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-intro h1 strong {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-intro h1 strong {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-intro h1 strong {
    font-size: 24px;
  }
}
#lp-intro p {
  font-family: "Poppins Medium";
  font-size: 24px;
  line-height: 1.333;
  color: #000;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-intro p {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-intro p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-intro p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #contact-cta.contact-lp {
    margin-bottom: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #contact-cta.contact-lp {
    margin-bottom: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #contact-cta.contact-lp {
    margin-bottom: 80px !important;
  }
}
@media only screen and (max-width: 767.98px) {
  #contact-cta.contact-lp {
    margin-bottom: 60px !important;
  }
}
#contact-cta.contact-lp #contact-wrapper {
  max-width: 650px;
  margin: 0px auto;
}

#lp-features {
  margin-top: 100px;
  padding-bottom: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-features {
    margin-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-features {
    margin-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-features {
    margin-top: 50px;
    padding-bottom: 20px;
  }
}
#lp-features .lp-features__intro .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__intro .container {
    display: block;
  }
}
#lp-features .lp-features__intro .feature-text {
  width: 50%;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-features .lp-features__intro .feature-text {
    width: 60%;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__intro .feature-text {
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
  }
}
#lp-features .lp-features__intro .feature-text h2 {
  font-size: 46px;
  line-height: 1.304;
  font-family: "Poppins Bold";
  margin-bottom: 25px;
  width: 95%;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-features .lp-features__intro .feature-text h2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-features .lp-features__intro .feature-text h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__intro .feature-text h2 {
    font-size: 26px;
    width: 100%;
    margin-bottom: 15px;
  }
}
#lp-features .lp-features__intro .feature-text p {
  font-size: 18px;
  line-height: 26px;
  width: 90%;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-features .lp-features__intro .feature-text p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-features .lp-features__intro .feature-text p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__intro .feature-text p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__intro .feature-text p {
    width: 100%;
    max-width: 90%;
    margin: 0px auto;
  }
}
#lp-features .lp-features__intro .feature-text a.btn-cta {
  display: inline-block;
  height: 62px;
  border-radius: 999px;
  background: #FED647;
  line-height: 62px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
  text-transform: uppercase;
  padding-left: 40px;
  padding-right: 40px;
  min-width: 200px;
  text-align: center;
  margin-top: 35px;
  text-transform: none;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-features .lp-features__intro .feature-text a.btn-cta {
    height: 52px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__intro .feature-text a.btn-cta {
    height: 50px;
    line-height: 50px;
  }
}
#lp-features .lp-features__intro .feature-text a.btn-cta:hover {
  background: #082140;
  color: #fff;
}
@media (min-width: 768px) {
  #lp-features .lp-features__intro .feature-text a.btn-cta {
    padding-left: 75px;
    padding-right: 75px;
  }
}
#lp-features .lp-features__intro .feature-image {
  width: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-features .lp-features__intro .feature-image {
    width: 40%;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__intro .feature-image {
    width: 100%;
  }
}
#lp-features .lp-features__intro .feature-image img {
  width: 610px;
  max-width: inherit;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-features .lp-features__intro .feature-image img {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-features .lp-features__intro .feature-image img {
    width: 100%;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__intro .feature-image img {
    width: 100%;
  }
}
#lp-features .lp-features__items {
  margin-top: 75px;
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__items {
    margin-top: 50px;
  }
}
#lp-features .lp-features__items .col-md-4:nth-child(3n+2) .lp-features__item {
  margin-left: auto;
  margin-right: auto;
}
#lp-features .lp-features__items .col-md-4:nth-child(3n+3) .lp-features__item {
  margin-left: auto;
}
#lp-features .lp-features__item {
  max-width: 252px;
}
@media only screen and (max-width: 767.98px) {
  #lp-features .lp-features__item {
    max-width: 100%;
    margin-bottom: 40px;
  }
}
#lp-features .lp-features__item .item-ico {
  margin-bottom: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 82px;
}
#lp-features .lp-features__item .item-text h3 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 14px;
}

#lp-steps {
  background-color: #eff7fe;
  padding-top: 100px;
  padding-bottom: 275px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-steps {
    padding-top: 80px;
    padding-bottom: 250px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-steps {
    padding-top: 60px;
    padding-bottom: 250px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-steps {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#lp-steps h2 {
  font-size: 46px;
  line-height: 1.304;
  font-family: "Poppins Bold";
  margin-bottom: 70px;
  max-width: 835px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-steps h2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-steps h2 {
    font-size: 32px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-steps h2 {
    font-size: 26px;
    max-width: 100%;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-steps .lp-step {
    margin-bottom: 40px;
  }
}
#lp-steps .lp-step .step-num {
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Pro";
  color: rgb(255, 255, 255);
  line-height: 0.813;
  border-radius: 50%;
  background-color: #227aeb;
  width: 74px;
  height: 74px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-steps .lp-step .step-num {
    font-size: 30px;
    width: 70px;
    height: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-steps .lp-step .step-num {
    font-size: 28px;
    width: 65px;
    height: 65px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-steps .lp-step .step-num {
    font-size: 24px;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
  }
}
#lp-steps .lp-step .lp-step-ico {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 55px;
  height: 160px;
  max-width: 303px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 767.98px) {
  #lp-steps .lp-step .lp-step-ico {
    margin-bottom: 35px;
  }
}
#lp-steps .lp-step .lp-step-ico img {
  max-width: 241px;
  max-height: 180px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-steps .lp-step .lp-step-ico img {
    max-width: 221px;
    max-height: 160px;
  }
}
#lp-steps .lp-step p {
  text-align: center;
  max-width: 303px;
  margin-left: auto;
  margin-right: auto;
}
#lp-steps .view-btn-wrap {
  margin-top: 80px;
  text-align: center;
}
@media only screen and (max-width: 767.98px) {
  #lp-steps .view-btn-wrap {
    margin-top: 10px;
  }
}
#lp-steps .view-btn-wrap a {
  display: inline-block;
  height: 62px;
  border-radius: 999px;
  background: #FED647;
  line-height: 62px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
  text-transform: uppercase;
  padding-left: 40px;
  padding-right: 40px;
  text-transform: none;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-steps .view-btn-wrap a {
    height: 52px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-steps .view-btn-wrap a {
    height: 50px;
    line-height: 50px;
  }
}
#lp-steps .view-btn-wrap a:hover {
  background: #082140;
  color: #fff;
}
@media (min-width: 768px) {
  #lp-steps .view-btn-wrap a {
    padding-left: 75px;
    padding-right: 75px;
  }
}

#lp-testimonials {
  margin-top: -175px;
  margin-bottom: 160px;
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #lp-testimonials {
    margin-bottom: 140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-testimonials {
    margin-bottom: 110px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-testimonials {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-testimonials {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
#lp-testimonials .slider-for {
  background-color: #227aeb;
  border-radius: 32px;
  padding: 80px 65px 45px 65px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-testimonials .slider-for {
    padding: 75px 55px 45px 55px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-testimonials .slider-for {
    padding: 55px 55px 45px 55px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-testimonials .slider-for {
    padding: 40px 15px;
  }
}
#lp-testimonials .slider-for .testimonial-text {
  text-align: center;
  max-width: 724px;
  margin-left: auto;
  margin-right: auto;
}
#lp-testimonials .slider-for .testimonial-text p {
  font-size: 32px;
  font-family: "Poppins Bold";
  color: rgb(255, 255, 255);
  line-height: 1.375;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-testimonials .slider-for .testimonial-text p {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-testimonials .slider-for .testimonial-text p {
    font-size: 28px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-testimonials .slider-for .testimonial-text p {
    font-size: 22px;
  }
}
#lp-testimonials .slider-for .testimonial-author {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767.98px) {
  #lp-testimonials .slider-for .testimonial-author {
    flex-direction: column;
  }
}
#lp-testimonials .slider-for .testimonial-author .img-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 128px;
  width: 128px;
  height: 128px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 30px;
}
@media only screen and (max-width: 767.98px) {
  #lp-testimonials .slider-for .testimonial-author .img-holder {
    min-width: 90px;
    width: 90px;
    height: 90px;
    margin-right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}
#lp-testimonials .slider-for .testimonial-author .img-holder img {
  padding: 16px;
}
@media only screen and (max-width: 767.98px) {
  #lp-testimonials .slider-for .testimonial-author .author-info {
    text-align: center;
  }
}
#lp-testimonials .slider-for .testimonial-author .author-info .author {
  display: block;
  font-family: "Poppins Bold";
  font-size: 24px;
  color: rgb(255, 255, 255);
  line-height: 1.833;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-testimonials .slider-for .testimonial-author .author-info .author {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-testimonials .slider-for .testimonial-author .author-info .author {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-testimonials .slider-for .testimonial-author .author-info .author {
    font-size: 18px;
  }
}
#lp-testimonials .slider-for .testimonial-author .author-info .position {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Source Sans Pro";
  color: rgba(255, 255, 255, 0.659);
  display: block;
}
#lp-testimonials .slider-nav {
  margin-top: 65px;
}
@media only screen and (max-width: 767.98px) {
  #lp-testimonials .slider-nav {
    margin-top: 30px;
  }
}
#lp-testimonials .slider-nav .slick-track {
  display: flex;
}
#lp-testimonials .slider-nav .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}
#lp-testimonials .slider-nav .slick-track .slick-slide:hover {
  cursor: pointer;
}
#lp-testimonials .slider-nav .slick-track .slick-slide img {
  opacity: 0.35;
  transition: 0.25s ease-in-out;
  max-width: 60%;
}
#lp-testimonials .slider-nav .slick-track .slick-slide.slick-current img {
  opacity: 1;
}
#lp-testimonials .slider-nav .slick-dots {
  text-align: center;
}

#lp-why {
  padding-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-why {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-why {
    padding-bottom: 20px;
  }
}
#lp-why h2 {
  font-size: 46px;
  line-height: 1.304;
  font-family: "Poppins Bold";
  margin-bottom: 70px;
  max-width: 835px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-why h2 {
    font-size: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-why h2 {
    font-size: 32px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-why h2 {
    font-size: 26px;
    width: 100%;
    margin-bottom: 40px;
  }
}
#lp-why .lp-why-list .col-md-4:nth-child(3n+2) .lp-why-item {
  margin-left: auto;
  margin-right: auto;
}
#lp-why .lp-why-list .col-md-4:nth-child(3n+3) .lp-why-item {
  margin-left: auto;
}
#lp-why .lp-why-list .lp-why-item {
  max-width: 252px;
  text-align: center;
}
@media only screen and (max-width: 767.98px) {
  #lp-why .lp-why-list .lp-why-item {
    max-width: 100%;
    margin-bottom: 40px;
  }
}
#lp-why .lp-why-list .lp-why-item .item-ico {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 82px;
}

#lp-cta {
  background-color: #0D539F;
  padding-top: 70px;
  padding-bottom: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-cta {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-cta {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-cta {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#lp-cta .lp-cta-intro {
  text-align: center;
  padding-bottom: 95px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-cta .lp-cta-intro {
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-cta .lp-cta-intro {
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-cta .lp-cta-intro {
    padding-bottom: 50px;
  }
}
#lp-cta .lp-cta-intro h3 {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  font-size: 32px;
  font-family: "Poppins Bold";
  color: rgb(255, 255, 255);
  line-height: 1.375;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-cta .lp-cta-intro h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-cta .lp-cta-intro h3 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-cta .lp-cta-intro h3 {
    font-size: 24px;
    max-width: 100%;
  }
}
#lp-cta .lp-cta-intro p {
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Poppins Medium";
  font-size: 24px;
  line-height: 1.333;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #lp-cta .lp-cta-intro p {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-cta .lp-cta-intro p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #lp-cta .lp-cta-intro p {
    font-size: 18px;
  }
}
#lp-cta #form-wrapper {
  max-width: 780px;
  padding: 40px;
  margin: 0px auto;
  margin-top: 50px;
  text-align: left;
  position: relative;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 32px;
}
@media only screen and (max-width: 767.98px) {
  #lp-cta #form-wrapper {
    padding: 20px;
  }
}
#lp-cta #form-wrapper form {
  padding-top: 20px;
}
#lp-cta #form-wrapper form .row-wrapper {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767.98px) {
  #lp-cta #form-wrapper form .row-wrapper {
    margin-bottom: 15px;
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
}
#lp-cta #form-wrapper form .row-wrapper .form-block {
  width: 50%;
  margin-right: 15px;
  margin-left: 15px;
}
@media only screen and (max-width: 767.98px) {
  #lp-cta #form-wrapper form .row-wrapper .form-block {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
#lp-cta #form-wrapper form .row-wrapper .form-block-full {
  width: 100%;
}
#lp-cta #form-wrapper form label {
  margin-bottom: 11px;
  font-family: "Source Sans Pro Semi";
  display: block;
  color: #fff;
}
#lp-cta #form-wrapper form label span {
  color: red;
}
#lp-cta #form-wrapper form input[type=text], #lp-cta #form-wrapper form input[type=tel], #lp-cta #form-wrapper form input[type=email], #lp-cta #form-wrapper form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 62px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 0 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
#lp-cta #form-wrapper form select {
  background: url("../img/ico/down.svg") no-repeat;
  background-position: 97% center;
  background-size: 24px;
  background-color: #fff;
}
#lp-cta #form-wrapper form textarea {
  width: 100%;
  height: 136px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
#lp-cta #form-wrapper form .ff-el-form-check {
  padding-top: 8px;
  font-size: 16px;
}
#lp-cta #form-wrapper form .ff-el-form-check a {
  color: #227aeb;
}
#lp-cta #form-wrapper form .ff-el-form-check a:hover {
  color: #0D539F;
}
#lp-cta #form-wrapper form .ff-el-form-check input[type=checkbox] {
  margin-right: 5px;
  position: relative;
  top: -1px;
}
#lp-cta #form-wrapper form .ff-btn-submit, #lp-cta #form-wrapper form .wpcf7-submit {
  padding: 0;
  border: 0;
  width: auto;
  padding-left: 60px;
  padding-right: 60px;
  height: 86px;
  line-height: 84px;
  text-align: center;
  border-radius: 999px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid transparent;
  margin-right: 0px;
  color: #1e1e1e;
  background: #FED647;
  margin-top: 20px;
}
@media only screen and (max-width: 991.98px) {
  #lp-cta #form-wrapper form .ff-btn-submit, #lp-cta #form-wrapper form .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #lp-cta #form-wrapper form .ff-btn-submit, #lp-cta #form-wrapper form .wpcf7-submit {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
#lp-cta #form-wrapper form .ff-btn-submit:hover, #lp-cta #form-wrapper form .wpcf7-submit:hover {
  background: #082140;
  color: #fff;
}
#lp-cta #form-wrapper .checkbox-wrapper {
  font-size: 16px;
  margin-bottom: 10px;
  color: #fff;
}
#lp-cta #form-wrapper .checkbox-wrapper label {
  color: #fff;
}
#lp-cta #form-wrapper .checkbox-wrapper label span {
  color: #fff;
}
#lp-cta #form-wrapper .checkbox-wrapper a {
  color: #FED647;
}
#lp-cta #form-wrapper .checkbox-wrapper a:hover {
  color: #fff;
}
#lp-cta #form-wrapper .checkbox-wrapper .wpcf7-list-item {
  margin-left: 0px;
}
#lp-cta #form-wrapper .checkbox-wrapper input[type=checkbox] {
  margin-right: 5px;
  position: relative;
  top: 1px;
  position: absolute;
  width: auto;
  opacity: 0;
}
#lp-cta #form-wrapper .checkbox-wrapper .wpcf7-list-item-label:before {
  content: " ";
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #afafb0;
  margin-right: 15px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 7px;
  border-radius: 6px;
}
#lp-cta #form-wrapper .checkbox-wrapper input:checked + span.wpcf7-list-item-label:before {
  background-color: #082140;
  border: 1px solid #0D539F;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
}
#lp-cta #form-wrapper .wpcf7-checkbox {
  font-size: 16px;
}
#lp-cta #form-wrapper .wpcf7-checkbox a {
  color: #FED647;
}
#lp-cta #form-wrapper .wpcf7-checkbox a:hover {
  color: #fff;
}
@media only screen and (max-width: 767.98px) {
  #lp-cta #form-wrapper .form-block {
    margin-bottom: 15px;
  }
}
#lp-cta #form-wrapper .form-block input[type=text].wpcf7-not-valid, #lp-cta #form-wrapper .form-block input[type=tel].wpcf7-not-valid, #lp-cta #form-wrapper .form-block input[type=email].wpcf7-not-valid {
  border-color: #ffd4d4;
}
#lp-cta #form-wrapper .form-block input[type=text] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors, #lp-cta #form-wrapper .form-block input[type=tel] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors, #lp-cta #form-wrapper .form-block input[type=email] .fieldset-cf7mls-wrapper div.wpcf7-validation-errors {
  border: none;
  padding: 0px;
  margin: 0px;
}
#lp-cta #form-wrapper .wpcf7-not-valid-tip {
  font-size: 14px;
  padding-left: 15px;
  padding-top: 7px;
  color: #ffd4d4;
}
#lp-cta #form-wrapper .wpcf7-response-output {
  border: none;
  padding: 0px;
  width: 100%;
  margin-left: 15px;
  color: #ffd4d4;
}

#blog-slider-wrapper {
  padding-top: 125px;
  margin-bottom: 60px;
}
@media only screen and (min-width: 1025px) and (max-height: 767px) {
  #blog-slider-wrapper {
    padding-top: 70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #blog-slider-wrapper {
    padding-top: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-slider-wrapper {
    padding-top: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider-wrapper {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-slider-wrapper {
    padding-top: 90px;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider-wrapper #blog-slider .slick-slide {
    margin: 0 13px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider-wrapper #blog-slider .slick-list {
    margin: 0 -13px;
  }
}
#blog-slider-wrapper #blog-slider .blog-item {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 991.98px) {
  #blog-slider-wrapper #blog-slider .blog-item {
    display: block;
  }
}
#blog-slider-wrapper #blog-slider .blog-item .blog-photo {
  margin-right: 68px;
  max-width: 595px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-photo {
    margin-right: 50px;
    min-width: 50%;
  }
}
@media only screen and (max-width: 991.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-photo {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
    min-width: auto;
  }
}
#blog-slider-wrapper #blog-slider .blog-item .blog-photo img {
  transition: 0.25s ease-in-out;
  display: block;
  border-radius: 12px;
  width: 100%;
  height: 450px;
  object-fit: cover;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-photo img {
    height: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-photo img {
    height: 280px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-photo img {
    height: 250px;
  }
}
#blog-slider-wrapper #blog-slider .blog-item .blog-photo a {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 12px;
}
#blog-slider-wrapper #blog-slider .blog-item .blog-photo a:hover img {
  transform: scale(1.1);
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content {
  flex: 1;
  padding-left: 30px;
}
@media only screen and (max-width: 1199.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-content {
    padding-left: 0px;
  }
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content span.post-date {
  display: block;
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: rgb(131, 136, 143);
  margin-bottom: 25px;
}
@media only screen and (max-width: 1199.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-content span.post-date {
    font-size: 15px;
    margin-bottom: 15px;
  }
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content h2 {
  font-size: 32px;
  margin-bottom: 15px;
  line-height: 1.313;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-content h2 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-content h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-content h2 {
    font-size: 22px;
  }
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content h2 a {
  display: block;
  color: inherit;
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content h2 a:hover {
  color: #0D539F;
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer .post-author {
  display: flex;
  align-items: center;
  padding-right: 30px;
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer .post-author img {
  width: 51px;
  height: 51px;
  border-radius: 50px;
  min-width: 50px;
  margin-right: 17px;
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer .post-author .author-name {
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: rgba(0, 0, 0, 0.588);
  line-height: 1.875;
  flex-grow: 1;
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer .post-author .author-name a {
  color: rgba(0, 0, 0, 0.588);
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer .post-author .author-name a:hover {
  color: #0D539F;
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer a.readmore {
  display: inline-block;
  margin-left: auto;
  font-size: 16px;
  font-family: "Poppins Bold";
  color: #227aeb;
  line-height: 1.625;
  text-transform: capitalize;
}
@media only screen and (max-width: 991.98px) {
  #blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer a.readmore {
    font-size: 14px;
  }
}
#blog-slider-wrapper #blog-slider .blog-item .blog-content .blog-footer a.readmore:hover {
  color: #082140;
}

#featured-articles {
  padding-top: 50px;
}
#featured-articles h2 {
  font-size: 24px;
  color: rgb(0, 0, 0);
  line-height: 1.083;
  margin-bottom: 55px;
}
@media only screen and (max-width: 991.98px) {
  #featured-articles h2 {
    margin-bottom: 30px;
  }
}
#featured-articles .row {
  padding-left: 2px;
  padding-right: 2px;
}
#featured-articles .blog-item {
  margin-bottom: 95px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #featured-articles .blog-item {
    margin-bottom: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-articles .blog-item {
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-articles .blog-item {
    margin-bottom: 50px;
  }
}
#featured-articles .blog-item .blog-photo {
  margin-bottom: 18px;
}
#featured-articles .blog-item .blog-photo img {
  transition: 0.25s ease-in-out;
  display: block;
  border-radius: 12px;
  width: 100%;
  object-fit: cover;
}
#featured-articles .blog-item .blog-photo a {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 12px;
}
#featured-articles .blog-item .blog-photo a:hover img {
  transform: scale(1.1);
}
#featured-articles .blog-item .blog-content {
  padding-right: 15px;
}
@media only screen and (max-width: 991.98px) {
  #featured-articles .blog-item .blog-content {
    padding-right: 0;
  }
}
#featured-articles .blog-item .blog-content span.post-date {
  display: block;
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: rgb(131, 136, 143);
  margin-bottom: 15px;
}
@media only screen and (max-width: 1199.98px) {
  #featured-articles .blog-item .blog-content span.post-date {
    font-size: 15px;
  }
}
#featured-articles .blog-item .blog-content h3 {
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.5;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #featured-articles .blog-item .blog-content h3 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-articles .blog-item .blog-content h3 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-articles .blog-item .blog-content h3 {
    font-size: 17px;
  }
}
#featured-articles .blog-item .blog-content h3 a {
  display: block;
  color: inherit;
}
#featured-articles .blog-item .blog-content h3 a:hover {
  color: #0D539F;
}
#featured-articles .col {
  padding-left: 13px;
  padding-right: 13px;
  min-width: 33.333333%;
}
@media only screen and (max-width: 767.98px) {
  #featured-articles .col {
    min-width: 100%;
  }
}
#featured-articles .col:nth-of-type(1), #featured-articles .col:nth-of-type(2) {
  min-width: 50%;
}
@media only screen and (max-width: 767.98px) {
  #featured-articles .col:nth-of-type(1), #featured-articles .col:nth-of-type(2) {
    min-width: 100%;
  }
}
#featured-articles .col:nth-of-type(1) .blog-content, #featured-articles .col:nth-of-type(2) .blog-content {
  padding-right: 52px;
}
@media only screen and (max-width: 991.98px) {
  #featured-articles .col:nth-of-type(1) .blog-content, #featured-articles .col:nth-of-type(2) .blog-content {
    padding-right: 0px;
  }
}
#featured-articles .col:nth-of-type(1) .blog-photo, #featured-articles .col:nth-of-type(2) .blog-photo {
  margin-bottom: 28px;
}
#featured-articles .col:nth-of-type(1) .blog-photo img, #featured-articles .col:nth-of-type(2) .blog-photo img {
  height: 360px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #featured-articles .col:nth-of-type(1) .blog-photo img, #featured-articles .col:nth-of-type(2) .blog-photo img {
    height: 320px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-articles .col:nth-of-type(1) .blog-photo img, #featured-articles .col:nth-of-type(2) .blog-photo img {
    height: 250px;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-articles .col:nth-of-type(1) .blog-photo img, #featured-articles .col:nth-of-type(2) .blog-photo img {
    height: 220px;
  }
}
#featured-articles .col:nth-of-type(1) h3, #featured-articles .col:nth-of-type(2) h3 {
  font-size: 23px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #featured-articles .col:nth-of-type(1) h3, #featured-articles .col:nth-of-type(2) h3 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-articles .col:nth-of-type(1) h3, #featured-articles .col:nth-of-type(2) h3 {
    font-size: 21px;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-articles .col:nth-of-type(1) h3, #featured-articles .col:nth-of-type(2) h3 {
    font-size: 20px;
  }
}
#featured-articles .col:nth-of-type(3) .blog-photo, #featured-articles .col:nth-of-type(4) .blog-photo, #featured-articles .col:nth-of-type(5) .blog-photo {
  margin-bottom: 28px;
}
#featured-articles .col:nth-of-type(3) .blog-photo img, #featured-articles .col:nth-of-type(4) .blog-photo img, #featured-articles .col:nth-of-type(5) .blog-photo img {
  height: 260px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #featured-articles .col:nth-of-type(3) .blog-photo img, #featured-articles .col:nth-of-type(4) .blog-photo img, #featured-articles .col:nth-of-type(5) .blog-photo img {
    height: 240px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-articles .col:nth-of-type(3) .blog-photo img, #featured-articles .col:nth-of-type(4) .blog-photo img, #featured-articles .col:nth-of-type(5) .blog-photo img {
    height: 200px;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-articles .col:nth-of-type(3) .blog-photo img, #featured-articles .col:nth-of-type(4) .blog-photo img, #featured-articles .col:nth-of-type(5) .blog-photo img {
    height: 220px;
  }
}

#blog-howto {
  background-color: #eff7fe;
  padding-top: 220px;
  padding-bottom: 190px;
}
@media only screen and (min-width: 1025px) and (max-height: 767px) {
  #blog-howto {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #blog-howto {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-howto {
    padding-top: 160px;
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-howto {
    padding-top: 120px;
    padding-bottom: 170px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-howto {
    padding-top: 150px;
    padding-bottom: 30px;
  }
}
#blog-howto header {
  text-align: center;
  padding-bottom: 50px;
  max-width: 700px;
  margin: 0px auto;
}
#blog-howto header h1 {
  font-size: 50px;
  line-height: 1.3;
  font-family: "Poppins Medium";
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-howto header h1 {
    font-size: 42px;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-howto header h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-howto header h1 {
    font-size: 32px;
  }
}
#blog-howto header h1 b {
  font-family: "Poppins Bold";
}
#blog-howto header h1 em {
  position: relative;
  font-style: normal;
}
#blog-howto header h1 em span {
  z-index: 10;
  position: relative;
}
#blog-howto header h1 em:before {
  width: 104%;
  display: block;
  content: "";
  height: 10px;
  background: #FED647;
  position: absolute;
  left: -2%;
  bottom: 13px;
  z-index: 0;
}
#blog-howto header p {
  max-width: 600px;
  margin: 0px auto;
  font-size: 18px;
  line-height: 26px;
  margin-top: 23px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-howto header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-howto header p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-howto header p {
    font-size: 16px;
    line-height: 24px;
  }
}
#blog-howto .col {
  padding-left: 10.5px;
  padding-right: 10.5px;
  min-width: 20%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-howto .col {
    min-width: 50%;
    max-width: 50%;
    margin-bottom: 21px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-howto .col {
    min-width: 50%;
    max-width: 50%;
    margin-bottom: 21px;
  }
}
#blog-howto .blog-howto {
  justify-content: center;
}
#blog-howto .blog-howto-item {
  border-radius: 10px;
  background-color: rgb(246, 248, 254);
  box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 28px;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-height: 195px;
  height: 100%;
  transition: 0.25s ease-in-out;
}
@media only screen and (max-width: 1199.98px) {
  #blog-howto .blog-howto-item {
    padding: 15px;
  }
}
#blog-howto .blog-howto-item .blog-ico {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 55px;
  margin-bottom: 29px;
}
#blog-howto .blog-howto-item h3 {
  font-family: "Poppins Medium";
  font-size: 18px;
  color: rgb(0, 0, 0);
  line-height: 1.333;
  transition: 0.25s ease-in-out;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-howto .blog-howto-item h3 {
    font-size: 17px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-howto .blog-howto-item h3 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-howto .blog-howto-item h3 {
    font-size: 16px;
  }
}
#blog-howto .blog-howto-item a {
  position: absolute;
  font-size: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
#blog-howto .blog-howto-item:hover {
  background: #fff;
  transform: scale(1.05);
}
#blog-howto .blog-howto-item:hover h3 {
  color: #0D539F;
}

#blog-search-area {
  margin-top: -120px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-search-area {
    margin-top: -100px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-search-area {
    margin-top: 0;
    padding-top: 50px;
  }
}
#blog-search-area h3 {
  font-size: 24px;
  margin-bottom: 35px;
}
@media only screen and (min-width: 1025px) and (max-height: 767px) {
  #blog-search-area h3 {
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #blog-search-area h3 {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 991.98px) {
  #blog-search-area h3 {
    margin-bottom: 30px;
  }
}
#blog-search-area h3 b {
  color: #227aeb;
}
#blog-search-area .blog-search-wrapper {
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1199.98px) {
  #blog-search-area .blog-search-wrapper {
    max-width: 660px;
  }
}
#blog-search-area .blog-search-wrapper form {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 54.6px 15.4px rgba(0, 0, 0, 0.06);
  height: 108px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-search-area .blog-search-wrapper form {
    height: 98px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-search-area .blog-search-wrapper form {
    height: auto;
    background-color: transparent;
    box-shadow: none;
  }
}
#blog-search-area .blog-search-wrapper input[type=text], #blog-search-area .blog-search-wrapper input[type=search] {
  display: block;
  width: 100%;
  height: 108px;
  border: 0;
  background-color: #fff;
  border-radius: 12px;
  padding-left: 111px;
  padding-right: 245px;
  font-size: 22px;
  font-family: "Source Sans Pro";
  line-height: 1.091;
  color: #181818;
}
#blog-search-area .blog-search-wrapper input[type=text].placeholder, #blog-search-area .blog-search-wrapper input[type=search].placeholder {
  color: rgb(190, 198, 199);
}
#blog-search-area .blog-search-wrapper input[type=text]:-moz-placeholder, #blog-search-area .blog-search-wrapper input[type=search]:-moz-placeholder {
  color: rgb(190, 198, 199);
}
#blog-search-area .blog-search-wrapper input[type=text]::-moz-placeholder, #blog-search-area .blog-search-wrapper input[type=search]::-moz-placeholder {
  color: rgb(190, 198, 199);
}
#blog-search-area .blog-search-wrapper input[type=text]:-ms-input-placeholder, #blog-search-area .blog-search-wrapper input[type=search]:-ms-input-placeholder {
  color: rgb(190, 198, 199);
}
#blog-search-area .blog-search-wrapper input[type=text]::-webkit-input-placeholder, #blog-search-area .blog-search-wrapper input[type=search]::-webkit-input-placeholder {
  color: rgb(190, 198, 199);
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-search-area .blog-search-wrapper input[type=text], #blog-search-area .blog-search-wrapper input[type=search] {
    height: 98px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-search-area .blog-search-wrapper input[type=text], #blog-search-area .blog-search-wrapper input[type=search] {
    height: 78px;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 0px 54.6px 15.4px rgba(0, 0, 0, 0.06);
  }
}
#blog-search-area .blog-search-wrapper .icon-search {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 24px;
  color: #1a6df9;
  left: 46px;
}
@media only screen and (max-width: 767.98px) {
  #blog-search-area .blog-search-wrapper .icon-search {
    display: none;
  }
}
#blog-search-area .blog-search-wrapper input[type=submit] {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
  text-transform: uppercase;
  border: 0;
  padding-top: 0px;
  padding-bottom: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-search-area .blog-search-wrapper input[type=submit] {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-search-area .blog-search-wrapper input[type=submit] {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#blog-search-area .blog-search-wrapper input[type=submit]:hover {
  background: #082140;
  color: #fff;
}
@media (min-width: 768px) {
  #blog-search-area .blog-search-wrapper input[type=submit] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 22px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-search-area .blog-search-wrapper input[type=submit] {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

#latest-posts {
  padding-bottom: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #latest-posts {
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #latest-posts {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #latest-posts {
    padding-bottom: 20px;
  }
}
#latest-posts h2 {
  font-size: 24px;
  color: rgb(0, 0, 0);
  line-height: 1.083;
  margin-bottom: 55px;
}
@media only screen and (max-width: 991.98px) {
  #latest-posts h2 {
    margin-bottom: 30px;
  }
}
#latest-posts .row {
  padding-left: 2px;
  padding-right: 2px;
}
#latest-posts .col {
  padding-left: 13px;
  padding-right: 13px;
  min-width: 33.333333%;
}
@media only screen and (max-width: 767.98px) {
  #latest-posts .col {
    min-width: 100%;
  }
}
#latest-posts .blog-item {
  margin-bottom: 65px;
}
#latest-posts .blog-item .blog-photo {
  margin-bottom: 18px;
}
#latest-posts .blog-item .blog-photo img {
  transition: 0.25s ease-in-out;
  display: block;
  border-radius: 12px;
  width: 100%;
  height: 285px;
  object-fit: cover;
}
#latest-posts .blog-item .blog-photo a {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 12px;
}
#latest-posts .blog-item .blog-photo a:hover img {
  transform: scale(1.1);
}
#latest-posts .blog-item .blog-content {
  padding-right: 15px;
}
@media only screen and (max-width: 991.98px) {
  #latest-posts .blog-item .blog-content {
    padding-right: 0;
  }
}
#latest-posts .blog-item .blog-content span.post-date {
  display: block;
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: rgb(131, 136, 143);
  margin-bottom: 15px;
}
@media only screen and (max-width: 1199.98px) {
  #latest-posts .blog-item .blog-content span.post-date {
    font-size: 15px;
  }
}
#latest-posts .blog-item .blog-content h3 {
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.5;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #latest-posts .blog-item .blog-content h3 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #latest-posts .blog-item .blog-content h3 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767.98px) {
  #latest-posts .blog-item .blog-content h3 {
    font-size: 17px;
  }
}
#latest-posts .blog-item .blog-content h3 a {
  display: block;
  color: inherit;
}
#latest-posts .blog-item .blog-content h3 a:hover {
  color: #0D539F;
}

#ctis-loading {
  text-align: center;
  width: 100%;
}

#blog-single-header {
  padding-top: 240px;
  padding-bottom: 70px;
  position: relative;
  background-color: #eff7fe;
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #blog-single-header {
    padding-top: 250px;
    padding-bottom: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-single-header {
    padding-top: 190px;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-single-header {
    padding-top: 130px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-header {
    padding-top: 150px;
    padding-bottom: 50px;
  }
}
#blog-single-header .container {
  position: relative;
  z-index: 2;
}
#blog-single-header .blog-category {
  font-family: "Source Sans Pro Semi";
  display: block;
  text-transform: uppercase;
  color: #227aeb;
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 25px;
}
#blog-single-header .blog-category a {
  color: inherit;
}
#blog-single-header .blog-category a:hover {
  color: #0D539F;
}
#blog-single-header h1 {
  font-size: 58px;
  line-height: 1.17;
  margin-bottom: 55px;
  max-width: 880px;
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #blog-single-header h1 {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-single-header h1 {
    font-size: 45px;
    margin-bottom: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-single-header h1 {
    font-size: 40px;
    margin-bottom: 55px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-header h1 {
    font-size: 32px;
    margin-bottom: 45px;
  }
}
#blog-single-header .author-info {
  display: flex;
  align-items: center;
  margin-bottom: 55px;
}
@media only screen and (max-width: 1199.98px) {
  #blog-single-header .author-info {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-header .author-info {
    flex-direction: column;
    text-align: center;
    margin-bottom: 0;
  }
}
#blog-single-header .author-info img {
  border-radius: 50%;
  width: 48px;
  margin-right: 17px;
}
@media only screen and (max-width: 767.98px) {
  #blog-single-header .author-info img {
    margin-bottom: 15px;
    margin-right: 0;
  }
}
#blog-single-header .author-info .author-content {
  flex-grow: 1;
}
#blog-single-header .author-info .author-content .author-name {
  display: block;
  font-family: "Source Sans Pro Semi";
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  margin-bottom: 5px;
}
#blog-single-header .author-info .author-content .author-name a {
  color: #000;
}
#blog-single-header .author-info .author-content .author-name a:hover {
  color: #227aeb;
}
#blog-single-header .author-info .author-content .date-time span {
  display: inline-block;
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.502);
}
#blog-single-header .author-info .author-content .date-time span.post-date {
  margin-right: 15px;
}
#blog-single-header .author-info .author-content .date-time span.sep {
  margin-right: 15px;
}

#blog-single-body {
  z-index: 3;
  margin-top: 50px;
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body {
    margin-top: 30px;
  }
}
#blog-single-body .blog-single-body-in {
  background-color: #fff;
  position: relative;
  z-index: 3;
  border-radius: 12px;
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #blog-single-body .blog-single-body-in {
    padding-bottom: 140px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in {
    padding: 40px 0px 60px 0px;
  }
}
#blog-single-body .blog-single-body-in .blog-left-col {
  padding-right: 0px;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-left-col {
    padding-right: 15px;
  }
}
#blog-single-body .blog-single-body-in .blog-content {
  padding-right: 55px;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-content {
    padding-right: 0px;
  }
}
#blog-single-body .blog-single-body-in .blog-content p {
  font-size: 19px;
  color: rgb(6, 6, 6);
  line-height: 1.684;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-content p {
    font-size: 18px;
  }
}
#blog-single-body .blog-single-body-in .blog-content p a {
  color: #0D539F;
  font-family: "Source Sans Pro Semi";
}
#blog-single-body .blog-single-body-in .blog-content p a:hover {
  text-decoration: underline;
  color: #082140;
}
#blog-single-body .blog-single-body-in .blog-content h2 {
  margin-bottom: 30px;
  line-height: 1.3;
  font-size: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-single-body .blog-single-body-in .blog-content h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-content h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-content h2 {
    margin-bottom: 20px;
  }
}
#blog-single-body .blog-single-body-in .blog-content h3 {
  margin-bottom: 20px;
  margin-top: 50px;
  line-height: 1.45;
  font-size: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-single-body .blog-single-body-in .blog-content h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-content h3 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-content h3 {
    margin-bottom: 15px;
  }
}
#blog-single-body .blog-single-body-in .blog-content h4 {
  margin-bottom: 20px;
  font-family: "Poppins Medium";
  line-height: 1.3;
  font-size: 22px;
}
#blog-single-body .blog-single-body-in .blog-content ul {
  margin-bottom: 30px;
}
#blog-single-body .blog-single-body-in .blog-content ul li {
  display: block;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
  font-size: 18px;
}
#blog-single-body .blog-single-body-in .blog-content ul li a {
  color: #0D539F;
  font-family: "Poppins Medium";
}
#blog-single-body .blog-single-body-in .blog-content ul li a:hover {
  text-decoration: underline;
}
#blog-single-body .blog-single-body-in .blog-content ul li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #227aeb;
  position: absolute;
  left: 0px;
  top: 10px;
}
#blog-single-body .blog-single-body-in .blog-content ul ul {
  margin-top: 15px;
  margin-bottom: 15px;
}
#blog-single-body .blog-single-body-in .blog-content ul ul li {
  font-size: 16px;
}
#blog-single-body .blog-single-body-in .blog-content .blog-intro h2 {
  margin-top: 0 !important;
}
#blog-single-body .blog-single-body-in .blog-content .blog-intro p {
  font-size: 19px;
  color: rgb(6, 6, 6);
  line-height: 1.684;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-content .blog-intro p {
    font-size: 18px;
  }
}
#blog-single-body .blog-single-body-in .blog-content .blog-intro p a {
  color: #0D539F;
}
#blog-single-body .blog-single-body-in .blog-content .blog-intro p a:hover {
  text-decoration: underline;
  color: #082140;
}
#blog-single-body .blog-single-body-in .blog-content .blog-photo {
  margin-top: 55px;
  margin-bottom: 55px;
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-content .blog-photo {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
#blog-single-body .blog-single-body-in .blog-content .blog-photo img {
  width: 100%;
  display: block;
}
#blog-single-body .blog-single-body-in .blog-content h2 {
  font-size: 30px;
  line-height: 1.5;
  color: #060606;
  margin-top: 55px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-single-body .blog-single-body-in .blog-content h2 {
    font-size: 27px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-content h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-content h2 {
    font-size: 25px;
    margin-top: 45px;
    margin-bottom: 25px;
  }
}
#blog-single-body .blog-single-body-in .blog-cta {
  margin-top: 60px;
  border-radius: 12px;
  background-color: #eff7fe;
  padding: 65px 40px 55px 40px;
  width: 95%;
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-cta {
    padding: 30px 20px;
    width: 100%;
  }
}
#blog-single-body .blog-single-body-in .blog-cta-caption {
  max-width: 450px;
  margin-bottom: 30px;
}
#blog-single-body .blog-single-body-in .blog-cta-caption p {
  font-family: "Source Sans Pro Semi";
  font-size: 20px;
  line-height: 1.2;
  color: #000;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-cta-caption p {
    font-size: 1.1em;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-cta-caption p {
    text-align: center;
  }
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form {
  max-width: 578px;
  margin-bottom: 35px;
  position: relative;
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email], #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text] {
  display: block;
  width: 100%;
  height: 75px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 54.6px 15.4px rgba(0, 0, 0, 0.06);
  padding: 0 165px 0 34px;
  font-size: 18px;
  font-family: "Source Sans Pro";
  line-height: 1.333;
  color: #060606;
  border: 0;
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email].placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text].placeholder {
  color: #bec6c7;
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email]:-moz-placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text]:-moz-placeholder {
  color: #bec6c7;
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email]::-moz-placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text]::-moz-placeholder {
  color: #bec6c7;
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email]:-ms-input-placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text]:-ms-input-placeholder {
  color: #bec6c7;
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email]::-webkit-input-placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text]::-webkit-input-placeholder {
  color: #bec6c7;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email], #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text] {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email], #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text] {
    height: 60px;
    padding: 0 20px;
    font-size: 1em;
  }
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email].placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text].placeholder {
  color: rgb(190, 198, 199);
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email]:-moz-placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text]:-moz-placeholder {
  color: rgb(190, 198, 199);
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email]::-moz-placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text]::-moz-placeholder {
  color: rgb(190, 198, 199);
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email]:-ms-input-placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text]:-ms-input-placeholder {
  color: rgb(190, 198, 199);
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=email]::-webkit-input-placeholder, #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=text]::-webkit-input-placeholder {
  color: rgb(190, 198, 199);
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=submit] {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FED647;
  border: 0;
  right: 15px;
  border-radius: 12px;
  width: 133px;
  height: 46px;
  transition: 0.25s ease-in-out;
  font-size: 12px;
  font-family: "Poppins Bold";
  color: #000;
  line-height: 1.333;
}
@media (min-width: 768px) {
  #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=submit] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=submit] {
    margin-top: 15px;
    width: 100%;
  }
}
#blog-single-body .blog-single-body-in .blog-cta .subscribe-form input[type=submit]:hover {
  background-color: #082140;
  cursor: pointer;
  color: #fff;
}
#blog-single-body .blog-single-body-in .blog-cta-share {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-cta-share {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
#blog-single-body .blog-single-body-in .blog-cta-share p {
  font-family: "Source Sans Pro Semi";
  font-size: 18px;
  line-height: 1.2;
  margin-right: 45px;
  color: #000;
  margin-bottom: 0px;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-cta-share p {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-cta-share p {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
#blog-single-body .blog-single-body-in .blog-cta-share ul li {
  display: inline-block;
  margin-right: 23px;
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-cta-share ul li {
    margin-left: 11px;
    margin-right: 11px;
  }
}
#blog-single-body .blog-single-body-in .blog-cta-share ul li a {
  color: #000;
  font-size: 22px;
}
#blog-single-body .blog-single-body-in .blog-cta-share ul li a:hover {
  color: #227aeb;
}
#blog-single-body .blog-single-body-in .blog-sidebar-col {
  padding-left: 0px;
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-sidebar-col {
    padding-left: 15px;
    order: -1;
  }
}
#blog-single-body .blog-single-body-in .blog-aside {
  max-width: 370px;
  margin-left: auto;
  position: sticky;
  top: 110px;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-aside {
    max-width: 100%;
    margin-top: 65px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-aside {
    margin-top: 0px;
  }
}
#blog-single-body .blog-single-body-in .blog-aside .side-image {
  margin-bottom: 30px;
}
#blog-single-body .blog-single-body-in .blog-aside .side-image img {
  border-radius: 9px;
  width: 100%;
  height: 220px;
  object-fit: cover;
}
@media only screen and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-aside .side-image img {
    height: auto;
  }
}
#blog-single-body .blog-single-body-in .blog-aside .aside-title {
  display: block;
  font-family: "Source Sans Pro Semi";
  font-size: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #060606;
}
#blog-single-body .blog-single-body-in .blog-aside .share-area .aside-title {
  margin-bottom: 34px;
}
#blog-single-body .blog-single-body-in .blog-aside .share-area ul li {
  display: inline-block;
  margin-right: 23px;
}
#blog-single-body .blog-single-body-in .blog-aside .share-area ul li a {
  color: #000;
  font-size: 22px;
}
#blog-single-body .blog-single-body-in .blog-aside .share-area ul li a:hover {
  color: #227aeb;
}
#blog-single-body .blog-single-body-in .blog-aside .archive-filter {
  margin-bottom: 65px;
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-aside .archive-filter {
    margin-bottom: 45px;
  }
}
#blog-single-body .blog-single-body-in .blog-aside .archive-filter .aside-title {
  margin-bottom: 15px;
}
#blog-single-body .blog-single-body-in .blog-aside .archive-filter ul li a {
  font-size: 16px;
  font-family: "Poppins Bold";
  color: rgba(75, 75, 75, 0.878);
  font-weight: bold;
  line-height: 1.556;
  padding-top: 19px;
  padding-bottom: 19px;
  border-bottom: 1px solid rgba(75, 75, 75, 0.11);
  display: block;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-single-body .blog-single-body-in .blog-aside .archive-filter ul li a {
    font-size: 17px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-single-body .blog-single-body-in .blog-aside .archive-filter ul li a {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-single-body .blog-single-body-in .blog-aside .archive-filter ul li a {
    font-size: 16px;
  }
}
#blog-single-body .blog-single-body-in .blog-aside .archive-filter ul li a:hover {
  color: #227aeb;
}
#blog-single-body .blog-single-body-in .blog-aside .archive-filter ul li.active a {
  color: #227aeb;
}
#blog-single-body .blog-single-body-in .blog-aside .category-filter ul li a {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  border: none;
  font-family: "Poppins Medium";
  opacity: 0.5;
}
#blog-single-body .blog-single-body-in .blog-aside .category-filter ul li a:hover {
  opacity: 1;
}

#related-posts {
  position: relative;
  padding-bottom: 171px;
  margin-top: 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  #related-posts {
    padding-bottom: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #related-posts {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #related-posts {
    padding-bottom: 90px;
  }
}
@media only screen and (max-width: 767.98px) {
  #related-posts {
    padding-bottom: 40px;
    margin-top: 50px;
  }
}
#related-posts .container {
  position: relative;
  z-index: 1;
}
#related-posts h2 {
  font-size: 21px;
  color: rgb(0, 0, 0);
  line-height: 1.083;
  margin-bottom: 35px;
}
@media only screen and (max-width: 991.98px) {
  #related-posts h2 {
    margin-bottom: 30px;
  }
}
#related-posts .row {
  padding-left: 2px;
  padding-right: 2px;
}
#related-posts .col {
  padding-left: 13px;
  padding-right: 13px;
  min-width: 33.333333%;
}
@media only screen and (max-width: 767.98px) {
  #related-posts .col {
    min-width: 100%;
  }
}
@media only screen and (max-width: 767.98px) {
  #related-posts .blog-item {
    margin-bottom: 35px;
  }
}
#related-posts .blog-item .blog-photo {
  margin-bottom: 18px;
}
#related-posts .blog-item .blog-photo img {
  transition: 0.25s ease-in-out;
  display: block;
  border-radius: 12px;
  width: 100%;
}
#related-posts .blog-item .blog-photo a {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 12px;
}
#related-posts .blog-item .blog-photo a:hover img {
  transform: scale(1.1);
}
#related-posts .blog-item .blog-content {
  padding-right: 15px;
}
@media only screen and (max-width: 991.98px) {
  #related-posts .blog-item .blog-content {
    padding-right: 0;
  }
}
#related-posts .blog-item .blog-content span.post-date {
  display: block;
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: rgb(131, 136, 143);
  margin-bottom: 15px;
}
@media only screen and (max-width: 1199.98px) {
  #related-posts .blog-item .blog-content span.post-date {
    font-size: 15px;
  }
}
#related-posts .blog-item .blog-content h3 {
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.5;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #related-posts .blog-item .blog-content h3 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #related-posts .blog-item .blog-content h3 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767.98px) {
  #related-posts .blog-item .blog-content h3 {
    font-size: 17px;
  }
}
#related-posts .blog-item .blog-content h3 a {
  display: block;
  color: inherit;
}
#related-posts .blog-item .blog-content h3 a:hover {
  color: #0D539F;
}

#blog-slider .slick-prev {
  right: calc(45.8% + 50px);
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-slider .slick-prev {
    right: calc(50% + 50px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider .slick-prev {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    right: auto;
    left: calc(50% - 20px);
  }
}
#blog-slider .slick-next {
  right: 45.8%;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #blog-slider .slick-next {
    right: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider .slick-next {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    left: calc(50% + 20px);
  }
}
#blog-slider .slick-prev:before, #blog-slider .slick-next:before {
  font-family: "icomoon";
  font-size: 26px;
  line-height: 1;
  opacity: 1;
  color: #227aeb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.25s ease-in-out;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider .slick-prev:before, #blog-slider .slick-next:before {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #blog-slider .slick-prev:before, #blog-slider .slick-next:before {
    font-size: 26px;
  }
}
#blog-slider .slick-prev:before {
  content: "\e90d";
}
#blog-slider .slick-next:before {
  content: "\e90c";
}
#blog-slider .slick-prev, #blog-slider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: -6px;
  display: block;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #blog-slider .slick-prev, #blog-slider .slick-next {
    bottom: -10px;
  }
}
#blog-slider .slick-prev:hover:before, #blog-slider .slick-next:hover:before {
  opacity: 1;
  color: #0D539F;
}

#infinite-handle, #ctis-loading {
  text-align: center;
  width: 100%;
}
#infinite-handle .ctis-load-more, #ctis-loading .ctis-load-more {
  text-align: center;
}
#infinite-handle .ctis-load-more button, #ctis-loading .ctis-load-more button {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #fff;
  line-height: 56px;
  font-size: 18px !important;
  font-family: "Source Sans Pro Semi" !important;
  color: #0D539F;
  text-align: center;
  border: 2px solid #0D539F;
  transition: 0.25s ease-in-out;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #infinite-handle .ctis-load-more button, #ctis-loading .ctis-load-more button {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #infinite-handle .ctis-load-more button, #ctis-loading .ctis-load-more button {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#infinite-handle .ctis-load-more button:hover, #infinite-handle .ctis-load-more button:focus, #ctis-loading .ctis-load-more button:hover, #ctis-loading .ctis-load-more button:focus {
  background: #082140;
  color: #fff;
}

#latest-posts.latest-posts--inner {
  padding-top: 100px;
}
@media only screen and (max-width: 767.98px) {
  #latest-posts.latest-posts--inner {
    padding-top: 50px;
  }
}

#search-no--results {
  padding-top: 80px;
  padding-bottom: 100px;
}
#search-no--results .container {
  max-width: 860px;
  text-align: center;
}
#search-no--results img {
  max-width: 650px;
  margin: 0px auto;
}
#search-no--results #no-posts h2 {
  margin-bottom: 30px;
  font-size: 33px;
  line-height: 43px;
  margin-bottom: 16px;
  text-transform: uppercase;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #search-no--results #no-posts h2 {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #search-no--results #no-posts h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767.98px) {
  #search-no--results #no-posts h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
#search-no--results #no-posts p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #search-no--results #no-posts p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #search-no--results #no-posts p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #search-no--results #no-posts p {
    font-size: 16px;
    line-height: 24px;
  }
}

.author #blog-howto header {
  max-width: 100% !important;
  padding-bottom: 10px !important;
}

.author-image--single img {
  border-radius: 100%;
  margin-top: 20px;
  border: 3px solid #fff;
}

#featured-podcast {
  margin-top: 80px;
  margin-bottom: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #featured-podcast {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-podcast {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-podcast {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
#featured-podcast .container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767.98px) {
  #featured-podcast .container {
    display: block;
  }
}
#featured-podcast .container .podcast-desc {
  width: 50%;
  padding-top: 30px;
}
@media only screen and (max-width: 767.98px) {
  #featured-podcast .container .podcast-desc {
    width: 100%;
    padding-top: 0px;
  }
}
#featured-podcast .container .podcast-desc .podcast-date small {
  display: block;
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: rgb(131, 136, 143);
  margin-bottom: 15px;
}
@media only screen and (max-width: 1199.98px) {
  #featured-podcast .container .podcast-desc .podcast-date small {
    font-size: 15px;
  }
}
#featured-podcast .container .podcast-desc h2 {
  font-size: 32px;
  margin-bottom: 15px;
  line-height: 1.313;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #featured-podcast .container .podcast-desc h2 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-podcast .container .podcast-desc h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-podcast .container .podcast-desc h2 {
    font-size: 22px;
  }
}
#featured-podcast .container .podcast-desc h2 a {
  display: block;
  color: inherit;
}
#featured-podcast .container .podcast-desc h2 a:hover {
  color: #0D539F;
}
#featured-podcast .container .podcast-desc p {
  margin-bottom: 35px;
}
#featured-podcast .container .podcast-desc a.btn-more {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-podcast .container .podcast-desc a.btn-more {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-podcast .container .podcast-desc a.btn-more {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#featured-podcast .container .podcast-desc a.btn-more:hover {
  background: #082140;
  color: #fff;
}
#featured-podcast .container .podcast-desc .author-info {
  display: flex;
  align-items: center;
}
#featured-podcast .container .podcast-desc .author-info img {
  width: 51px;
  height: 51px;
  border-radius: 50px;
  min-width: 50px;
  margin-right: 17px;
}
#featured-podcast .container .podcast-desc .author-info .author-name {
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: rgba(0, 0, 0, 0.588);
  line-height: 1.875;
  flex-grow: 1;
}
#featured-podcast .container .podcast-desc .author-info .author-name a {
  color: rgba(0, 0, 0, 0.588);
}
#featured-podcast .container .podcast-desc .author-info .author-name a:hover {
  color: #0D539F;
}
#featured-podcast .container .podcast-image {
  width: 40%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #featured-podcast .container .podcast-image {
    width: 50%;
  }
}
@media only screen and (max-width: 767.98px) {
  #featured-podcast .container .podcast-image {
    width: 100%;
  }
}
#featured-podcast .container .podcast-image .image-holder img {
  width: 100%;
  border-radius: 12px;
}
#featured-podcast .container .podcast-image .podcast-socials {
  text-align: center;
  padding-top: 30px;
}
#featured-podcast .container .podcast-image .podcast-socials ul li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
#featured-podcast .container .podcast-image .podcast-socials ul li a {
  font-size: 20px;
  color: #082140;
}
#featured-podcast .container .podcast-image .podcast-socials ul li a:hover {
  color: #0D539F;
}
#featured-podcast .container .podcast-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#podcast-list {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #eff7fe;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-list {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-list {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#podcast-list header h3 {
  font-size: 24px;
  color: rgb(0, 0, 0);
  line-height: 1.083;
  margin-bottom: 55px;
}
@media only screen and (max-width: 991.98px) {
  #podcast-list header h3 {
    margin-bottom: 30px;
  }
}
#podcast-list .podcast-list .podcast-card {
  background: #fff;
  border-radius: 12px;
  margin-bottom: 30px;
  display: flex;
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card {
    display: block;
  }
}
#podcast-list .podcast-list .podcast-card .podcast-image {
  width: 400px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-image {
    width: 300px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-image {
    width: 100%;
  }
}
#podcast-list .podcast-list .podcast-card .podcast-image img {
  width: 100%;
  object-fit: cover;
  height: 350px;
  border-radius: 12px;
  display: block;
}
#podcast-list .podcast-list .podcast-card .podcast-desc {
  flex: 1;
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc {
    padding: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc {
    padding: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
#podcast-list .podcast-list .podcast-card .podcast-desc .podcast-meta span {
  display: inline-block;
  font-size: 16px;
  font-family: "Source Sans Pro Semi";
  color: rgb(131, 136, 143);
  margin-bottom: 15px;
  margin-right: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc .podcast-meta span {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 1199.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc .podcast-meta span {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc .podcast-meta span {
    font-size: 14px;
    margin-right: 15px;
  }
}
#podcast-list .podcast-list .podcast-card .podcast-desc h3 {
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.5;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc h3 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc h3 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc h3 {
    font-size: 17px;
  }
}
#podcast-list .podcast-list .podcast-card .podcast-desc h3 a {
  display: block;
  color: inherit;
}
#podcast-list .podcast-list .podcast-card .podcast-desc h3 a:hover {
  color: #0D539F;
}
#podcast-list .podcast-list .podcast-card .podcast-desc p {
  margin-bottom: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc p {
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc p {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc p {
    margin-bottom: 25px;
  }
}
#podcast-list .podcast-list .podcast-card .podcast-desc a.btn-more {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc a.btn-more {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc a.btn-more {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
#podcast-list .podcast-list .podcast-card .podcast-desc a.btn-more:hover {
  background: #082140;
  color: #fff;
}
#podcast-list .podcast-list .podcast-card .podcast-desc footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#podcast-list .podcast-list .podcast-card .podcast-desc footer .podcast-icons ul li {
  display: inline-block;
  margin-left: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc footer .podcast-icons ul li {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc footer .podcast-icons ul li {
    margin-left: 3px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc footer .podcast-icons ul li {
    margin-left: 5px;
  }
}
#podcast-list .podcast-list .podcast-card .podcast-desc footer .podcast-icons ul li a img {
  width: 22px;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc footer .podcast-icons ul li a img {
    width: 16px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-list .podcast-list .podcast-card .podcast-desc footer .podcast-icons ul li a img {
    width: 14px;
  }
}

#podcast-main {
  padding-top: 80px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 767.98px) {
  #podcast-main {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#podcast-main #faq h3 {
  font-size: 17px;
  color: #000;
  line-height: 1.333;
  position: relative;
  padding: 24px 60px 24px 30px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  border: 0;
  margin: 0;
  border-radius: 8px;
  border: 2px solid #bddbf5;
  position: relative;
  color: #082140;
}
@media only screen and (max-width: 767.98px) {
  #podcast-main #faq h3 {
    font-size: 16px;
    padding: 15px;
    padding-right: 40px;
  }
}
#podcast-main #faq h3:hover {
  cursor: pointer;
}
#podcast-main #faq h3:focus {
  outline: none !important;
}
#podcast-main #faq h3:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 30px;
  content: url("../img/ico/chevron-down-regular.svg");
  width: 20px;
}
@media only screen and (max-width: 767.98px) {
  #podcast-main #faq h3:after {
    width: 12px;
  }
}
#podcast-main #faq h3.active-header {
  border-color: #0D539F;
  background: #0D539F;
  color: #fff;
}
#podcast-main #faq h3.active-header:after {
  transform: translateY(-50%) rotate(180deg);
  color: #fff !important;
  filter: brightness(0) invert(1);
}
#podcast-main #faq .content {
  border: 0;
  padding: 35px 55px 35px 30px;
  padding-bottom: 15px;
  display: none;
  background: #fff;
  border-radius: 12px;
  margin-top: 10px;
  padding-bottom: 30px;
  box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.04);
}
@media only screen and (max-width: 767.98px) {
  #podcast-main #faq .content {
    padding: 20px;
  }
}
#podcast-main #faq .content p {
  margin-bottom: 30px;
}
#podcast-main #faq .content p strong {
  font-weight: normal;
  font-family: "Poppins Medium";
}
#podcast-main #faq .content p a {
  color: #0D539F;
  font-family: "Poppins Medium";
}
#podcast-main #faq .content p a:hover {
  color: #000;
  text-decoration: underline;
}
#podcast-main .faq__accordion {
  max-width: 875px;
  margin-left: auto;
  margin-right: auto;
}
#podcast-main .faq__accordion .faq-wrap {
  margin-bottom: 10px;
}
#podcast-main .podcast-content {
  margin-top: 50px;
}
@media only screen and (max-width: 767.98px) {
  #podcast-main .podcast-content {
    margin-top: 30px;
  }
}
#podcast-main .podcast-content p {
  font-size: 19px;
  color: rgb(6, 6, 6);
  line-height: 1.684;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991.98px) {
  #podcast-main .podcast-content p {
    font-size: 18px;
  }
}
#podcast-main .podcast-content p a {
  color: #0D539F;
  font-family: "Source Sans Pro Semi";
}
#podcast-main .podcast-content p a:hover {
  text-decoration: underline;
  color: #082140;
}
#podcast-main .podcast-content h2 {
  margin-bottom: 30px;
  line-height: 1.3;
  font-size: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-main .podcast-content h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-main .podcast-content h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-main .podcast-content h2 {
    margin-bottom: 20px;
  }
}
#podcast-main .podcast-content h3 {
  margin-bottom: 20px;
  margin-top: 50px;
  line-height: 1.45;
  font-size: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-main .podcast-content h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-main .podcast-content h3 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  #podcast-main .podcast-content h3 {
    margin-bottom: 15px;
  }
}
#podcast-main .podcast-content h4 {
  margin-bottom: 20px;
  font-family: "Poppins Medium";
  line-height: 1.3;
  font-size: 22px;
}
#podcast-main .podcast-content ul {
  margin-bottom: 30px;
}
#podcast-main .podcast-content ul li {
  display: block;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
  font-size: 18px;
}
#podcast-main .podcast-content ul li a {
  color: #0D539F;
  font-family: "Poppins Medium";
}
#podcast-main .podcast-content ul li a:hover {
  text-decoration: underline;
}
#podcast-main .podcast-content ul li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #227aeb;
  position: absolute;
  left: 0px;
  top: 10px;
}
#podcast-main .podcast-content ul ul {
  margin-top: 15px;
  margin-bottom: 15px;
}
#podcast-main .podcast-content ul ul li {
  font-size: 16px;
}
#podcast-main .podcast-sidebar {
  background: #eff7fe;
  border-radius: 12px;
  padding: 30px;
  text-align: center;
  position: sticky;
  top: 110px;
}
#podcast-main .podcast-sidebar .podcast-links li {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-main .podcast-sidebar .podcast-links li {
    margin-left: 5px;
    margin-right: 5px;
  }
}
#podcast-main .podcast-sidebar .podcast-links li img {
  width: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-main .podcast-sidebar .podcast-links li img {
    width: 22px;
  }
}
#podcast-main .podcast-sidebar .podcast-links span {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
  color: #000;
  font-family: "Poppins Medium";
}
#podcast-main .podcast-sidebar .form-wrapper {
  margin-top: 40px;
}
#podcast-main .podcast-sidebar .form-wrapper input[type=email] {
  width: 100%;
  height: 58px;
  line-height: 58px;
  padding-left: 25px;
  border-radius: 12px;
  border: none;
  background: #CEE7FD;
  background: #fff;
  font-size: 16px;
  border: 1px solid #ddd;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #podcast-main .podcast-sidebar .form-wrapper input[type=email] {
    height: 50px;
    line-height: 50px;
    padding-left: 15px;
  }
}
#podcast-main .podcast-sidebar .form-wrapper input[type=submit] {
  width: 100%;
  height: 58px;
  line-height: 58px;
  border-radius: 12px;
  color: #000;
  background-color: #FED647;
  margin-top: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  font-family: "Poppins Medium";
}
#podcast-main .podcast-sidebar .form-wrapper input[type=submit]:hover {
  background-color: #082140;
  color: #fff;
}

#podcast-header .podcast-list .podcast-wrapper {
  margin-bottom: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #podcast-header .podcast-list .podcast-wrapper {
    margin-bottom: 80px;
  }
}

#blog-search-area.podcast-search h3 {
  color: #fff;
}
@media only screen and (max-width: 767.98px) {
  #blog-search-area.podcast-search h3 {
    color: #0D539F;
  }
}
#blog-search-area.podcast-search h3 b {
  color: #CEE7FD;
}
@media only screen and (max-width: 767.98px) {
  #blog-search-area.podcast-search h3 b {
    color: #227aeb;
  }
}

@media only screen and (max-width: 767.98px) {
  #featured-podcast .container .podcast-image {
    margin-top: 30px;
  }
}

#podcast-list.white-bg {
  background: #fff;
}

button {
  background: #fff;
}

#jobs-list-page {
  padding-top: 80px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 991.98px) {
  #jobs-list-page {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
#jobs-list-page .regular-content p {
  font-size: 19px;
  color: rgb(6, 6, 6);
  line-height: 1.684;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991.98px) {
  #jobs-list-page .regular-content p {
    font-size: 18px;
  }
}
#jobs-list-page .regular-content p a {
  color: #0D539F;
  font-family: "Source Sans Pro Semi";
}
#jobs-list-page .regular-content p a:hover {
  text-decoration: underline;
  color: #082140;
}
#jobs-list-page .regular-content h2, #jobs-list-page .regular-content h1 {
  margin-bottom: 30px;
  line-height: 1.3;
  font-size: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #jobs-list-page .regular-content h2, #jobs-list-page .regular-content h1 {
    margin-bottom: 20px;
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #jobs-list-page .regular-content h2, #jobs-list-page .regular-content h1 {
    margin-bottom: 20px;
    font-size: 26px;
  }
}
@media only screen and (max-width: 767.98px) {
  #jobs-list-page .regular-content h2, #jobs-list-page .regular-content h1 {
    margin-bottom: 20px;
    font-size: 24px;
  }
}
#jobs-list-page .regular-content h1 {
  font-size: 35px;
}
#jobs-list-page .regular-content ul {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  border: none !important;
  justify-content: start;
}
#jobs-list-page .regular-content ul > li.job_listing {
  background: #fff;
  border-radius: 12px;
  padding: 15px;
  transition: 0.25s ease-in-out;
  margin-bottom: 30px;
  width: 31%;
  padding: 0px;
  margin: 0px;
  border: none;
  margin-bottom: 30px !important;
  position: relative;
  padding-bottom: 50px;
  border: 1px solid #ddd;
  margin-left: 1%;
  margin-right: 1%;
}
@media only screen and (max-width: 767.98px) {
  #jobs-list-page .regular-content ul > li.job_listing {
    width: 100%;
  }
}
#jobs-list-page .regular-content ul > li.job_listing:hover {
  box-shadow: rgba(0, 0, 0, 0.0005) 0px 5px 20px 0px;
  transform: scale(1.05);
  border-color: #227aeb;
}
#jobs-list-page .regular-content ul > li.job_listing:hover h3 {
  color: #227aeb;
}
#jobs-list-page .regular-content ul > li.job_listing a {
  padding-top: 25px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: unset;
  overflow: unset;
  background-color: transparent;
}
#jobs-list-page .regular-content ul > li.job_listing .position {
  float: none;
  padding: 0px;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  font-family: "Poppins Medium";
}
#jobs-list-page .regular-content ul > li.job_listing h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #0D539F;
  color: #082140;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  #jobs-list-page .regular-content ul > li.job_listing h3 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #jobs-list-page .regular-content ul > li.job_listing h3 {
    font-size: 18px;
  }
}
#jobs-list-page .regular-content ul > li.job_listing .location {
  padding-left: 25px;
  padding-right: 25px;
  float: none;
  width: 100%;
  padding-left: 25px;
  margin-left: 0px;
  margin-bottom: 15px;
  color: #1E1E1E;
  text-transform: uppercase;
  font-family: "Poppins Medium";
  text-align: left !important;
  font-size: 16px;
}
#jobs-list-page .regular-content ul > li.job_listing ul.meta {
  float: none;
  width: 100%;
  display: block;
  text-align: left;
  padding-left: 0px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0px;
  margin-bottom: 0px;
  background-color: #ececec;
  color: #000;
  font-size: 15px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 0px !important;
  z-index: 100;
}
#jobs-list-page .regular-content ul > li.job_listing ul.meta li {
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #jobs-list-page .regular-content ul > li.job_listing ul.meta li.date {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary {
    margin-top: 0px !important;
  }
}
.apply-template-default #primary article .entry-header h1 {
  font-size: 58px;
  line-height: 1.17;
  margin-bottom: 55px;
  max-width: 880px;
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  .apply-template-default #primary article .entry-header h1 {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .apply-template-default #primary article .entry-header h1 {
    font-size: 45px;
    margin-bottom: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .apply-template-default #primary article .entry-header h1 {
    font-size: 40px;
    margin-bottom: 55px;
  }
}
@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary article .entry-header h1 {
    font-size: 32px;
    margin-bottom: 45px;
  }
}
.apply-template-default #primary article ul.meta {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #eff7fe;
  padding: 20px;
  border-radius: 12px;
}
@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary article ul.meta {
    display: block;
  }
}
.apply-template-default #primary article ul#social-share {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.apply-template-default #primary article ul#social-share svg {
  width: 22px;
}
@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary article ul.meta li {
    float: none;
    width: 100%;
  }
}
.apply-template-default #primary article li.location {
  font-family: "Source Sans Pro Semi";
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 991.98px) {
  .apply-template-default #primary article li.location {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary article li.location {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.apply-template-default #primary article li.location:before {
  content: url("../img/ico/map-marker-alt-solid.svg");
  margin-right: 10px;
  position: relative;
  top: -1px;
}
.apply-template-default #primary article .application_dj {
  display: inline-block;
  width: 197px;
  height: 59px;
  border-radius: 999px;
  background: #FED647;
  line-height: 59px;
  font-size: 18px;
  font-family: "Source Sans Pro Semi";
  color: #1E1E1E;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .apply-template-default #primary article .application_dj {
    height: 52px;
    line-height: 52px;
    width: 150px;
  }
}
@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary article .application_dj {
    height: 50px;
    line-height: 50px;
    width: 150px;
  }
}
.apply-template-default #primary article .application_dj:hover {
  background: #082140;
  color: #fff;
}
@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary article .application_dj {
    width: 100%;
    margin-top: 10px;
    margin-left: 0%;
    margin-right: 0px;
  }
}
.apply-template-default #primary article .entry-content p {
  font-size: 19px;
  color: rgb(6, 6, 6);
  line-height: 1.684;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991.98px) {
  .apply-template-default #primary article .entry-content p {
    font-size: 18px;
  }
}
.apply-template-default #primary article .entry-content p a {
  color: #0D539F;
  font-family: "Source Sans Pro Semi";
}
.apply-template-default #primary article .entry-content p a:hover {
  text-decoration: underline;
  color: #082140;
}
.apply-template-default #primary article .entry-content h2 {
  margin-bottom: 30px;
  line-height: 1.3;
  font-size: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .apply-template-default #primary article .entry-content h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .apply-template-default #primary article .entry-content h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary article .entry-content h2 {
    margin-bottom: 20px;
  }
}
.apply-template-default #primary article .entry-content h3 {
  margin-bottom: 20px;
  margin-top: 50px;
  line-height: 1.45;
  font-size: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .apply-template-default #primary article .entry-content h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .apply-template-default #primary article .entry-content h3 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  .apply-template-default #primary article .entry-content h3 {
    margin-bottom: 15px;
  }
}
.apply-template-default #primary article .entry-content h4 {
  margin-bottom: 20px;
  font-family: "Poppins Medium";
  line-height: 1.3;
  font-size: 22px;
}
.apply-template-default #primary article .entry-content ul {
  margin-bottom: 30px;
}
.apply-template-default #primary article .entry-content ul li {
  display: block;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
  font-size: 18px;
}
.apply-template-default #primary article .entry-content ul li a {
  color: #0D539F;
  font-family: "Poppins Medium";
}
.apply-template-default #primary article .entry-content ul li a:hover {
  text-decoration: underline;
}
.apply-template-default #primary article .entry-content ul li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #227aeb;
  position: absolute;
  left: 0px;
  top: 10px;
}
.apply-template-default #primary article .entry-content ul ul {
  margin-top: 15px;
  margin-bottom: 15px;
}
.apply-template-default #primary article .entry-content ul ul li {
  font-size: 16px;
}

#apply-form {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 40px 76px rgba(0, 0, 0, 0.08);
  position: relative;
  margin-bottom: 100px !important;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #apply-form {
    padding: 30px;
    border-radius: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #apply-form {
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 32px;
  }
}
#apply-form:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  border-radius: 50px;
  background: #fff;
  opacity: 0.5;
  box-shadow: 0 30px 76px rgba(0, 0, 0, 0.08);
  top: calc(50% + 30px);
  z-index: -1;
}
#apply-form:before {
  position: absolute;
  display: block;
  content: "";
  left: -175px;
  top: -50px;
  width: 350px;
  height: 350px;
  background: #eff7fe;
  border-radius: 50%;
  z-index: -1;
}
#apply-form .form-wrapper h2 {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 1.25;
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #apply-form .form-wrapper h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767.98px) {
  #apply-form .form-wrapper h2 {
    font-size: 30px;
  }
}
#apply-form .form-wrapper h2 strong {
  color: #227aeb;
}
#apply-form .form-wrapper #submitJobForm {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
#apply-form .form-wrapper #submitJobForm .field {
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}
@media only screen and (max-width: 767.98px) {
  #apply-form .form-wrapper #submitJobForm .field {
    width: 100%;
  }
}
#apply-form .form-wrapper #submitJobForm .field:nth-child(5) {
  width: 100%;
}
#apply-form .form-wrapper #submitJobForm .field:last-child {
  width: 100%;
}
#apply-form .form-wrapper #submitJobForm .field.Skype {
  width: 100%;
}
#apply-form .form-wrapper #submitJobForm .field label {
  margin-bottom: 11px;
  font-family: "Source Sans Pro Semi";
  display: block;
}
#apply-form .form-wrapper #submitJobForm .field label span {
  color: red;
}
#apply-form .form-wrapper #submitJobForm .field input[type=text], #apply-form .form-wrapper #submitJobForm .field input[type=tel], #apply-form .form-wrapper #submitJobForm .field input[type=email], #apply-form .form-wrapper #submitJobForm .field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 62px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 0 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
#apply-form .form-wrapper #submitJobForm .field select {
  background: url("../img/ico/down.svg") no-repeat;
  background-position: 97% center;
  background-size: 24px;
}
#apply-form .form-wrapper #submitJobForm .field textarea {
  width: 100%;
  height: 136px;
  border-radius: 24px;
  background: #fff;
  border: 2px solid #e7f0f6;
  padding: 25px;
  font-size: 16px;
  color: #2d2d2d;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro";
}
#apply-form .form-wrapper #submitJobForm .field .choose-button {
  font-size: 20px;
  font-family: "Source Sans Pro Semi";
  margin-right: 15px;
}
#apply-form .form-wrapper #submitJobForm .field .choose-button a {
  border-bottom: 2px solid #0D539F;
  padding-bottom: 2px;
  color: #0D539F;
}
#apply-form .form-wrapper #submitJobForm .How + .field {
  width: 100%;
}
#apply-form .form-wrapper #submitJobForm #submit_buttons {
  width: 100%;
  margin-top: 0px;
}
#apply-form .form-wrapper #submitJobForm #submit_buttons button.mandatory {
  padding: 0;
  border: 0;
  width: auto;
  padding-left: 60px;
  padding-right: 60px;
  height: 86px;
  line-height: 84px;
  text-align: center;
  border-radius: 999px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #227aeb;
  background-image: none;
  font-size: 1.25em;
  transition: 0.25s ease-in-out;
  font-family: "Source Sans Pro Semi";
  margin: 0px;
  border: 2px solid transparent;
  margin-right: 0px;
  color: #1e1e1e;
  background: #FED647;
}
@media only screen and (max-width: 991.98px) {
  #apply-form .form-wrapper #submitJobForm #submit_buttons button.mandatory {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #apply-form .form-wrapper #submitJobForm #submit_buttons button.mandatory {
    width: 100%;
    height: 70px;
    line-height: 68px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
#apply-form .form-wrapper #submitJobForm #submit_buttons button.mandatory:hover {
  background: #082140;
  color: #fff;
}