#blog-slider-wrapper {
    padding-top: 125px;
    margin-bottom: 60px;
    @include bp(small-lap) {
        padding-top: 70px;
    }
    @include bp(desktop) {
        padding-top: 70px;
    }
    @include bp(lg) {
        padding-top: 90px;
    }
    @include bp(md) {
        padding-top: 90px;
    }
    @include bp(sm-down) {
        padding-top: 90px;
        margin-bottom: 50px;
    }
    #blog-slider {
        .slick-slide {
            @include bp(md) {
                margin: 0 13px;
            }
        }
        .slick-list {
            @include bp(md) {
                margin: 0 -13px;
            }
        }
        .blog-item {
            display: flex;
            align-items: center;
            @include bp(md-down) {
                display: block;
            }
            .blog-photo {
                margin-right: 68px;
                max-width: 595px;

                @include bp(lg) {
                    margin-right: 50px;
                    min-width: 50%;
                }
                @include bp(md-down) {
                    display: block;
                    margin-right: 0;
                    margin-bottom: 20px;
                    min-width: auto;
                    
                }
                img {
                    @include animate-fade;
                    display: block;
                    border-radius: 12px;
                    width: 100%;
                    height: 450px;
                    object-fit: cover;
                    @include bp(lg) {
                        height: 400px;
                    }
                    @include bp(md) {
                        height: 280px;  
                    }
                    @include bp(sm-down) {
                        height: 250px;
                    }
                }
                a {
                    position: relative;
                    overflow: hidden;
                    display: block;
                    border-radius: 12px;
                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            .blog-content {
                flex: 1;
                padding-left: 30px;
                @include bp(lg-down) {
                    padding-left: 0px;
                }
                span.post-date {
                    display: block;
                    font-size: 16px;
                    font-family: $font-semi;
                    color: rgb(131, 136, 143);
                    margin-bottom: 25px;
                    @include bp(lg-down) {
                        font-size: 15px;
                        margin-bottom: 15px;
                    }
                }
                h2 {
                    font-size: 32px;
                    margin-bottom: 15px;
                    line-height: 1.313;
                    @include bp(lg) {
                        font-size: 30px;
                    }         
                    @include bp(md) {
                        font-size: 24px;
                    }
                    @include bp(sm-down) {
                        font-size: 22px;
                    }
                    a {
                        display: block;
                        color: inherit;
                        &:hover {
                            color: $color-blue;
                        }
                    }
                }
                .blog-footer {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    .post-author {
                        display: flex;
                        align-items: center;
                        padding-right: 30px;
                        img {
                            width: 51px;
                            height: 51px;
                            border-radius: 50px;
                            min-width: 50px;
                            margin-right: 17px;
                        }
                        .author-name {
                            font-size: 16px;
                            font-family: $font-semi;
                            color: rgba(0, 0, 0, 0.588);
                            line-height: 1.875;
                            flex-grow: 1;
                            a {
                                color: rgba(0, 0, 0, 0.588);
                                &:hover {
                                    color:$color-blue;
                                }
                            }
                        }
                    }
                    a.readmore {
                        display: inline-block;
                        margin-left: auto;
                        font-size: 16px;
                        font-family: $font-heading;
                        color: $color-pale;
                        line-height: 1.625;
                        text-transform: capitalize;
                        @include bp(md-down) {
                            font-size: 14px;
                        }
                        &:hover {
                            color: $color-heading;
                        }
                    }
                }
            }
        }
    }
}

#featured-articles {
    padding-top: 50px;
    h2 {
        font-size: 24px;
        color: rgb(0, 0, 0);
        line-height: 1.083;
        margin-bottom: 55px;
        @include bp(md-down) {
            margin-bottom: 30px;
        }
    }
    .row {
        padding-left: 2px;
        padding-right: 2px;
    }
    
    .blog-item {
        margin-bottom: 95px;
        @include bp(lg) {
            margin-bottom: 80px;
        }
        @include bp(md) {
            margin-bottom: 70px;
        }
        @include bp(sm-down) {
            margin-bottom: 50px;
        }
        .blog-photo {
            margin-bottom: 18px;
            img {
                @include animate-fade;
                display: block;
                border-radius: 12px;
                width: 100%;
                object-fit: cover;
            }
            a {
                position: relative;
                overflow: hidden;
                display: block;
                border-radius: 12px;
                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .blog-content {
            padding-right: 15px;
            @include bp(md-down) {
                padding-right: 0;
            }
            span.post-date {
                display: block;
                font-size: 16px;
                font-family: $font-semi;
                color: rgb(131, 136, 143);
                margin-bottom: 15px;
                @include bp(lg-down) {
                    font-size: 15px;
                }
            }
            h3 {
                
                font-size: 20px;
                margin-bottom: 15px;
                line-height: 1.5;
                @include bp(lg) {
                    font-size: 19px;
                }         
                @include bp(md) {
                    font-size: 18px;
                }
                @include bp(sm-down) {
                    font-size: 17px;
                }
                a {
                    display: block;
                    color: inherit;
                    &:hover {
                        color: $color-blue;
                    }
                }
            }
        }
    }
    .col {
        padding-left: 13px;
        padding-right: 13px;
        min-width: 33.333333%;
        @include bp(sm-down) {
            min-width: 100%;
        }
        &:nth-of-type(1), &:nth-of-type(2) {
            min-width: 50%;
            @include bp(sm-down) {
                min-width: 100%;
            }
            .blog-content {
                padding-right: 52px;
                @include bp(md-down) {
                    padding-right: 0px;
                }
            }
            .blog-photo {
                margin-bottom: 28px;
                img {
                    height: 360px;
                    @include bp(lg) {
                        height: 320px;
                    }
                    @include bp(md) {
                        height: 250px;
                    }
                    @include bp(sm-down) {
                        height: 220px;
                    }
                }
            }
            h3 {
                font-size: 23px;
                margin-bottom: 20px;
                @include bp(lg) {
                    font-size: 22px;
                }         
                @include bp(md) {
                    font-size: 21px;
                }
                @include bp(sm-down) {
                    font-size: 20px;
                }
            }
        }
        &:nth-of-type(3), &:nth-of-type(4) , &:nth-of-type(5) {
            .blog-photo {
                margin-bottom: 28px;
                img {
                    height: 260px;
                    @include bp(lg) {
                        height: 240px;
                    }
                    @include bp(md) {
                        height: 200px;
                    }
                    @include bp(sm-down) {
                        height: 220px;
                    }
                }
            }            
        }
    }
}

#blog-howto {
    background-color: $color-light;
    padding-top: 220px;
    padding-bottom: 190px;
    @include bp(small-lap) {
        padding-top: 160px;
        padding-bottom: 160px;
    }
    @include bp(desktop) {
        padding-top: 160px;
        padding-bottom: 160px;        
    }
    @include bp(lg) {
        padding-top: 160px;
        padding-bottom: 200px;
    }

    @include bp(md) {
        padding-top: 120px;
        padding-bottom: 170px;
    }
    @include bp(sm-down) {
        padding-top: 150px;
        padding-bottom: 30px;
    }
    header {
        text-align: center;
        padding-bottom: 50px;
        max-width: 700px;
        margin:0px auto;
        h1 {
            font-size: 50px;
            line-height: 1.3;
            font-family: $font-med;
            font-weight: 500;
            @include bp(lg) {
                font-size: 42px;
                margin-bottom: 0px;
            }
            @include bp(md) {
                font-size: 40px;
            }
            @include bp(sm-down) {
                font-size: 32px;
            }
            b {
                font-family: $font-heading;
            }
            em {
                position: relative;
                font-style: normal;
                span {
                    z-index:10;
                    position: relative;
                }
                &:before {
                    width: 104%;
                    display: block;
                    content:'';
                    height: 10px;
                    background:$color-yellow;
                    position: absolute;
                    left:-2%;
                    bottom:13px;
                    z-index:0;
                }
            }
        }
        p {
            max-width: 600px;
            margin:0px auto;
            @include intro-text;
            margin-top: 23px;
        }
    }
    .col {
        padding-left: 10.5px;
        padding-right: 10.5px;
        min-width: 20%;
        @include bp(md) {
            min-width: 50%;
            max-width: 50%;
            margin-bottom: 21px;
        }
        @include bp(sm-down) {
            min-width: 50%;
            max-width: 50%;
            margin-bottom: 21px;
        }
    }
    .blog-howto {
        justify-content: center;
    }
    .blog-howto-item {
        border-radius: 10px;
        background-color: rgb(246, 248, 254);
        box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.04);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 28px;
        flex-direction: column;
        text-align: center;
        position: relative;
        min-height: 195px;
        height: 100%;
        @include animate-fade;
        @include bp(lg-down) {
            padding: 15px;
        }
        .blog-ico {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 55px;
            margin-bottom: 29px;
        }
        h3 {
            font-family: $font-med;
            font-size: 18px;
            color: rgb(0, 0, 0);
            line-height: 1.333;
            @include animate-fade;
            font-weight: 500;
            @include bp(lg) {
                font-size: 17px;
            }         
            @include bp(md) {
                font-size: 17px;
            }
            @include bp(sm-down) {
                font-size: 16px;
            }
        }
        a {
            position: absolute;
            font-size: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
        &:hover {
            background: $white;
            transform: scale(1.05);
            h3 {
                color: $color-blue;
            }
        }
    }
}

#blog-search-area{
    margin-top: -120px;
    @include bp(md) {
        margin-top: -100px;
    }
    @include bp(sm-down) {
        margin-top: 0;
        padding-top: 50px;
    }
    h3 {
        font-size: 24px;
        margin-bottom: 35px;
        @include bp(small-lap) {
            margin-bottom: 25px;
        }
        @include bp(desktop) {
            margin-bottom: 25px;
        }
        @include bp(md-down) {
            margin-bottom: 30px;
        }
        b {
            color: $color-pale;
        }
    }
    .blog-search-wrapper {
        max-width: 830px;
        margin-left: auto;
        margin-right: auto;
        @include bp(lg-down) {
            max-width: 660px;
        }
        form {
            border-radius: 12px;
            background-color: #fff;
            box-shadow: 0px 0px 54.6px 15.4px rgba(0, 0, 0, 0.06);
            height: 108px;
            position: relative;
            @include bp(md) {
                height: 98px;
            }
            @include bp(sm-down) {
                height: auto;
                background-color: transparent;
                box-shadow: none;
            }
        }
        input[type="text"], input[type="search"] {
            display: block;
            width: 100%;
            height: 108px;
            border:0;
            background-color: #fff;
            border-radius: 12px;
            padding-left: 111px;
            padding-right: 245px;
            font-size: 22px;
            font-family: $font-main;
            line-height: 1.091;
            color: $color-text;
            @include input-placeholder {
                color: rgb(190, 198, 199);
            }
            @include bp(md) {
                height: 98px;
                font-size: 20px;
            }
            @include bp(sm-down) {
                height: 78px;
                font-size: 18px;
                padding-left: 20px;
                padding-right: 20px;
                box-shadow: 0px 0px 54.6px 15.4px rgba(0, 0, 0, 0.06);
            }
        }
        .icon-search {
            @include centre(y);
            font-size: 24px;
            color: #1a6df9;
            left: 46px;
            @include bp(sm-down) {
                display: none;
            }
        }
        input[type=submit] {
            @include btn-cta;
            text-transform: uppercase;
            border: 0;
            padding-top: 0px;
            padding-bottom: 0px;
            @media (min-width: 768px) {
                @include centre(y);
                right: 22px;
            }
            @include bp(sm-down) {
                margin-top: 15px;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }
    }
}

#latest-posts {
    padding-bottom: 80px;
    @include bp(lg) {
        padding-bottom: 60px;
    }

    @include bp(md) {
        padding-bottom: 20px;
    }
    @include bp(sm-down) {
        padding-bottom: 20px;
    }
    h2 {
        font-size: 24px;
        color: rgb(0, 0, 0);
        line-height: 1.083;
        margin-bottom: 55px;
        @include bp(md-down) {
            margin-bottom: 30px;
        }
    }
    .row {
        padding-left: 2px;
        padding-right: 2px;
    }
    .col {
        padding-left: 13px;
        padding-right: 13px;
        min-width: 33.333333%;
        @include bp(sm-down) {
            min-width: 100%;
        }
    }
    .blog-item {
        margin-bottom: 65px;
        .blog-photo {
            margin-bottom: 18px;
            img {
                @include animate-fade;
                display: block;
                border-radius: 12px;
                width: 100%;
                height: 285px;
                object-fit: cover;
            }
            a {
                position: relative;
                overflow: hidden;
                display: block;
                border-radius: 12px;
                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .blog-content {
            padding-right: 15px;
            @include bp(md-down) {
                padding-right: 0;
            }
            span.post-date {
                display: block;
                font-size: 16px;
                font-family: $font-semi;
                color: rgb(131, 136, 143);
                margin-bottom: 15px;
                @include bp(lg-down) {
                    font-size: 15px;
                }
            }
            h3 {
                
                font-size: 20px;
                margin-bottom: 15px;
                line-height: 1.5;
                @include bp(lg) {
                    font-size: 19px;
                }         
                @include bp(md) {
                    font-size: 18px;
                }
                @include bp(sm-down) {
                    font-size: 17px;
                }
                a {
                    display: block;
                    color: inherit;
                    &:hover {
                        color: $color-blue;
                    }
                }
            }
        }
    }
}

#ctis-loading {
    text-align: center;
    width: 100%;
}


// Blog Single style

#blog-single-header {
    padding-top: 240px;
    padding-bottom: 70px;
    position: relative;
    background-color: #eff7fe;
    @include bp(desktop) {
        padding-top: 250px;
        padding-bottom: 70px;
    }
    @include bp(lg) {
        padding-top: 190px;
        padding-bottom: 60px;
    }
    @include bp(md) {
        padding-top: 130px;
        padding-bottom: 50px;
    }
    @include bp(sm-down) {
        padding-top: 150px;
        padding-bottom: 50px;
    }
    .container {
        position: relative;
        z-index: 2;
    }
    .blog-category {
        font-family: $font-semi;
        display: block;
        text-transform: uppercase;
        color: $color-pale;
        font-size: 15px;
        line-height: 1.2;
        margin-bottom: 25px;
        a {
            color: inherit;
            &:hover {
                color: $color-blue;
            }
        }
    }
    h1 {
        font-size: 58px;
        line-height: 1.17;
        margin-bottom: 55px;
        max-width: 880px;
        @include bp(desktop) {
            font-size: 50px;
        }
        @include bp(lg) {
            font-size: 45px;
            margin-bottom: 65px;
        }
        @include bp(md) {
            font-size: 40px;
            margin-bottom: 55px;
        }
        @include bp(sm-down) {
            font-size: 32px;
            margin-bottom: 45px;
        }
    }
    .author-info {
        display: flex;
        align-items: center;
        margin-bottom: 55px;
        @include bp(lg-down) {
            margin-bottom: 30px;
        }
        @include bp(sm-down) {
            flex-direction: column;
            text-align: center;
            margin-bottom: 0;
        }
        img {
            border-radius: 50%;
            width: 48px;
            margin-right: 17px;
            @include bp(sm-down) {
                margin-bottom: 15px;
                margin-right: 0;
            }
        }
        .author-content {
            flex-grow: 1;
            .author-name {
                display: block;
                font-family: $font-semi;
                font-size: 18px;
                line-height: 1.2;
                color: #000;
                margin-bottom: 5px;
                a {
                    color:$black;
                    &:hover {
                        color:$color-pale;
                    }
                }
            }
            .date-time {
                span {
                    display: inline-block;
                    font-family: $font-main;
                    font-size: 16px;
                    line-height: 1.2;
                    color: rgba(0, 0, 0, 0.502);
                    &.post-date {
                        margin-right: 15px;
                    }
                    &.sep {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
// blog-single-body
#blog-single-body {
    z-index: 3;
    margin-top: 50px;
    @include bp(sm-down) {
        margin-top: 30px;
    }
    .blog-single-body {
        &-in {
            
            background-color: #fff;
            position: relative;
            z-index: 3;
            border-radius: 12px;
            @include bp(desktop) {
                padding-bottom: 140px;
            }
            @include bp(sm-down) {
                padding: 40px 0px 60px 0px;
            }
            .blog-left-col {
                padding-right: 0px;
                @include bp(md-down) {
                    padding-right: 15px;
                }
            }
            .blog-content {
                padding-right: 55px;
                @include bp(md-down) {
                    padding-right: 0px;
                }
                p {
                    font-size: 19px;
                    color: rgb(6, 6, 6);
                    line-height: 1.684;
                    margin-bottom: 30px;
                    @include bp(md-down) {
                        font-size: 18px;
                    }
                    a {
                        color:$color-blue;
                        font-family: $font-semi;
                        &:hover {
                            text-decoration: underline;
                            color:$color-blue-dark;
                        }
                    }                    
                }    
                h2 {
                    margin-bottom: 30px;
                    line-height: 1.3;
                    font-size: 30px;
                    @include bp(lg) {
                        margin-bottom: 20px;
                    }
                    @include bp(md) {
                        margin-bottom: 20px;
                    }
                    @include bp(sm-down) {
                        margin-bottom: 20px;
                    }
                }
                h3 {
                    margin-bottom: 20px;
                    margin-top: 50px;
                    line-height: 1.45;
                    font-size: 26px;
                    @include bp(lg) {
                        margin-top: 30px;
                        margin-bottom: 15px;
                    }
                    @include bp(md) {
                        margin-bottom: 15px;
                    }
                    @include bp(sm-down) {
                        margin-bottom: 15px;
                    }
                }
                h4 {
                    margin-bottom: 20px;
                    font-family: $font-med;
                    line-height: 1.3;
                    font-size: 22px;
                }
                ul {
                    margin-bottom: 30px;
                    li {
                        display: block;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        position: relative;
                        font-size: 18px;
                        a {
                            color:$color-blue;
                            font-family: $font-med;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &:before {
                            content:'';
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-radius: 100%;
                            background:$color-pale;
                            position: absolute;
                            left:0px;
                            top:10px;
                        }
                    }
                    ul {
                        margin-top: 15px;
                        margin-bottom: 15px;
                        li {
                            font-size: 16px;
                        }
                    }
                }     
                .blog-intro {
                    h2 {
                        margin-top: 0!important;
                    }
                    p {
                        font-size: 19px;
                        color: rgb(6, 6, 6);
                        line-height: 1.684;
                        @include bp(md-down) {
                            font-size: 18px;
                        }
                        a {
                            color:$color-blue;
                            &:hover {
                                text-decoration: underline;
                                color:$color-blue-dark;
                            }
                        }
                    }
                }
                .blog-photo {
                    margin-top: 55px;
                    margin-bottom: 55px;
                    @include bp(sm-down) {
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }
                    img {
                        width: 100%;
                        display: block;
                    }
                }
                h2 {
                    font-size: 30px;
                    line-height: 1.5;
                    color: #060606;
                    margin-top: 55px;
                    margin-bottom: 30px;
                    @include bp(lg) {
                        font-size: 27px;
                    }
                    @include bp(md) {
                        font-size: 26px;
                    }
                    @include bp(sm-down) {
                        font-size: 25px;
                        margin-top: 45px;
                        margin-bottom:25px;
                    }
                }
            }
            .blog-cta {
                margin-top: 60px;
                border-radius: 12px;
                background-color: #eff7fe;
                padding: 65px 40px 55px 40px;
                width: 95%;
                @include bp(sm-down) {
                    padding: 30px 20px;
                    width: 100%;
                }
                &-caption {
                    max-width: 450px;
                    margin-bottom: 30px;
                    p {
                        font-family: $font-semi;
                        font-size: 20px;
                        line-height: 1.2;
                        color: #000;
                        @include bp(md-down) {
                            font-size: 1.1em;
                            margin-bottom: 30px;
                        }
                        @include bp(sm-down) {
                            text-align: center;
                        }
                    }
                }
                .subscribe-form {
                    max-width: 578px;
                    margin-bottom: 35px;
                    position: relative;
                    input[type="email"], input[type="text"] {
                        display: block;
                        width: 100%;
                        height: 75px;
                        border-radius: 12px;
                        background-color: #fff;
                        box-shadow: 0px 0px 54.6px 15.4px rgba(0, 0, 0, 0.06);
                        padding: 0 165px 0 34px;
                        font-size: 18px;
                        font-family: $font-main;
                        line-height: 1.333;
                        color: #060606;
                        border: 0;
                        @include input-placeholder {
                            color: #bec6c7;
                        }
                        @include bp(md-down) {
                            font-size: 1em;
                        }
                        @include bp(sm-down) {
                            height: 60px;
                            padding: 0 20px;
                            font-size: 1em;
                        }
                        @include input-placeholder {
                            color: rgb(190, 198, 199);
                        }
                    }
                    input[type="submit"] {
                        @media (min-width: 768px) {
                            @include centre(y);
                        }
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $color-yellow;
                        border: 0;
                        right: 15px;
                        border-radius: 12px;
                        width: 133px;
                        height: 46px;
                        @include animate-fade;
                        font-size: 12px;
                        font-family: $font-heading;
                        color: #000;
                        line-height: 1.333;
                        @include bp(sm-down) {
                            margin-top: 15px;
                            width: 100%;
                        }
                        &:hover {
                            background-color: $color-heading;
                            cursor: pointer;
                            color: #fff;
                        }
                    }
                }
                &-share {
                    display: flex;
                    align-items: center;
                    @include bp(sm-down) {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    p {
                        font-family: $font-semi;
                        font-size: 18px;
                        line-height: 1.2;
                        margin-right: 45px;
                        color: #000;
                        margin-bottom: 0px;
                        @include bp(md-down) {
                            font-size: 1em;
                        }
                        @include bp(sm-down) {
                            margin-right: 0;
                            margin-bottom: 15px;
                        }
                    }
                    ul {
                        li {
                            display: inline-block;
                            margin-right: 23px;
                            @include bp(sm-down) {
                                margin-left: 11px;
                                margin-right: 11px;
                            }
                            a {
                                color: #000;
                                font-size: 22px;
                                &:hover {
                                    color: $color-pale;
                                }
                            }
                        }
                    }
                }
            }
            .blog-sidebar-col {
                padding-left: 0px;
                @include bp(sm-down) {
                    padding-left: 15px;
                    order:-1;
                }
            }
            .blog-aside {
                max-width: 370px;
                margin-left: auto;
                position: sticky;
                top:110px;
                @include bp(md-down) {
                    max-width: 100%;
                    margin-top: 65px;
                }
                @include bp(sm-down) {
                    margin-top: 0px;
                }
                .side-image {
                    margin-bottom: 30px;
                    img {
                        border-radius: 9px;
                        width: 100%;
                        height: 220px;
                        object-fit: cover;
                        @include bp(md-down) {
                            height: auto;
                        }
                    }
                }
                .aside-title {
                    display: block;
                    font-family: $font-semi;
                    font-size: 20px;
                    line-height: 1.2;
                    text-transform: uppercase;
                    color: #060606;
                }
                .share-area {
                    .aside-title {
                        margin-bottom: 34px;
                    }
                    ul {
                        li {
                            display: inline-block;
                            margin-right: 23px;
                            a {
                                color: #000;
                                font-size: 22px;
                                &:hover {
                                    color: $color-pale;
                                }
                            }
                        }
                    }
                }
                .archive-filter {
                    margin-bottom: 65px;
                    @include bp(sm-down) {
                        margin-bottom: 45px;
                    }
                    .aside-title {
                        margin-bottom: 15px;
                    }
                    ul {
                        li {
                            a {
                                font-size: 16px;
                                font-family: $font-heading;
                                color: rgba(75, 75, 75, 0.878);
                                font-weight: bold;
                                line-height: 1.556;
                                padding-top: 19px;
                                padding-bottom: 19px;
                                border-bottom: 1px solid rgba(75, 75, 75, .11);
                                display: block;
                                @include bp(lg) {
                                    font-size: 17px;
                                }         
                                @include bp(md) {
                                    font-size: 17px;
                                }
                                @include bp(sm-down) {
                                    font-size: 16px;
                                }
                                &:hover {
                                    color: $color-pale;
                                }
                            }
                            &.active {
                                a {
                                    color: $color-pale;
                                }
                            }
                        }
                    }
                }
                .category-filter {
                    ul {
                        li {
                            a {
                                padding-top: 10px;
                                padding-bottom: 10px;
                                font-size: 16px;
                                border:none;
                                font-family: $font-med;
                                opacity: 0.5;
                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#related-posts {
    position: relative;
    padding-bottom: 171px;
    margin-top: 70px;
    @include bp(desktop) {
        padding-bottom: 110px;
    }
    @include bp(lg) {
        padding-bottom: 100px;
    }
    @include bp(md) {
        padding-bottom: 90px;
    }
    @include bp(sm-down) {
        padding-bottom: 40px;
        margin-top: 50px;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    h2 {
        font-size: 21px;
        color: rgb(0, 0, 0);
        line-height: 1.083;
        margin-bottom: 35px;
        @include bp(md-down) {
            margin-bottom: 30px;
        }
    }
    .row {
        padding-left: 2px;
        padding-right: 2px;
    }
    .col {
        padding-left: 13px;
        padding-right: 13px;
        min-width: 33.333333%;
        @include bp(sm-down) {
            min-width: 100%;
        }
    }
    .blog-item {
        @include bp(sm-down) {
            margin-bottom: 35px;
        }
        .blog-photo {
            margin-bottom: 18px;
            img {
                @include animate-fade;
                display: block;
                border-radius: 12px;
                width: 100%;
            }
            a {
                position: relative;
                overflow: hidden;
                display: block;
                border-radius: 12px;
                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .blog-content {
            padding-right: 15px;
            @include bp(md-down) {
                padding-right: 0;
            }
            span.post-date {
                display: block;
                font-size: 16px;
                font-family: $font-semi;
                color: rgb(131, 136, 143);
                margin-bottom: 15px;
                @include bp(lg-down) {
                    font-size: 15px;
                }
            }
            h3 {
                
                font-size: 20px;
                margin-bottom: 15px;
                line-height: 1.5;
                @include bp(lg) {
                    font-size: 19px;
                }         
                @include bp(md) {
                    font-size: 18px;
                }
                @include bp(sm-down) {
                    font-size: 17px;
                }
                a {
                    display: block;
                    color: inherit;
                    &:hover {
                        color: $color-blue;
                    }
                }
            }
        }
    }
}

// Blog listing featured slider - additional css for arrows

#blog-slider {
    .slick-prev {
        right: calc(45.8% + 50px);
        @include bp(lg) {
            right: calc(50% + 50px);
        }
        @include bp(md) {
            @include centre(x);
            right: auto;
            left: calc(50% - 20px);
        }
    }
    .slick-next {
        right: 45.8%;
        @include bp(lg) {
            right: 50%;
        }
        @include bp(md) {
            @include centre(x);
            left: calc(50% + 20px);
        }
    }
    .slick-prev:before, .slick-next:before {
        font-family: "icomoon";
        font-size: 26px;
        line-height: 1;
        opacity: 1;
        color: $color-pale;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: 0.25s ease-in-out;
        @include bp(md) {
            font-size: 30px;
        }
        @include bp(sm-down) {
            font-size: 26px;
        }
    }   
    .slick-prev:before { content: "\e90d";}


    .slick-next:before { content: "\e90c";}
    .slick-prev, .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        bottom: -6px;
        display: block;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
        z-index: 99;
        @include bp(md) {
            bottom: -10px;
        }
        @include bp(sm-down) {
        }
        &:hover {
            &:before {
                opacity: 1;
                color: $color-blue;
            }
        }
    }
}

#infinite-handle, #ctis-loading {
    text-align: center;
    width: 100%;
    .ctis-load-more {
        text-align: center;
        button {
            display: inline-block;
            width: 197px;
            height: 59px;
            border-radius: 999px;
            background:$white;
            line-height: 56px;
            font-size: 18px!important;
            font-family: $font-semi!important;
            color:$color-blue;
            text-align: center;
            border:2px solid $color-blue;
            @include animate-fade;
            @include bp(md) {
                height: 52px;
                line-height: 52px;
                width: 150px;
            }
            @include bp(sm-down) {
                height: 50px;
                line-height: 50px;
                width: 150px;                
            }
            &:hover, &:focus {
                background:$color-blue-dark;
                color:$white;
            }  
        }
    }    
}

#latest-posts.latest-posts--inner {
    padding-top: 100px;
    @include bp(sm-down) {
        padding-top: 50px;
    }
}

// Not Found
#search-no--results {
    padding-top: 80px;
    padding-bottom: 100px;
    .container {
        max-width: 860px;
        text-align: center;
    }
    img {
        max-width: 650px;
        margin: 0px auto;
    }
    #no-posts {
        h2 {
            margin-bottom: 30px;
            @include main-title;
            text-transform: uppercase;
        }
        p {
            @include intro-text;
            margin-bottom: 60px;
        }
    }
}

.author #blog-howto {
    header {
        max-width: 100%!important;
        padding-bottom: 10px!important;
    }
}

.author-image--single {
    img {
        border-radius: 100%;
        margin-top: 20px;
        border:3px solid $white;        
    }
}