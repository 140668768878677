// Import fonts and fonts variables
@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Prompt';
//     src: url('../fonts/Prompt-Bold.woff2') format('woff2'),
//         url('../fonts/Prompt-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Source Sans Pro Semi';
    src: url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Prompt Medium';
//     src: url('../fonts/Prompt-Medium.woff2') format('woff2'),
//         url('../fonts/Prompt-Medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Poppins Medium';
    src: url('../fonts/Poppins-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Semi';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Bold';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



$font-main:'Source Sans Pro';
$font-heading:'Poppins Bold';
$font-med:'Poppins Medium';
$font-semi:'Source Sans Pro Semi';

*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.fa, .fab, .fas, .fal {
    font-family: 'icomoon';
}

body { 
	font: {
		size:$base-size;
	}
    font-family: $font-main;
	-webkit-text-size-adjust: 100%;  //fix for iOS 
    color: $color-text;
    padding: 0;
    margin: 0;
    font-weight: 400;
}

p {
    color: $color-text;
    margin-top: 0;
    margin-bottom: 0px;
    font-weight: normal;
    font-family: $font-main;
    font-size: 16px;
    line-height: 26px;
}

ul {
    list-style: none;
    margin:0px;
    padding:0px;
}

body {
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
        line-height: 1;
        font-family: $font-heading;
        color:$color-heading;
    }
}

// reset
a {
    @include animate-fade;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

a, button, input, textarea {
    &:focus {
        outline: none!important;
    }
}

button, input[type="submit"] {
    @include animate-fade;
    cursor: pointer;
}

address {
    margin:0px;
    padding:0px;
}
iframe {
    border:0;
}

i {
    font-style: normal;
}

#secondary img, article img, figure, img, img.wp-post-image {
    max-width: 100%;
    height: auto;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?xecj5f');
  src:  url('../fonts/icomoon.eot?xecj5f#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?xecj5f') format('woff2'),
    url('../fonts/icomoon.ttf?xecj5f') format('truetype'),
    url('../fonts/icomoon.woff?xecj5f') format('woff'),
    url('../fonts/icomoon.svg?xecj5f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-globe-light:before {
  content: "\e90e";
}
.icon-pinterest-p-brands:before {
  content: "\e90a";
}
.icon-instagram-brands:before {
  content: "\e90b";
}
.icon-arrow-right-long-solid:before {
  content: "\e90c";
}
.icon-arrow-left-long-solid:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e909";
}
.icon-long-arrow-down-light:before {
  content: "\e908";
}
.icon-long-arrow-right-light:before {
  content: "\e907";
}
.icon-facebook-brands:before {
  content: "\e900";
}
.icon-facebook-f-brands:before {
  content: "\e901";
}
.icon-linkedin-brands:before {
  content: "\e902";
}
.icon-linkedin-in-brands:before {
  content: "\e903";
}
.icon-twitter-brands:before {
  content: "\e904";
}
.icon-twitter-square-brands:before {
  content: "\e905";
}
.icon-youtube-brands:before {
  content: "\e906";
}
