// Blog styling
.single {
    .elementor {
        h1, h2, h3, h4, h5 {
            margin-bottom: 30px;
            margin-top: 20px;
        }
    }
}

.page-content {
    h1, h2, h3, h4, h5 {
        margin-bottom: 30px;
        margin-top: 20px;
    }    
}

.elementor-section-wrap {
    overflow-x: hidden;
}

.elementor-container {
    max-width: 1140px!important;
}

.rp4wp-related-job_listing>ul li.job_listing a, .rp4wp-related-job_listing>ul li.no_job_listings_found a, ul.job_listings li.job_listing a, ul.job_listings li.no_job_listings_found a {
    padding-left: 0px!important;
}

#ermac-wrapper {
    padding-top: 180px;
    padding-bottom: 150px;
    @include bp(lg) {
        padding-top: 140px;
    }
    @include bp(md) {
        padding-top: 150px;
        padding-bottom: 100px;
    }
    @include bp(sm-down) {
        padding-top: 130px;
        padding-bottom: 80px;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include bp(md) {
            display: block;
            text-align: center;
        }
        @include bp(sm-down) {
            display: block;
            text-align: center;
        }
    }
    .ermac-image {
        width: 45%;
        @include bp(md-down) {
            width: 100%;
            margin-top: 50px;
        }
        img {
            max-width: 90%;
            @include bp(md) {
                max-width: 400px;
            }
        }
    }
    .ermac-content {
        width: 50%;
        @include bp(md) {
            width: 100%;
            padding-left: 50px;
            padding-right: 50px;
        }
        @include bp(sm-down) {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    h1 {
        margin-bottom: 15px;
        text-transform: uppercase;
        color:$color-pale;
        font-size: 64px;
        @include bp(lg) {
            font-size: 52px;
        }
        @include bp(md) {
            font-size: 52px;
        }
        @include bp(sm-down) {
            font-size: 42px;
        }
    }
    h2 {
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 1.3;
        @include bp(lg) {
            font-size: 22px;
        }
        @include bp(md) {
            font-size: 22px;
        }
        @include bp(sm-down) {
            font-size: 22px;
        }
    }
    h3 {
        margin-top: 40px;
        margin-bottom: 15px;
        font-size: 22px;
    }
    p {
       &:first-of-type {
            @include intro-text;           
       }
    }
    a.btn-cta {
        @include yellow-btn;
        margin-top: 20px;
    }
}

// Tech 
#tech-stack {
    background:#eff7fe;
    padding-top: 100px;
    padding-bottom: 300px;
    @include bp(lg) {
        padding-top: 60px;
    }
    @include bp(md) {
        padding-top: 60px;
        padding-bottom: 250px;
    }
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    header {
        text-align: center;
        width: 690px;
        margin: 0 auto;
        padding-bottom: 50px;
        @include bp(sm-down) {
            width: 90%;
        }
        h2 {
            @include main-title;
            strong {
                color:#227aeb;
            }
        }
        p {
            @include intro-text;
        }
    }
    .tab {
        margin:0px auto;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:2px solid $color-pale;
        width:auto;
        padding:5px;
        width: 400px;
        border-radius: 999px;
        @include bp(sm-down) {
            width: 100%;
            display: block;
            text-align: center;
            border-radius: 4px;
        }
        button {
            width: 200px;
            border-radius: 999px;
            border:none;
            font-size: 18px;
            font-family: $font-semi;
            color:$color-pale;
            padding-top: 13px;
            padding-bottom: 13px;
            @include bp(md) {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 16px;
            }
            @include bp(sm-down) {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 16px;     
                width: 100%;      
                border-radius: 4px;     
            }
            &.active {
                background:$color-pale!important;
                color:$white!important;
            }
            &:hover {
                color:$color-pale;
                background: none;
            }
        }
    }
    .tech-list {
        a {
            display: block;
            width: 100%;
            height: 100%;
            &:hover {
                .tech-card {
                    background:$color-blue;
                    transform: scale(1.1);
                    h4 {
                        color:$white;
                    }
                    p {
                        color:$white;
                    }
                    span {
                        color:$color-yellow;
                    }
                }
            }
        }
        .tech-card {
            background:$white;
            border-radius: 16px;
            padding:15px;
            @include animate-fade;
            box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.05); 
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            @include bp(sm-down) {
                margin-bottom: 15px;
            }
            .tech-ico {
                width: 64px;
                height: 64px;
                background:$white;
                border:1px solid #ddd;
                border-radius: 12px;
                @include animate-fade;
                position: relative;
                margin-right: 20px;
                img {
                    @include centre(both);
                    width:36px;
                }
            }
            .tech-desc {
                flex:1;
            }
            h4 {
                margin-top: 0px;
                font-size: 18px;
                margin-bottom: 3px;
                @include animate-fade;
                font-family: $font-med;
                font-weight: 500;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                @include animate-fade;
                margin-bottom: 0px;
                line-height: 20px;
                padding-right: 0px;
            }
            span {
                font-family: $font-semi;
                color:$color-pale;
                @include animate-fade;
            }
        }
    }
}

// Contact CTA
#contact-cta--inner {
    margin-bottom: 150px;
    @include bp(lg) {
        margin-bottom: 100px;
    }
    @include bp(md) {
        margin-bottom: 100px;
    }
    @include bp(sm-down) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    #contact-wrapper {
        margin:0px auto;
        max-width:960px;
        padding: 40px 65px 62px 65px;
        background-color: $color-pale;
        border-radius: 50px;
        box-shadow: 0 40px 76px rgba(0, 0, 0,  0.08);  
        position: relative;
        @include bp(md) {
            padding:30px;
            border-radius: 30px;
        }
        @include bp(sm-down) {
            padding:30px;
            padding-top: 40px;
            padding-bottom: 40px;
            border-radius: 32px;
        }
        @include bp(sm-down) {
            padding:30px;
        }
        h3 {
            margin-bottom: 20px;
            font-size: 32px;
            color:$white;
            @include bp(md) {
                font-size: 30px;
            }
            @include bp(sm-down) {
                font-size: 30px;
            }
            strong {
                color:#227aeb;
            }
        }
        p {
            margin-bottom: 20px;
            margin-top: -10px;
            font-size: 1.25em;
            font-size: 18px;
            color:$white;
            width: 80%;
            @include bp(md) {
                width: 100%;
                margin-top: 0px;
            }
            @include bp(sm-down) {
                width: 100%;
            }
        }

        .form-wrapper {
            // validate
            .wpcf7-not-valid-tip {
                font-size: 14px;
                padding-left: 15px;
                padding-top: 7px;
                color:#ffd4d4;
            }
            .wpcf7-response-output {
                border:none;
                padding:0px;
                width: 100%;
                margin-left: 15px;
                color:#ffd4d4;
            }


            .fluentform, form {
                padding-top: 20px;
                .row-wrapper {
                    display: flex;
                    margin-left: -15px;
                    margin-right: -15px;
                    margin-bottom: 30px;
                    @include bp(sm-down) {
                        margin-bottom: 15px;
                        display: block;
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                    .form-block {
                        width: 50%;
                        margin-right: 15px;
                        margin-left: 15px;
                        @include bp(sm-down) {
                            width: 100%;
                            margin-left: 0px;
                            margin-right: 0px;
                        }
                    }
                    .form-block-full {
                        width: 100%;
                    }
                }
                label {
                    margin-bottom: 11px;
                    font-family: $font-semi;
                    display: block;
                    color:$white;
                    span {
                        color:red;
                    }
                }
                input[type="text"], input[type="tel"], input[type="email"], select {

                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    width: 100%;
                    height: 62px;
                    border-radius: 24px;
                    background: #fff;
                    border: 2px solid #e7f0f6;
                    padding: 0 25px;
                    font-size: 16px;
                    color: #2d2d2d;
                    transition: 0.25s ease-in-out;      
                    font-family: $font-main;      
                    
                }    
                select {
                    background:url('../img/ico/down.svg') no-repeat;
                    background-position: 97% center;
                    background-size:24px;
                    background-color: $white;
                }  
                textarea {
                    width: 100%;
                    height: 136px;
                    border-radius: 24px;
                    background: #fff;
                    border: 2px solid #e7f0f6;
                    padding: 25px;
                    font-size: 16px;
                    color: #2d2d2d;
                    transition: 0.25s ease-in-out;     
                    font-family: $font-main;                  
                }      
                .ff-el-form-check {
                    padding-top: 8px;
                    font-size: 16px;
                    a {
                        color:$color-pale;
                        &:hover {
                            color:$color-blue;
                        }
                    }
                    input[type="checkbox"] {
                        margin-right: 5px;
                        position: relative;
                        top:-1px;
                    }  
                }   
                .ff-btn-submit, .wpcf7-submit {
                    padding: 0;
                    border: 0;
                    width: auto;
                    padding-left: 60px;
                    padding-right: 60px;
                    height: 86px;
                    line-height: 84px;
                    text-align: center;
                    border-radius: 999px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: $color-pale;
                    background-image: none;
                    font-size: 1.250em;
                    @include animate-fade;
                    font-family: $font-semi;
                    margin:0px;
                    border:2px solid transparent;
                    margin-right: 0px;
                    color:#1e1e1e;
                    background:$color-yellow;
                    margin-top: 20px;
                    @include bp(md-down) {
                        width: 100%;
                        height: 70px;
                        line-height: 68px;
                        margin-left: 0px;
                        margin-right: 0px;
                        margin-bottom: 15px;
                        padding-left: 0px;
                        padding-right: 0px;
                        width: 100%;
                        text-align: center;                            
                    }
                    @include bp(md) {
                        width: 100%;
                        height: 70px;
                        line-height: 68px;    
                        margin-bottom: 0px;
                        margin-top: 15px;                
                    }                    
                    &:hover {
                        background: #082140;
                        color: #fff;                        
                    }                      
                } 
            }

            fieldset {
                padding:0px;
                margin:0px;
                display: flex;
                flex-wrap: wrap;
                .row {
                    flex:1;
                    @include bp(md-down) {
                        width: 100%;
                        flex:auto;
                    }
                }
                .wpcf7-submit {
                    padding: 0;
                    border: 0;
                    width: auto;
                    padding-left: 40px;
                    padding-right: 40px;
                    height: 86px;
                    text-align: center;
                    border-radius: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: $color-pale;
                    background-image: none;
                    font-size: 1.250em;
                    @include animate-fade;
                    font-family: $font-semi;
                    margin:0px;
                    border:2px solid transparent;
                    margin-right: 0px;
                    color:#1e1e1e;
                    background:$color-yellow;
                    margin-left: 30px;
                    @include bp(md-down) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;
                        margin-left: 0px;
                        margin-right: 0px;
                        margin-bottom: 15px;
                    }
                    @include bp(md) {
                        width: 100%;
                        height: 70px;
                        line-height: 70px;    
                        margin-bottom: 0px;
                        margin-top: 15px;                    
                    }                    
                    &:hover {
                        background: #082140;
                        color: #fff;                        
                    }                       
                }
            }
            .checkbox-wrapper {
                font-size: 16px;
                margin-bottom: 10px;
                color:$white;
                label {
                    color:$white;
                    span {
                        color:$white;
                    }
                }
                a {
                    color:$color-yellow;
                    &:hover {
                        color:$white;
                    }
                }
                .wpcf7-list-item {
                    margin-left: 0px;
                }
                input[type="checkbox"] {
                    margin-right: 5px;
                    position: relative;
                    top:1px;

                    position: absolute;
                    width: auto;
                    opacity: 0;

                }    
                .wpcf7-list-item-label {
                    &:before {
                        content: ' ';
                        width: 24px;
                        height: 24px;
                        background: #fff;
                        border: 1px solid #afafb0;
                        margin-right: 15px;
                        display: inline-block;
                        cursor: pointer;
                        position: relative;
                        top: 7px;
                        border-radius: 6px;
                    }
                }     
                input:checked+span.wpcf7-list-item-label:before {
                    background-color: $color-blue-dark;
                    border: 1px solid $color-blue;
                    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 70%;
                }       
            }
            .wpcf7-checkbox {
                font-size: 16px;
                a {
                    color:$color-yellow;
                    &:hover {
                        color:$white;
                    }
                }
            }
            .form-block {
                @include bp(sm-down) {
                    margin-bottom: 15px;
                }
                input[type="text"], input[type="tel"], input[type="email"] {
                    &.wpcf7-not-valid {
                        border-color:#ffd4d4;
                    }    
                    .fieldset-cf7mls-wrapper div.wpcf7-validation-errors {
                        border:none;
                        padding:0px;
                        margin:0px;
                    }      
                }
            }
        }

    }  
}

#experience-tech {
    header {
        text-align: center;
        padding-bottom: 60px;
        @include bp(lg) {
            padding-bottom: 50px;
        }
        @include bp(md) {
            padding-bottom: 50px;
        }
        @include bp(sm-down) {
            padding-bottom: 30px;
        }
        h2 {
            @include main-title;
        }
    }
}

.control-tech {
    padding-bottom: 100px!important;
}

#tech-features {
    padding-bottom: 100px;
    .feat-card {
        padding-left: 70px;
        padding-right: 30px;
        margin-bottom: 50px;
        position: relative;
        @include bp(md) {
            padding-left: 55px;
            padding-right: 0px;
        }
        @include bp(sm-down) {
            padding-left: 55px;
            padding-right: 0px;
        }
        img {
            position: absolute;
            left:0px;
            top:0px;
            width: 42px;
            @include bp(md) {
                width: 32px;
            }
            @include bp(sm-down) {
                width: 32px;
            }
        }
        h4 {
            margin-bottom: 20px;
            font-size: 22px;
            @include bp(lg) {
                font-size: 20px;
            }
            @include bp(md) {
                font-size: 20px;
                margin-bottom: 10px;
            }
            @include bp(sm-down) {
                font-size: 19px;
                margin-bottom: 10px;
            }
        }
        p {
            font-size: 16px;
        }
    }
    .tech-cta {
        padding-top: 30px;
        text-align: center;
        a {
           @include yellow-btn;
        }
    }
}

#faq-tech {
    padding-top: 100px;
    padding-bottom: 100px;
    background:#eff7fe;
    @include bp(lg) {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    @include bp(md) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include bp(sm-down) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    header {
        text-align: center;
        padding-bottom: 60px;
        @include bp(lg) {
            padding-bottom: 50px;
        }
        @include bp(md) {
            padding-bottom: 40px;
        }
        @include bp(sm-down) {
            padding-bottom: 35px;
        }
        h2 {
            @include bp(sm-down) {
                font-size: 24px;
                padding-left: 30px;
                padding-right: 30px;
                line-height: 1.3;
            }
        }
    }
    .faq__accordion {
        max-width: 875px;
        margin-left: auto;
        margin-right: auto;
        .faq-wrap {
            margin-bottom: 10px;
        }
        h3 {
            font-size: 17px;
            color: $black;
            line-height: 1.333;
            position: relative;
            padding: 24px 60px 24px 30px;
            cursor: pointer;
            @include animate-fade;
            border:0;
            margin: 0;
            border-radius: 8px;
            border:2px solid #bddbf5;
            position: relative;
            color:$color-heading;
            @include bp(sm-down) {
                font-size: 16px;
                padding:15px;
                padding-right: 40px;
            }
            &:hover {
                cursor: pointer;
            }
            &:focus {
                outline: none!important;
            }
            &:after {
                @include centre(y);
                right:30px;
                content:url('../img/ico/chevron-down-regular.svg');
                width: 20px;
                @include bp(sm-down) {
                    width: 12px;
                }
            }
            &.active {
                border-color:$color-blue;
                background: $color-blue;
                color:$white;
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                    color:$white!important;
                    filter: brightness(0) invert(1);

                }
            }
        }
        .content {
            border:0;
            padding: 35px 55px 35px 30px;
            padding-bottom: 15px;
            display: none;
            background:$white;
            border-radius: 12px;
            margin-top: 10px;
            padding-bottom: 30px;
            box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.04); 
            @include bp(sm-down) {
                padding:20px;
            }
            p {
                margin-bottom: 30px;
                strong {
                    font-weight: normal;
                    font-family: $font-med;
                }
                a {
                    color:$color-blue;
                    font-family: $font-med;
                    &:hover {
                        color:$black;
                        text-decoration:underline;
                    }
                }
            }
        }
    }    
}

// techn single
#technology-main {
    #about {
        border-bottom: 1px solid #ddd;
        margin-bottom: 0px;
        margin-top: 0px;
        padding-bottom: 100px;
        padding-top: 100px;
        @include bp(md) {
            padding-bottom: 80px;
            padding-top: 80px;
        }
        @include bp(sm-down) {
            padding-bottom: 50px;
            padding-top: 50px;
        }
        header {
            padding-bottom: 30px;
        }
    }
    .tech-tabs {
        padding-bottom: 120px;
        padding-top: 120px;
        @include bp(lg) {
            padding-bottom: 80px;
            padding-top: 80px;
        }
        @include bp(md) {
            padding-bottom: 60px;
            padding-top: 60px;
        }
        // sidebar
        .side-nav {
            .tab {
                button {
                    display: block;
                    width: 100%;
                    height: auto;
                    padding:0px;
                    border:none;
                    white-space: initial;
                    text-align: left;
                    border-top: 1px solid #f1f1f1;
                    border-bottom: 1px solid #fcfbfc;
                    color:#393939;
                    font-size: 18px;
                    font-family: $font-med;
                    line-height: 1.3;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    @include bp(lg) {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        font-size: 16px;
                    }
                    @include bp(md) {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        font-size: 16px;
                    }
                    @include bp(sm-down) {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        font-size: 16px;
                    }
                    &.active {
                        color:$color-blue;
                    }
                    &:hover {
                        background: none;
                        color:$color-pale;
                    }
                    &:focus {
                        outline: 0;
                        background: transparent;
                    }
                }
            }
        }
        .tab-list {
            padding-left: 40px;
            padding-top: 20px;
            @include bp(lg) {
                padding-left: 15px;
                padding-top: 10px;
            }
            @include bp(md) {
                padding-top: 0px;
                padding-left: 15px;
            }
            @include bp(sm-down) {
                padding-left: 0px;
            }
            .tab-card {
                h2 {
                    margin-bottom: 30px;
                    line-height: 1.3;
                    @include bp(lg) {
                        margin-bottom: 20px;
                    }
                    @include bp(md) {
                        margin-bottom: 20px;
                    }
                    @include bp(sm-down) {
                        margin-bottom: 20px;
                    }
                }
                h3 {
                    margin-bottom: 20px;
                    margin-top: 50px;
                    font-family: $font-med;
                    line-height: 1.3;
                    @include bp(lg) {
                        margin-top: 30px;
                        margin-bottom: 15px;
                    }
                    @include bp(md) {
                        margin-bottom: 15px;
                    }
                    @include bp(sm-down) {
                        margin-bottom: 15px;
                    }
                }
                h4 {
                    margin-bottom: 20px;
                    font-family: $font-med;
                    line-height: 1.3;
                }
                p {
                    margin-bottom: 25px;
                    @include bp(md) {
                        margin-bottom: 15px;
                    }
                    @include bp(sm-down) {
                        margin-bottom: 15px;
                    }
                    a {
                        color:$color-blue;
                        font-family: $font-semi;
                        &:hover {
                            color:$black;
                            text-decoration:underline;
                        }
                    }                    
                }
                table {
                    width: 100%;
                    border:none;
                    tr {
                        border:none;
                        &:nth-child(even) {
                            background-color: $color-blue-light;
                        }
                    }
                    td {
                        border:none;
                        padding:10px;
                    }
                    a {
                        color:$color-blue;
                        font-family: $font-semi;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }              
                ul {
                    li {
                        display: block;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        position: relative;
                        line-height: 1.4;
                        a {
                            color:$color-blue;
                            font-family: $font-med;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &:before {
                            content:'';
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-radius: 100%;
                            background:$color-pale;
                            position: absolute;
                            left:0px;
                            top:8px;
                        }
                    }
                }
                ol {
                    li {
                        margin-bottom: 10px;
                        line-height: 1.4;
                    }
                }
            }
        }
    }
    // why
    .tech-why {
        padding-top: 80px;
        padding-bottom: 80px;
        background:#eff7fe;
        @include bp(sm-down) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        header {
            text-align: center;
            width: 770px;
            margin:0px auto;
            padding-bottom: 100px;
            @include bp(lg) {
                padding-bottom: 80px;
                width: 550px;
            }
            @include bp(md) {
                padding-bottom: 50px;
                width: 80%;
            }
            @include bp(sm-down) {
                width: 100%;
                padding-bottom: 40px;
            }
            h2 {
                @include main-title;
            }
            p {
                @include intro-text;
                margin-bottom: 20px;
            }    
            ul {
                display: inline-block;
                margin-bottom: 15px;
                li {
                    display: inline-block;
                    padding-left: 20px;
                    margin-bottom: 10px;
                    position: relative;
                    font-size: 18px;
                    line-height: 1.45;
                    margin-right: 15px;
                    &:before {
                        content:'';
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 100%;
                        background:$color-pale;
                        position: absolute;
                        left:0px;
                        top:10px;
                    }                    
                }
            }        
        }
        .features-list {
            .col-md-4 {
                @include bp(md) {
                    padding-right: 0px;
                }
            }
            .feat-card {
                padding-left: 60px;
                padding-right: 0px;
                margin-bottom: 50px;
                position: relative;
                @include bp(lg) {
                    padding-left: 60px;
                }
                @include bp(md) {
                    padding-left: 35px;
                    padding-right: 0px;
                }
                @include bp(sm-down) {
                    padding-left: 55px;
                    padding-right: 0px;
                }
                img {
                    position: absolute;
                    left:0px;
                    top:0px;
                    width: 42px;
                    @include bp(md) {
                        width: 24px;
                    }
                    @include bp(sm-down) {
                        width: 32px;
                    }
                }
                h4 {
                    margin-bottom: 20px;
                    font-size: 22px;
                    line-height: 1.3;
                    @include bp(lg) {
                        font-size: 20px;
                    }
                    @include bp(md) {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                    @include bp(sm-down) {
                        font-size: 19px;
                        margin-bottom: 10px;
                    }
                }
                p {
                    font-size: 16px;
                    line-height: 1.45;
                }
                ul {
                    li {
                        display: block;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        position: relative;
                        font-size: 16px;
                        line-height: 1.45;
                        &:before {
                            content:'';
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-radius: 100%;
                            background:$color-pale;
                            position: absolute;
                            left:0px;
                            top:8px;
                        }
                    }
                }                
            }
            .tech-cta {
                padding-top: 30px;
                text-align: center;
                a {
                    @include btn-cta;
                    @include bp(md) {
                        width: 250px;
                    }
                    @include bp(sm-down) {
                        width: 250px;
                    }
                }
            }
        }
    }
    // related tech
    #tech-list--related {
        padding-bottom: 80px;
        @include bp(lg) {
            padding-bottom: 50px;
        }
        @include bp(md) {
            padding-bottom: 50px;
        }
        @include bp(sm-down) {
            padding-bottom: 50px;
        }
        header h4 {
            margin-bottom: 50px;
            font-size: 24px;
            @include bp(lg) {
                font-size: 22px;
                margin-bottom: 30px;
            }
            @include bp(md) {
                font-size: 22px;
                margin-bottom: 30px;
            }
            @include bp(sm-down) {
                margin-bottom: 30px;            
            }
        }        
        a {
            display: block;
            width: 100%;
            height: 100%;
            &:hover {
                .tech-card {
                    background:$color-blue;
                    transform: scale(1.1);
                    h5 {
                        color:$white;
                    }
                    p {
                        color:$white;
                    }
                    span {
                        color:$color-yellow;
                    }
                }
            }
        }
        .tech-card {
            background:$white;
            border-radius: 16px;
            padding:12px;
            padding-top: 10px;
            padding-bottom: 10px;
            @include animate-fade;
            box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.05); 
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            @include bp(sm-down) {
                margin-bottom: 15px;
            }
            .tech-ico {
                width: 44px;
                height: 44px;
                background:$white;
                border:1px solid #ddd;
                border-radius: 12px;
                @include animate-fade;
                position: relative;
                margin-right: 20px;
                img {
                    @include centre(both);
                    width:28px;
                }
            }
            .tech-desc {
                flex:1;
            }
            h5 {
                margin-top: 0px;
                font-size: 18px;
                margin-bottom: 0px;
                @include animate-fade;
                font-family: $font-med;
                font-weight: 500;
                @include bp(lg) {
                    font-size: 16px;
                }
            }
            p {
                font-size: 16px;
                line-height: 24px;
                @include animate-fade;
                margin-bottom: 0px;
                line-height: 20px;
                padding-right: 0px;
            }
            span {
                font-family: $font-semi;
                color:$color-pale;
                @include animate-fade;
            }
        }        
    }
}


// Relaated posts
.related-posts {
    padding-bottom: 120px;
    @include bp(md) {
        padding-bottom: 50px;
    }
    @include bp(sm-down) {
        padding-bottom: 50px;
    }
    h4 {
        margin-bottom: 50px;
        font-size: 24px;
        @include bp(sm-down) {
            margin-bottom: 30px;
        }
    }
    .article-card {
        .article-photo {
            background:$color-blue;
            border-radius: 12px;
            a {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                small {
                    @include centre(both);
                    width: 70px;
                    height: 70px;
                    border-radius: 9px;
                    background:$color-yellow;
                    @include animate-fade;
                    opacity: 0;
                    margin-top: 30px;
                    span {
                        @include centre(both);
                        color:$color-heading;
                        font-size: 32px;
                    }
                }
                &:hover {
                    small {
                        opacity: 1;
                        margin-top: 0px;
                    }
                    img {
                        opacity: 0.1;
                    }
                }                
            }
            img {
                width: 100%;
                border-radius: 12px;
                object-fit: cover;
                display: block;
                @include animate-fade;
            }
        }
        .article-desc {
            padding-top: 16px;
            span.date {
                display: block;
                margin-bottom: 20px;
                font-size: 16px;
                color:#83888f;
                @include bp(lg) {
                    margin-bottom: 15px;
                    font-size: 14px;
                }
                @include bp(md) {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
                strong {
                    font-weight: 500;
                    font-family: $font-semi;
                }
            }
            p {
                max-height: 80px;
                overflow: hidden;                
            }
            h3 {
                font-size: 20px;
                line-height: 1.3;
                @include bp(lg) {
                    font-size: 18px;
                }
                @include bp(md) {
                    font-size: 18px;
                }
                a {
                    color:$color-heading;
                    &:hover {
                        color:$color-pale;
                    }
                }
            }
        }
    }
}

.single-technologies {
    #contact-cta--inner {
        margin-bottom: 120px;
        @include bp(lg) {
            margin-bottom: 80px;
        }
        @include bp(md) {
            margin-bottom: 70px;
        }
    }
}

#tech-breadcrumb {
    margin-bottom: 40px;
    margin-top: -30px;
    .breadcrumb-wrapper {
        display: inline-flex;
        border:1px solid #ddd;
        align-items: center;
        border-radius: 6px;
    }
    .home-link {
        img {
            width: 52px;
        }
    }
    ul {
        display: flex;
        align-items: center;
        li {
            display: inline-block;
        }
    }
}

.breadcrumb-wrapper {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    .tech-ico {
        margin-right: 10px!important;
        margin-bottom: 0px!important;
        @include bp(md) {
            margin-right: 5px!important;
        }
    }
    ul {
        display: flex;
        align-items: center;
        li {
            display: inline-block;
            a {
                color:#646464;
                font-size: 14px;
                font-family: $font-med;
                padding-left: 10px;
                padding-right: 10px;
                position: relative;
                @include bp(md) {
                    padding-left: 5px;
                    padding-right: 5px;
                }
                &:after {
                    content:'>';
                    font-size: 18px;
                    position: relative;
                    left: 10px;
                    top: 2px;        
                    @include bp(md) {     
                        left:5px;       
                    }
                }
            }
            &:last-child {
                a {
                    color:$color-blue;
                    font-family: $font-med;
                    padding-right: 0px;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

// remove moda
#elementor-popup-modal-7091 {
    display: none!important;
}

.about-hire {
    padding-bottom: 80px!important;
}

.contact-hire {
    margin-top: 0px!important;
}

.page-id-20505 {
    #inner-header {
        margin-top: 100px;
        @include bp(md) {
            margin-top: 50px;
        }
        @include bp(sm-down) {
            margin-top: 30px;
        }
    }
}

#bottom-cta .thanks-cta-content {
    width: 90%!important;
    padding-top: 30px;
    padding-bottom: 30px;
    @include bp(sm-down) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    p {
        margin:0px auto;
        max-width: 600px;
        margin-bottom: 0px!important;
    }
    h3 {
        margin-bottom: 35px;
        @include bp(sm-down) {
            margin-bottom: 15px;
        }
    }
    footer {
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include bp(sm-down) {
            display:block;
        }
        a.btn-cta {
            width: auto!important;
            padding-left: 35px;
            padding-right: 35px;
            max-width: 100%;
            margin-right: 30px;
            min-width: 200px;
            @include bp(sm-down) {
                width: 100%;
                text-align: center;
                padding-left: 0px;
                padding-right: 0px;
                margin-right: 0px;
                margin-bottom: 20px;
                display: block;
            }
        }
        a.btn-home {
            color:$white;
            font-size: 17px;
            display: inline-block;
            font-family: $font-semi;
            position: relative;
            margin-left: 20px;
            @include bp(sm-down) {
                margin-left: 0px;
            }
            &:after {
                width: 100%;
                position: absolute;
                left:0px;
                bottom:0px;
                height: 2px;
                background: $white;
                content:'';
                opacity: 1;
                @include animate-fade;
            }
            &:hover {
                &:after {
                    width: 0%;
                    opacity: 0;
                }
            }        
        }
    }
}

// Regulr Page
#regular-page {
    padding-top: 80px;
    padding-bottom: 100px;
    @include bp(md-down) {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    .regular-content {
        p {
            font-size: 19px;
            color: rgb(6, 6, 6);
            line-height: 1.684;
            margin-bottom: 30px;
            @include bp(md-down) {
                font-size: 18px;
            }
            a {
                color:$color-blue;
                font-family: $font-semi;
                &:hover {
                    text-decoration: underline;
                    color:$color-blue-dark;
                }
            }                    
        }    
        h2 {
            margin-bottom: 30px;
            line-height: 1.3;
            font-size: 30px;
            @include bp(lg) {
                margin-bottom: 20px;
                font-size: 28px;
            }
            @include bp(md) {
                margin-bottom: 20px;
                font-size: 26px;
            }
            @include bp(sm-down) {
                margin-bottom: 20px;
                font-size: 24px;
            }
        }
        h3 {
            margin-bottom: 20px;
            margin-top: 50px;
            line-height: 1.45;
            font-size: 26px;
            @include bp(lg) {
                margin-top: 30px;
                margin-bottom: 15px;
            }
            @include bp(md) {
                margin-bottom: 15px;
            }
            @include bp(sm-down) {
                margin-bottom: 15px;
            }
        }
        h4 {
            margin-bottom: 20px;
            font-family: $font-med;
            line-height: 1.3;
            font-size: 22px;
        }
        ul {
            margin-bottom: 30px;
            li {
                display: block;
                padding-left: 20px;
                margin-bottom: 10px;
                position: relative;
                font-size: 18px;
                a {
                    color:$color-blue;
                    font-family: $font-med;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &:before {
                    content:'';
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background:$color-pale;
                    position: absolute;
                    left:0px;
                    top:10px;
                }
            }
        }         
    }      
}

// contact info
#contact-info {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 90px;
    @include bp(sm-down) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    header {
        padding-bottom: 60px;
        text-align: center;
        @include bp(sm-down) {
            padding-bottom: 30px;
        }
        h2 {
            @include main-title;
            text-align: center;
        }
    }

    .tab {
        margin:0px auto;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:2px solid $color-pale;
        width:auto;
        padding:5px;
        width: 400px;
        border-radius: 999px;
        @include bp(sm-down) {
            width: 100%;
            display: block;
            text-align: center;
            border-radius: 4px;
        }
        button {
            width: 200px;
            border-radius: 999px;
            border:none;
            font-size: 18px;
            font-family: $font-semi;
            color:$color-pale;
            padding-top: 13px;
            padding-bottom: 13px;
            background-color: $white;
            @include bp(md) {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 16px;
            }
            @include bp(sm-down) {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 16px;     
                width: 100%;      
                border-radius: 4px;     
            }
            &.active {
                background:$color-pale!important;
                color:$white!important;
            }
            &:hover {
                color:$color-pale;
                background: none;
            }
        }
    }

    // card
    .info-card {
        padding:50px;
        box-shadow: 0 40px 76px rgba(0, 0, 0,  0.08);  
        border-radius: 24px;
        height: 340px;
        @include bp(sm-down) {
            margin-bottom: 40px;
            height: auto;
            box-shadow: 0 20px 36px rgba(0, 0, 0,  0.08);  
        }
        .icon {
            margin-bottom: 20px;
            img {
                width: 74px;
            }
        }
        .card-text {
            span.label {
                display: block;
                margin-bottom: 20px;
                font-size: 22px;
                line-height: 1.3;
                color:#1f6fd6;
                @include bp(lg) {
                    font-size: 20px;
                }
                @include bp(md) {
                    font-size: 20px;
                    margin-bottom: 10px;
                }
                @include bp(sm-down) {
                    font-size: 19px;
                    margin-bottom: 10px;
                }                
                margin-bottom: 20px;
                font-family: $font-heading;
            }
            p {
                font-size: 20px;
                color:$black;
                line-height: 1.4;
                a {
                    color:$black;
                    &:hover {
                        color:$color-pale;
                    }
                }
            }
        }
    }
}

// Map
.map-holder {
    margin-bottom: 80px;
    margin-top: 50px;
    iframe {
        width: 100%;
        height: 450px;
        display: block;
        border-radius: 24px;
    }
}

.socials-author {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 30px;
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
            margin-left: 15px;
            margin-right: 15px;
            @include bp(sm-down) {
                margin-left: 10px;
                margin-right: 10px;
            }
            a {
                img {
                    width: 36px;
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

.schema-faq-section {
    margin-bottom: 10px;
}

.schema-faq {
    background: #f1f1f1;
    .schema-faq-question {
        display: block;
        font-size: 17px;
        background: white;
        color: $black;
        line-height: 1.333;
        position: relative;
        padding: 24px 60px 24px 30px;
        cursor: pointer;
        @include animate-fade;
        border:0;
        margin: 0;
        border-radius: 8px;
        border:2px solid #bddbf5;
        position: relative;
        color:$color-heading;
        @include bp(sm-down) {
            font-size: 16px;
            padding:15px;
            padding-right: 40px;
        }
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none!important;
        }
        &:after {
            @include centre(y);
            right:30px;
            content:url('../img/ico/chevron-down-regular.svg');
            width: 20px;
            @include bp(sm-down) {
                width: 12px;
            }
        }
        &.active {
            border-color:$color-blue;
            background: $color-blue;
            color:$white;
            &:after {
                transform: translateY(-50%) rotate(180deg);
                color:$white!important;
                filter: brightness(0) invert(1);

            }
        }
    }
    .schema-faq-answer {
        border:0;
        padding: 35px 55px 35px 30px;
        padding-bottom: 15px;
        display: none;
        background:$white;
        border-radius: 12px;
        margin-top: 10px;
        padding-bottom: 30px;
        box-shadow: 0px 0px 34.56px 13.44px rgba(0, 0, 0, 0.04); 
        @include bp(sm-down) {
            padding:20px;
        }
    }
}  

// table
.wp-block-table {
    padding:0px!important;
    width: 100%;
    margin:0px;
    margin-bottom: 30px;
    @include bp(sm-down) {
        overflow: scroll;
    }
    table {
        width: 100%!important;
    }
    td {
        padding:10px;
        border-bottom: 1px solid #ddd;
        strong {
            color:$color-blue;
        }
    }
    th {
        background-color: $color-blue;
        color:$white;
        padding:10px;
    }
    .wp-element-caption {
        font-size: 15px;
        opacity: 0.6;
        display: block;
        padding-top: 10px;
        background: #f1f1f1;
        padding:10px;
    }
}